/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_NumThrend from "./UI_NumThrend";
import UI_LHThrend from "./UI_LHThrend";
import UI_SumThrend from "./UI_SumThrend";
import UI_BigThrend from "./UI_BigThrend";

export default class UI_GameRecordWindow extends fgui.GComponent {

	public m_page:fgui.Controller;
	public m_mask:fgui.GGraph;
	public m_record:fgui.GButton;
	public m_thrend:fgui.GButton;
	public m_list:fgui.GList;
	public m_recordContent:fgui.GGroup;
	public m_threndSelect:fgui.GComboBox;
	public m_NumThrend:UI_NumThrend;
	public m_LHThrend:UI_LHThrend;
	public m_SumThrend:UI_SumThrend;
	public m_BigThrend:UI_BigThrend;
	public m_threndContent:fgui.GGroup;
	public static URL:string = "ui://d7esdcvtnvh61";

	public static createInstance():UI_GameRecordWindow {
		return <UI_GameRecordWindow>(fgui.UIPackage.createObject("windows", "GameRecordWindow"));
	}

	protected onConstruct():void {
		this.m_page = this.getController("page");
		this.m_mask = <fgui.GGraph>(this.getChild("mask"));
		this.m_record = <fgui.GButton>(this.getChild("record"));
		this.m_thrend = <fgui.GButton>(this.getChild("thrend"));
		this.m_list = <fgui.GList>(this.getChild("list"));
		this.m_recordContent = <fgui.GGroup>(this.getChild("recordContent"));
		this.m_threndSelect = <fgui.GComboBox>(this.getChild("threndSelect"));
		this.m_NumThrend = <UI_NumThrend>(this.getChild("NumThrend"));
		this.m_LHThrend = <UI_LHThrend>(this.getChild("LHThrend"));
		this.m_SumThrend = <UI_SumThrend>(this.getChild("SumThrend"));
		this.m_BigThrend = <UI_BigThrend>(this.getChild("BigThrend"));
		this.m_threndContent = <fgui.GGroup>(this.getChild("threndContent"));
	}
}