import { E_ScreenMode } from "../../config/Enum";
import { GameEvent } from "../../config/GameEvent";
import { notice } from "../../helper/NoticeHelper";
import { Game } from "../game/game";


const { regClass, property } = Laya;



@regClass('A9HEXpbjREq1P8GJbPgd2w')
export class GameScene extends Laya.Script {
  
    private uiH: Game = null
    private uiV: Game = null
    roomId:number
    //组件被激活后执行，此时所有节点和组件均已创建完毕，此方法只执行一次
    onAwake(): void {

    }


    onOpened(param: any): void {
        this.roomId = param.roomId;
    }
    //组件被启用后执行，例如节点被添加到舞台后
    //onEnable(): void {}

    //组件被禁用时执行，例如从节点从舞台移除后
    //onDisable(): void {}

    //第一次执行update之前执行，只会执行一次
    onStart(): void {

        if (Laya.stage.screenMode == E_ScreenMode.horizontal) {
            Laya.stage.width = 1080 
            Laya.stage.height = 1920
            this.uiH = new Game(E_ScreenMode.vertical,this.roomId)
            Laya.stage.scaleMode = "showall"  
            // this.uiV = new Home(E_ScreenMode.vertical)
        }else{
            Laya.stage.width = 1080 
            Laya.stage.height = 1920
            this.uiV = new Game(E_ScreenMode.vertical,this.roomId)
            Laya.stage.scaleMode = "fixedwidth"
            // this.uiH = new Login(E_ScreenMode.horizontal)
        }
        // this.ChangeOr()
        // notice.on(GameEvent.ChangeOr,this.ChangeOr)
    }

    //手动调用节点销毁时执行
    // ChangeOr = ()=> {
    //     if (UIManager.checkHorizontal()) {
    //         this.uiV && this.uiV.hide()
    //         this.uiH && this.uiH.show()
    //         Laya.stage.width = 1920
    //         Laya.stage.height = 1080

    //     }else{
    //         this.uiH && this.uiH.hide()
    //         this.uiV && this.uiV.show()
    //         Laya.stage.width = 1080
    //         Laya.stage.height = 1920
    //     }
    // }

    //手动调用节点销毁时执行
    onDestroy(): void {
        console.log("GameScene被销毁")
        this.uiH && this.uiH.destroy()
        this.uiV && this.uiV.destroy()
    }

    onKeyUp(evt: Laya.Event): void {
        
        if (evt.keyCode == 13) {
            notice.emit(GameEvent.SendMsg)
        }
    }

  

    //每帧更新时执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
    //onUpdate(): void {}

    //每帧更新时执行，在update之后执行，尽量不要在这里写大循环逻辑或者使用getComponent方法
    //onLateUpdate(): void {}

    //鼠标点击后执行。与交互相关的还有onMouseDown等十多个函数，具体请参阅文档。
    //onMouseClick(): void {}
}