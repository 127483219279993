/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_toggleCom from "./UI_toggleCom";
import UI_game_btn from "./UI_game_btn";
import UI_chatRight from "./UI_chatRight";
import UI_chatLeft from "./UI_chatLeft";
import UI_chat from "./UI_chat";

export default class chatBinder {
	public static bindAll():void {
		fgui.UIObjectFactory.setExtension(UI_toggleCom.URL, UI_toggleCom);
		fgui.UIObjectFactory.setExtension(UI_game_btn.URL, UI_game_btn);
		fgui.UIObjectFactory.setExtension(UI_chatRight.URL, UI_chatRight);
		fgui.UIObjectFactory.setExtension(UI_chatLeft.URL, UI_chatLeft);
		fgui.UIObjectFactory.setExtension(UI_chat.URL, UI_chat);
	}
}