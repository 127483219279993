import { E_ScreenMode } from "../../../config/Enum"
import { IBank, IBankCard } from "../../../config/interface"
import UI_bank_item from "../../../fgui/bind_card/UI_bank_item"
import UI_bind_card from "../../../fgui/bind_card/UI_bind_card"
import ConfigManager from "../../../helper/ConfigManager"
import I18nHelper from "../../../helper/I18nHelper"
import { UIHelper } from "../../../helper/UIHelper"
import { http } from "../../../net/Http"
import { Router } from "../../../util/router"
import { UIBase } from "../../scene/UIBase"
import { CustomerWindow } from "../../windows/CustomerWindow"

export class BindCard extends UIBase {
    private reloadNums: number = 0
    private or: E_ScreenMode
    private uiBindCard: UI_bind_card
    private bankList: IBank[] = []
    private bankId: number = null
    private data: IBankCard = null
    private bindData: {
        name: string,
        bankCode: string
    } = null
    constructor(or: E_ScreenMode, data: IBankCard, bindData: { name: string; bankCode: string }) {
        super(or == E_ScreenMode.horizontal ? UI_bind_card.createInstance() : UI_bind_card.createInstance());
        this.data = data
        this.bindData = bindData
        this.onUILoaded()
    }
    onUILoaded() {
        this.uiBindCard = this.UI_Item as UI_bind_card
        fgui.GRoot.inst.addChild(this.uiBindCard);


     

        this.uiBindCard.m_back.onClick(this, () => {
            Laya.Scene.open(Router.Cash, true, null, Laya.Handler.create(this, () => {
            }))
        })

        //选择开户行
        this.uiBindCard.m_card_account.onClick(this, this.openSelectAccount)
        // this.uiBindCard.m_back_bg.onClick(this, this.colseBg)
        this.uiBindCard.m_bottom.m_list.itemRenderer = Laya.Handler.create(this, this.renderListItem, null, false)
        //提交
        this.uiBindCard.m_modifyBtn.onClick(this, this.bindBank)
        //客服列表
        this.uiBindCard.m_help_btn.onClick(this, this.onCustomerClick)
        this.uiBindCard.m_title.text = this.bindData.name
        if (this.data) {
            this.uiBindCard.m_account.text = this.data.account
            this.uiBindCard.m_withdrawName.text = this.data.withdrawName
            this.uiBindCard.m_outlets.text = this.data.outlets
        }
    }
    //客服列表
    customerWindow: CustomerWindow = null
    onCustomerClick() {
        if (!ConfigManager.getInstance().Customer) {
            UIHelper.showMsg(I18nHelper.t('noService'))
            return
        }
        if (ConfigManager.getInstance().Customer.length > 0) {
            if (!this.customerWindow) {
                this.customerWindow = new CustomerWindow(ConfigManager.getInstance().Customer)
            }
            UIHelper.showWindow(this.customerWindow)
        }
        else {
            UIHelper.showMsg(I18nHelper.t('noService'))
        }
    }

    bindBank() {
        console.log("11111");

        let data = {
            id:this.data ? this.data.id:null,
            "withdrawType": "bank",
            // "bankId": this.bankId,
            "account": this.uiBindCard.m_account.text,
            "withdrawName": this.uiBindCard.m_withdrawName.text,
            "outlets": this.uiBindCard.m_outlets.text
        }

        http.addMyBankCard(data, (data: any) => {
            if (data.code == 200) {
                UIHelper.showMsg(I18nHelper.t(data.msg))
                Laya.Scene.open(Router.Cash, true, null, Laya.Handler.create(this, () => {
                }))
            } else {
                UIHelper.showMsg(I18nHelper.t(data.msg))
            }
        })
    }


    renderListItem(index: number, obj: fairygui.GObject) {
        let item = obj as UI_bank_item
        item.m_bankName.text = this.bankList[index].bankName
        item.onClick(this, () => {
            this.uiBindCard.m_out_name.text = this.bankList[index].bankName
            this.bankId = this.bankList[index].id
            this.colseBg()
        })
    }

    openSelectAccount() {
        // this.uiBindCard.m_back_bg.visible = true
        this.uiBindCard.m_t0.play()
    }

    colseBg() {
        // this.uiBindCard.m_back_bg.visible = false
        this.uiBindCard.m_t0.playReverse()
    }


    destroy() {
        this.uiBindCard.dispose()
    }
}