import { E_ScreenMode } from "../../config/Enum";
import ConfigManager from "../../helper/ConfigManager";
import { http } from "../../net/Http";
import { Router } from "../../util/router";
import UI_pay_modify from "../../fgui/pay_modify/UI_pay_modify";
import { UIBase } from "../scene/UIBase";
import PlayerDataManager from "../../model/PlayerDataManager";
import { UIHelper } from "../../helper/UIHelper";
import { CustomerWindow } from "../windows/CustomerWindow";
import I18nHelper from "../../helper/I18nHelper";
export class PayModify extends UIBase {
    private reloadNums: number = 0
    private or: E_ScreenMode
    private uiPayModify: UI_pay_modify
    xhrList:XMLHttpRequest[] = []

    constructor(or: E_ScreenMode) {
        super(or == E_ScreenMode.horizontal ? UI_pay_modify.createInstance() : UI_pay_modify.createInstance());
        this.onUILoaded()
    }
    onUILoaded() {
        this.uiPayModify = this.UI_Item as UI_pay_modify

        this.uiPayModify.m_old_psd.visible = PlayerDataManager.getInstance().getPlayerInfo().isPwd == 1

        fgui.GRoot.inst.addChild(this.uiPayModify);
        //修改密码
        this.uiPayModify.m_modifyBtn.onClick(this, () => {
            let data = {}
            if (PlayerDataManager.getInstance().getPlayerInfo().isPwd == 1) {
                data = {
                    oldPassword: this.uiPayModify.m_oldPassword.text,
                    newPassword: this.uiPayModify.m_newPassword.text,
                    twoPassword: this.uiPayModify.m_twoPassword.text,
                }
            }else{
                data = {
                    newPassword: this.uiPayModify.m_newPassword.text,
                    twoPassword: this.uiPayModify.m_twoPassword.text,
                }
            }

           let xhr =   http.updateWithdrawalPwd(data, (data: any) => {
                if (data.code == 200) {
                    UIHelper.showMsg(I18nHelper.t(data.msg))
                    let xhr =  http.getUserInfo((data: any) => {
                        if (data.code == 200) {
                            PlayerDataManager.getInstance().setPlayerInfo(data.data)
                            Laya.Scene.open(Router.Me, true, null, Laya.Handler.create(this, () => {
                            }))
                        } else {
                            UIHelper.showMsg(I18nHelper.t(data.msg))
                        }
                    })
                    this.xhrList.push(xhr)
                }else{
                    UIHelper.showMsg(I18nHelper.t(data.msg))
                }
            })
            this.xhrList.push(xhr)
        })
        this.uiPayModify.m_back.onClick(this, () => {
            Laya.Scene.open(Router.Me, true, null, Laya.Handler.create(this, () => {
            }))
        })
    //客服列表
    this.uiPayModify.m_help_btn.onClick(this, this.onCustomerClick)
}
//客服列表
customerWindow: CustomerWindow = null
onCustomerClick() {
    if (!ConfigManager.getInstance().Customer) {
        UIHelper.showMsg(I18nHelper.t('noService'))
        return
    }
    if (ConfigManager.getInstance().Customer.length > 0) {
        if (!this.customerWindow) {
            this.customerWindow = new CustomerWindow(ConfigManager.getInstance().Customer)
        }
        UIHelper.showWindow(this.customerWindow)
    }
    else {
        UIHelper.showMsg(I18nHelper.t('noService'))
    }
}
    destroy() {
        this.xhrList.forEach((xhr)=>{
            xhr.abort()
        })
        this.uiPayModify.dispose()
    }
}