/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_Settled extends fgui.GComponent {

	public m_nperCode:fgui.GTextField;
	public m_betCode:fgui.GTextField;
	public m_isWin:fgui.GTextField;
	public m_betTime:fgui.GTextField;
	public m_status:fgui.GTextField;
	public m_odds:fgui.GTextField;
	public m_winLoss:fgui.GTextField;
	public m_gameResult:fgui.GTextField;
	public m_amount:fgui.GTextField;
	public static URL:string = "ui://n8dzzxx0n5qta";

	public static createInstance():UI_Settled {
		return <UI_Settled>(fgui.UIPackage.createObject("betting_order", "Settled"));
	}

	protected onConstruct():void {
		this.m_nperCode = <fgui.GTextField>(this.getChild("nperCode"));
		this.m_betCode = <fgui.GTextField>(this.getChild("betCode"));
		this.m_isWin = <fgui.GTextField>(this.getChild("isWin"));
		this.m_betTime = <fgui.GTextField>(this.getChild("betTime"));
		this.m_status = <fgui.GTextField>(this.getChild("status"));
		this.m_odds = <fgui.GTextField>(this.getChild("odds"));
		this.m_winLoss = <fgui.GTextField>(this.getChild("winLoss"));
		this.m_gameResult = <fgui.GTextField>(this.getChild("gameResult"));
		this.m_amount = <fgui.GTextField>(this.getChild("amount"));
	}
}