/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_add extends fgui.GButton {

	public m_micon:fgui.GLoader;
	public m_closebtn:fgui.GButton;
	public static URL:string = "ui://8xnwynuhywm7l";

	public static createInstance():UI_add {
		return <UI_add>(fgui.UIPackage.createObject("cash", "add"));
	}

	protected onConstruct():void {
		this.m_micon = <fgui.GLoader>(this.getChild("micon"));
		this.m_closebtn = <fgui.GButton>(this.getChild("closebtn"));
	}
}