/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_BigRecordTitle from "./UI_BigRecordTitle";

export default class UI_BigThrend extends fgui.GComponent {

	public m_list:fgui.GList;
	public m_title:UI_BigRecordTitle;
	public static URL:string = "ui://d7esdcvtmi8lz9y";

	public static createInstance():UI_BigThrend {
		return <UI_BigThrend>(fgui.UIPackage.createObject("windows", "BigThrend"));
	}

	protected onConstruct():void {
		this.m_list = <fgui.GList>(this.getChild("list"));
		this.m_title = <UI_BigRecordTitle>(this.getChild("title"));
	}
}