/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_bet_btn from "./UI_bet_btn";

export default class UI_GYHBet extends fgui.GComponent {

	public m_bg:UI_bet_btn;
	public m_bs:UI_bet_btn;
	public m_xd:UI_bet_btn;
	public m_xs:UI_bet_btn;
	public m_b:UI_bet_btn;
	public m_x:UI_bet_btn;
	public m_d:UI_bet_btn;
	public m_s:UI_bet_btn;
	public m_3:UI_bet_btn;
	public m_4:UI_bet_btn;
	public m_5:UI_bet_btn;
	public m_6:UI_bet_btn;
	public m_7:UI_bet_btn;
	public m_8:UI_bet_btn;
	public m_9:UI_bet_btn;
	public m_10:UI_bet_btn;
	public m_11:UI_bet_btn;
	public m_12:UI_bet_btn;
	public m_13:UI_bet_btn;
	public m_14:UI_bet_btn;
	public m_15:UI_bet_btn;
	public m_16:UI_bet_btn;
	public m_17:UI_bet_btn;
	public m_18:UI_bet_btn;
	public m_19:UI_bet_btn;
	public static URL:string = "ui://61ic9kfkgl40z79";

	public static createInstance():UI_GYHBet {
		return <UI_GYHBet>(fgui.UIPackage.createObject("game", "GYHBet"));
	}

	protected onConstruct():void {
		this.m_bg = <UI_bet_btn>(this.getChild("bg"));
		this.m_bs = <UI_bet_btn>(this.getChild("bs"));
		this.m_xd = <UI_bet_btn>(this.getChild("xd"));
		this.m_xs = <UI_bet_btn>(this.getChild("xs"));
		this.m_b = <UI_bet_btn>(this.getChild("b"));
		this.m_x = <UI_bet_btn>(this.getChild("x"));
		this.m_d = <UI_bet_btn>(this.getChild("d"));
		this.m_s = <UI_bet_btn>(this.getChild("s"));
		this.m_3 = <UI_bet_btn>(this.getChild("3"));
		this.m_4 = <UI_bet_btn>(this.getChild("4"));
		this.m_5 = <UI_bet_btn>(this.getChild("5"));
		this.m_6 = <UI_bet_btn>(this.getChild("6"));
		this.m_7 = <UI_bet_btn>(this.getChild("7"));
		this.m_8 = <UI_bet_btn>(this.getChild("8"));
		this.m_9 = <UI_bet_btn>(this.getChild("9"));
		this.m_10 = <UI_bet_btn>(this.getChild("10"));
		this.m_11 = <UI_bet_btn>(this.getChild("11"));
		this.m_12 = <UI_bet_btn>(this.getChild("12"));
		this.m_13 = <UI_bet_btn>(this.getChild("13"));
		this.m_14 = <UI_bet_btn>(this.getChild("14"));
		this.m_15 = <UI_bet_btn>(this.getChild("15"));
		this.m_16 = <UI_bet_btn>(this.getChild("16"));
		this.m_17 = <UI_bet_btn>(this.getChild("17"));
		this.m_18 = <UI_bet_btn>(this.getChild("18"));
		this.m_19 = <UI_bet_btn>(this.getChild("19"));
	}
}