/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_ComboBox1_popup extends fgui.GComponent {

	public m_list:fgui.GList;
	public static URL:string = "ui://d7esdcvtmi8lz9l";

	public static createInstance():UI_ComboBox1_popup {
		return <UI_ComboBox1_popup>(fgui.UIPackage.createObject("windows", "ComboBox1_popup"));
	}

	protected onConstruct():void {
		this.m_list = <fgui.GList>(this.getChild("list"));
	}
}