/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_BigBallBtn2 extends fgui.GButton {

	public m_big:fgui.GTextField;
	public m_double:fgui.GTextField;
	public static URL:string = "ui://d7esdcvtc9q3zag";

	public static createInstance():UI_BigBallBtn2 {
		return <UI_BigBallBtn2>(fgui.UIPackage.createObject("windows", "BigBallBtn2"));
	}

	protected onConstruct():void {
		this.m_big = <fgui.GTextField>(this.getChild("big"));
		this.m_double = <fgui.GTextField>(this.getChild("double"));
	}
}