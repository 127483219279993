/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_NumBallBtn3 from "./UI_NumBallBtn3";
import UI_LHBallBtn2 from "./UI_LHBallBtn2";
import UI_BigBallBtnTitle2 from "./UI_BigBallBtnTitle2";
import UI_BigBallBtn2 from "./UI_BigBallBtn2";
import UI_Footer from "./UI_Footer";
import UI_BindEmailWindow from "./UI_BindEmailWindow";
import UI_winFrame from "./UI_winFrame";
import UI_Button5 from "./UI_Button5";
import UI_RecordBall from "./UI_RecordBall";
import UI_ConfirmWindow from "./UI_ConfirmWindow";
import UI_customer_item_window from "./UI_customer_item_window";
import UI_PayWindow from "./UI_PayWindow";
import UI_customer_item_window from "./UI_customer_item_window";
import UI_ComboBox1_popup from "./UI_ComboBox1_popup";
import UI_NumThrend from "./UI_NumThrend";
import UI_NumRecordRow from "./UI_NumRecordRow";
import UI_NumBallBtn from "./UI_NumBallBtn";
import UI_LHThrend from "./UI_LHThrend";
import UI_LHRecordTitle from "./UI_LHRecordTitle";
import UI_LHBallBtn from "./UI_LHBallBtn";
import UI_LHRecordRow from "./UI_LHRecordRow";
import UI_BigBallBtnTitle from "./UI_BigBallBtnTitle";
import UI_BigRecordTitle from "./UI_BigRecordTitle";
import UI_BigThrend from "./UI_BigThrend";
import UI_BigRecordRow from "./UI_BigRecordRow";
import UI_BigBallBtn from "./UI_BigBallBtn";
import UI_SumRecordRow from "./UI_SumRecordRow";
import UI_SumRecordTitle from "./UI_SumRecordTitle";
import UI_SumThrend from "./UI_SumThrend";
import UI_ChipsWindow from "./UI_ChipsWindow";
import UI_chips_btn from "./UI_chips_btn";
import UI_winFrame from "./UI_winFrame";
import UI_winFrame from "./UI_winFrame";
import UI_GameListWindow from "./UI_GameListWindow";
import UI_Button from "./UI_Button";
import UI_row from "./UI_row";
import UI_frame from "./UI_frame";
import UI_GameRecordWindow from "./UI_GameRecordWindow";
import UI_GameRecordRow from "./UI_GameRecordRow";
import UI_BetRecordWindow from "./UI_BetRecordWindow";
import UI_BetRecordRow from "./UI_BetRecordRow";
import UI_CustomerWindow from "./UI_CustomerWindow";
import UI_customer_item_window from "./UI_customer_item_window";

export default class windowsBinder {
	public static bindAll():void {
		fgui.UIObjectFactory.setExtension(UI_NumBallBtn3.URL, UI_NumBallBtn3);
		fgui.UIObjectFactory.setExtension(UI_LHBallBtn2.URL, UI_LHBallBtn2);
		fgui.UIObjectFactory.setExtension(UI_BigBallBtnTitle2.URL, UI_BigBallBtnTitle2);
		fgui.UIObjectFactory.setExtension(UI_BigBallBtn2.URL, UI_BigBallBtn2);
		fgui.UIObjectFactory.setExtension(UI_Footer.URL, UI_Footer);
		fgui.UIObjectFactory.setExtension(UI_BindEmailWindow.URL, UI_BindEmailWindow);
		fgui.UIObjectFactory.setExtension(UI_winFrame.URL, UI_winFrame);
		fgui.UIObjectFactory.setExtension(UI_Button5.URL, UI_Button5);
		fgui.UIObjectFactory.setExtension(UI_RecordBall.URL, UI_RecordBall);
		fgui.UIObjectFactory.setExtension(UI_ConfirmWindow.URL, UI_ConfirmWindow);
		fgui.UIObjectFactory.setExtension(UI_customer_item_window.URL, UI_customer_item_window);
		fgui.UIObjectFactory.setExtension(UI_PayWindow.URL, UI_PayWindow);
		fgui.UIObjectFactory.setExtension(UI_customer_item_window.URL, UI_customer_item_window);
		fgui.UIObjectFactory.setExtension(UI_ComboBox1_popup.URL, UI_ComboBox1_popup);
		fgui.UIObjectFactory.setExtension(UI_NumThrend.URL, UI_NumThrend);
		fgui.UIObjectFactory.setExtension(UI_NumRecordRow.URL, UI_NumRecordRow);
		fgui.UIObjectFactory.setExtension(UI_NumBallBtn.URL, UI_NumBallBtn);
		fgui.UIObjectFactory.setExtension(UI_LHThrend.URL, UI_LHThrend);
		fgui.UIObjectFactory.setExtension(UI_LHRecordTitle.URL, UI_LHRecordTitle);
		fgui.UIObjectFactory.setExtension(UI_LHBallBtn.URL, UI_LHBallBtn);
		fgui.UIObjectFactory.setExtension(UI_LHRecordRow.URL, UI_LHRecordRow);
		fgui.UIObjectFactory.setExtension(UI_BigBallBtnTitle.URL, UI_BigBallBtnTitle);
		fgui.UIObjectFactory.setExtension(UI_BigRecordTitle.URL, UI_BigRecordTitle);
		fgui.UIObjectFactory.setExtension(UI_BigThrend.URL, UI_BigThrend);
		fgui.UIObjectFactory.setExtension(UI_BigRecordRow.URL, UI_BigRecordRow);
		fgui.UIObjectFactory.setExtension(UI_BigBallBtn.URL, UI_BigBallBtn);
		fgui.UIObjectFactory.setExtension(UI_SumRecordRow.URL, UI_SumRecordRow);
		fgui.UIObjectFactory.setExtension(UI_SumRecordTitle.URL, UI_SumRecordTitle);
		fgui.UIObjectFactory.setExtension(UI_SumThrend.URL, UI_SumThrend);
		fgui.UIObjectFactory.setExtension(UI_ChipsWindow.URL, UI_ChipsWindow);
		fgui.UIObjectFactory.setExtension(UI_chips_btn.URL, UI_chips_btn);
		fgui.UIObjectFactory.setExtension(UI_winFrame.URL, UI_winFrame);
		fgui.UIObjectFactory.setExtension(UI_winFrame.URL, UI_winFrame);
		fgui.UIObjectFactory.setExtension(UI_GameListWindow.URL, UI_GameListWindow);
		fgui.UIObjectFactory.setExtension(UI_Button.URL, UI_Button);
		fgui.UIObjectFactory.setExtension(UI_row.URL, UI_row);
		fgui.UIObjectFactory.setExtension(UI_frame.URL, UI_frame);
		fgui.UIObjectFactory.setExtension(UI_GameRecordWindow.URL, UI_GameRecordWindow);
		fgui.UIObjectFactory.setExtension(UI_GameRecordRow.URL, UI_GameRecordRow);
		fgui.UIObjectFactory.setExtension(UI_BetRecordWindow.URL, UI_BetRecordWindow);
		fgui.UIObjectFactory.setExtension(UI_BetRecordRow.URL, UI_BetRecordRow);
		fgui.UIObjectFactory.setExtension(UI_CustomerWindow.URL, UI_CustomerWindow);
		fgui.UIObjectFactory.setExtension(UI_customer_item_window.URL, UI_customer_item_window);
	}
}