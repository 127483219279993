/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_LHBallBtn extends fgui.GButton {

	public m_bg:fgui.GGraph;
	public static URL:string = "ui://d7esdcvtmi8lz9u";

	public static createInstance():UI_LHBallBtn {
		return <UI_LHBallBtn>(fgui.UIPackage.createObject("windows", "LHBallBtn"));
	}

	protected onConstruct():void {
		this.m_bg = <fgui.GGraph>(this.getChild("bg"));
	}
}