/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_GlobalModalWaiting extends fgui.GComponent {

	public m_t0:fgui.Transition;
	public static URL:string = "ui://1gj14ei7nojpg";

	public static createInstance():UI_GlobalModalWaiting {
		return <UI_GlobalModalWaiting>(fgui.UIPackage.createObject("loading", "GlobalModalWaiting"));
	}

	protected onConstruct():void {
		this.m_t0 = this.getTransition("t0");
	}
}