/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_NumBallBtn extends fgui.GButton {

	public m_red:fgui.GGraph;
	public m_line:fgui.GGraph;
	public static URL:string = "ui://d7esdcvtmi8lz9r";

	public static createInstance():UI_NumBallBtn {
		return <UI_NumBallBtn>(fgui.UIPackage.createObject("windows", "NumBallBtn"));
	}

	protected onConstruct():void {
		this.m_red = <fgui.GGraph>(this.getChild("red"));
		this.m_line = <fgui.GGraph>(this.getChild("line"));
	}
}