import { E_ScreenMode } from "../../../config/Enum"
import { IPConifg } from "../../../config/NetConfig"
import { IBankCard } from "../../../config/interface"
import UI_bind_withdraw from "../../../fgui/bind_withdraw/UI_bind_withdraw"
import ConfigManager from "../../../helper/ConfigManager"
import I18nHelper from "../../../helper/I18nHelper"
import { UIHelper } from "../../../helper/UIHelper"
import { http } from "../../../net/Http"
import { Router } from "../../../util/router"
import { UIBase } from "../../scene/UIBase"
import { CustomerWindow } from "../../windows/CustomerWindow"

export class BindWithdraw extends UIBase {
    private reloadNums: number = 0
    private or: E_ScreenMode
    private uiBindWithdraw: UI_bind_withdraw
    private data:IBankCard = null
    private bindData:{
        name:string,
        bankCode:string
    } = null
    constructor(or: E_ScreenMode,data:IBankCard,bindData:any) {
        super(or == E_ScreenMode.horizontal ? UI_bind_withdraw.createInstance() : UI_bind_withdraw.createInstance());
        this.data = data
        this.bindData = bindData
        this.onUILoaded()
    }
    onUILoaded() {
        this.uiBindWithdraw = this.UI_Item as UI_bind_withdraw
        fgui.GRoot.inst.addChild(this.uiBindWithdraw);
        this.uiBindWithdraw.m_back.onClick(this, () => {
            Laya.Scene.open(Router.Cash, true, null, Laya.Handler.create(this, () => {
            }))
        })
        //提交
        this.uiBindWithdraw.m_modifyBtn.onClick(this, this.bindUsdt)

        //客服列表
        this.uiBindWithdraw.m_help_btn.onClick(this, this.onCustomerClick)
        this.createImg()
        this.uiBindWithdraw.m_title.text = this.bindData.name
        if (this.data) {
            this.uiBindWithdraw.m_img.url =  this.data.img
            this.uiBindWithdraw.m_micon.visible = false
        }
    }
    //客服列表
    customerWindow: CustomerWindow = null
    onCustomerClick() {
        if (!ConfigManager.getInstance().Customer) {
            UIHelper.showMsg(I18nHelper.t('noService'))
            return
        }
        if (ConfigManager.getInstance().Customer.length > 0) {
            if (!this.customerWindow) {
                this.customerWindow = new CustomerWindow(ConfigManager.getInstance().Customer)
            }
            UIHelper.showWindow(this.customerWindow)
        }
        else {
            UIHelper.showMsg(I18nHelper.t('noService'))
        }
    }


    img:string = ""
    bindUsdt() {
        console.log("11111");

        let data = {
            id:this.data ? this.data.id:null,
            // "id": 234, //修改时带提现方式id
            "withdrawType": this.bindData.bankCode, //提现类型
            "name": "", //名称 例：1号钱包
            "img": this.img
        }

        http.addMyBankCard(data, (data: any) => {
            if (data.code == 200) {
                UIHelper.showMsg(I18nHelper.t(data.msg))
                Laya.Scene.open(Router.Cash, true, null, Laya.Handler.create(this, () => {
                }))
            } else {
                UIHelper.showMsg(I18nHelper.t(data.msg))
            }
        })
    }




    /**
   * 用户提交图片 file 表单 基于原生 dom
   *
   */
    createImg() {

        console.log("1212");

        //创建隐藏的file并且把它和按钮对齐。达到位置一致，这里我们默认在0点位置
        let file: any = Laya.Browser.document.createElement("input");
        //设置file样式
        file.style = "filter:alpha(opacity=0);opacity:0;width:100%;height:250px;cursor:pointer;top:50px";
        file.type = "file";//设置类型是file类型。
        file.size = "30";
        file.accept = "image/png/jpg";//设置文件的格式为png；
        file.style.position = "absolute";
        file.id = "withdraw"
        // file.style.zIndex = 999;
        Laya.Browser.document.body.appendChild(file);//添加到页面；
        let fileReader: any = new Laya.Browser.window.FileReader();

        file.onchange = function (e: any): void {
            if (file.files.length > 0) {

                if (5120 * 1024 < file.files[0].size) {
                    UIHelper.showMsg(I18nHelper.t('maxImg'))
                } else {
                    fileReader.readAsDataURL(file.files[0]); //转换图片格式为字符编码
                }

            }
        };
        let self = this
        fileReader.onload = function (e: { target: { result: any; }; }): void {
            if (Laya.Browser.window.FileReader.DONE == fileReader.readyState) {
                let data = e.target.result;
                self.uiBindWithdraw.m_img.url = e.target.result;
                self.img  = e.target.result
                // http.uploadHead({
                //     "img": data
                // }, (res: any) => {
                //     if (res.code == 200) {
                //         PlayerDataManager.getInstance().setAvatar(res.fileName);
                //         (self.uime.m_avatar.getChild("avatar") as fgui.GLoader).url = data
                //     } else {
                //         UIHelper.showMsg("上传头像失败")
                //     }
                // });
            }
        };
    }

    destroy() {
        document.getElementById("withdraw")?.remove()

        this.uiBindWithdraw.dispose()
    }
}