import { E_ScreenMode } from "../../../config/Enum";
import ConfigManager from "../../../helper/ConfigManager"
import { http } from "../../../net/Http"
import { Router } from "../../../util/router"
import { ISelectWater } from "../../../config/interface"
import { UIHelper } from "../../../helper/UIHelper";
import { LayaHelper } from "../../../helper/LayaHelper";
import UI_wallet_record from "../../../fgui/wallet_record/UI_wallet_record";
import { UIBase } from "../../scene/UIBase";
import { CustomerWindow } from "../../windows/CustomerWindow";
import UI_wallet_row from "../../../fgui/wallet_record/UI_wallet_row";
import I18nHelper from "../../../helper/I18nHelper";
export class WalletRecord extends UIBase {
    private reloadNums: number = 0
    private or: E_ScreenMode
    private uiWalletRecord: UI_wallet_record
    data: ISelectWater[] = []
    private start_time: fgui.GTextInput
    private end_time: fgui.GTextInput
    private timeData: {
        beginTime: string,
        endTime: string
    }
    //页码
    private _pageNum: number = 1;
    private _total: number = 0;
    //刷新头部
    private _header: fairygui.GComponent;
    //刷新头部控制器
    private _header_c1: fgui.Controller;
    //数据类型
    private settledType: number = 0;
    constructor(or: E_ScreenMode, type: number) {
        super(or == E_ScreenMode.horizontal ? UI_wallet_record.createInstance() : UI_wallet_record.createInstance());
        this.settledType = type
        this.onUILoaded()
    }

    onUILoaded() {
        this.uiWalletRecord = this.UI_Item as UI_wallet_record
        fgui.GRoot.inst.addChild(this.uiWalletRecord)
        this.uiWalletRecord.m_title.text = this.settledType == 0 ? I18nHelper.t('order') : I18nHelper.t('withdraw')
        //刷新控制器
        this._header = this.uiWalletRecord.m_list.scrollPane.header
        this._header_c1 = this._header.getController("c1");
        this._header.on(fgui.Events.SIZE_CHANGED, this, this.onSizeChanged);

        this.uiWalletRecord.m_list.on(fgui.Events.PULL_DOWN_RELEASE, this, this.onPullDownToRefresh);
        this.uiWalletRecord.m_list.on(fgui.Events.PULL_UP_RELEASE, this, this.onPullUpToRefresh);
        //刷新控制器

        this.start_time = this.uiWalletRecord.m_start_time
        this.end_time = this.uiWalletRecord.m_end_time
        this.initTime()
        this.uiWalletRecord.m_back.onClick(this, this.back)
        this.uiWalletRecord.m_list.itemRenderer = Laya.Handler.create(this, this.renderListItem, null, false)
        this.uiWalletRecord.m_search.onClick(this, () => {
            this.timeData = {
                "beginTime": this.start_time.text,
                "endTime": this.end_time.text,
            }
            this._pageNum = 1
            this.getList();
            this.uiWalletRecord.m_btn_list.selectNone()
        })
        this.uiWalletRecord.m_btn_list.getChild('today').onClick(this, () => {
            console.log('今天', LayaHelper.getDays())
            this.timeData = {
                "beginTime": LayaHelper.getDays().startTime,
                "endTime": LayaHelper.getDays().endTime
            }
            this._pageNum = 1
            this.start_time.text = this.timeData.beginTime
            this.end_time.text = this.timeData.endTime
            this.getList();
        })
        this.uiWalletRecord.m_btn_list.getChild('yesyerday').onClick(this, () => {
            console.log('昨天', LayaHelper.getLastDays())
            this.timeData = {
                "beginTime": LayaHelper.getLastDays().startTime,
                "endTime": LayaHelper.getLastDays().endTime
            }
            this._pageNum = 1
            this.start_time.text = this.timeData.beginTime
            this.end_time.text = this.timeData.endTime
            this.getList();
        })
        this.uiWalletRecord.m_btn_list.getChild('seven_day').onClick(this, () => {
            console.log('近7天', LayaHelper.getCurrWeekDays())
            this.timeData = {
                "beginTime": LayaHelper.getCurrWeekDays().startTime,
                "endTime": LayaHelper.getCurrWeekDays().endTime
            }
            this._pageNum = 1
            this.start_time.text = this.timeData.beginTime
            this.end_time.text = this.timeData.endTime
            this.getList();
        })
        this.uiWalletRecord.m_btn_list.getChild('month_day').onClick(this, () => {
            console.log('近1个月', LayaHelper.getCurrMonthDays())
            this.timeData = {
                "beginTime": LayaHelper.getCurrMonthDays().startTime,
                "endTime": LayaHelper.getCurrMonthDays().endTime
            }
            this._pageNum = 1
            this.start_time.text = this.timeData.beginTime
            this.end_time.text = this.timeData.endTime
            this.getList();
        })

        //客服列表
        this.uiWalletRecord.m_help_btn.onClick(this, this.onCustomerClick)
    }
    //客服列表
    customerWindow: CustomerWindow = null
    onCustomerClick() {
        if (!ConfigManager.getInstance().Customer) {
            UIHelper.showMsg(I18nHelper.t('noService'))
            return
        }
        if (ConfigManager.getInstance().Customer.length > 0) {
            if (!this.customerWindow) {
                this.customerWindow = new CustomerWindow(ConfigManager.getInstance().Customer)
            }
            UIHelper.showWindow(this.customerWindow)
        }
        else {
            UIHelper.showMsg(I18nHelper.t('noService'))
        }
    }
    renderListItem(index: number, obj: fairygui.GObject) {
        let row = obj as UI_wallet_row
        row.m_m1.text = this.data[index].createTime

        if (this.settledType == 0) {

            row.m_m2.text = String(this.data[index].changeBalance)
            row.m_m3.text = String(this.data[index].newBalance)
        } else {

            row.m_m2.text = String(this.data[index].afterAmount)
            row.m_m3.text = String(this.data[index].beforeAmount)
        }

    }

    //获取列表
    getList() {
        this.start_time.text = this.timeData.beginTime
        this.end_time.text = this.timeData.beginTime
        var params = {
            beginTime: this.timeData.beginTime,
            endTime: this.timeData.beginTime,

            date: <any>(<any>window).dayjs().format('YYYY-MM-DD'),
        }
        console.log(params)


        if (this.settledType == 0) {
            // 存款
            return new Promise((resolve, reject) => {
                http.rechargeOrderList(params, (res: any) => {
                    if (res.code == 200) {
                        this._total = res.total
                        console.log(res)

                        this.data = res.data
                        this.uiWalletRecord.m_list.numItems = this.data.length
                        resolve(true)
                    } else {
                        UIHelper.showMsg(I18nHelper.t(res.msg))
                        reject(false)
                    }

                });
            });
        } else {
            // 提现
            return new Promise((resolve, reject) => {
                http.withdrawOrderList(params, (res: any) => {
                    if (res.code == 200) {
                        this._total = res.total
                        console.log(res)

                        this.data = res.data
                        this.uiWalletRecord.m_list.numItems = this.data.length
                        resolve(true)
                    } else {
                        UIHelper.showMsg(I18nHelper.t(res.msg))
                        reject(false)
                    }

                });
            });

        }


    }

    protected initTime() {
        let time = new Date();
        let timeText = time.toISOString().slice(0, 10);
        this.start_time.text = timeText
        this.end_time.text = timeText
        this.start_time._displayObject.type = Laya.Input.TYPE_DATE
        // this.start_time._displayObject.bgColor = "#525152"
        this.start_time._displayObject.align = "center"
        this.end_time._displayObject.type = Laya.Input.TYPE_DATE
        // this.end_time._displayObject.bgColor = "#525152"
        this.end_time._displayObject.align = "center"
        this.timeData = {
            "beginTime": LayaHelper.getDays().startTime,
            "endTime": LayaHelper.getDays().endTime
        }
        this.getList()
    }


    //下拉加载
    private async onPullDownToRefresh(evt: Laya.Event): Promise<void> {
        console.log('准备');
        // console.log(header);

        if (this._header_c1.selectedIndex == 1) {
            this._header_c1.selectedIndex = 2;
            this.uiWalletRecord.m_list.scrollPane.lockHeader(this._header.sourceHeight);
            Laya.timer.once(2000, this, async () => {
                var result = await this.getList();
                if (this.uiWalletRecord.isDisposed) {
                    return;
                }
                this._header_c1.selectedIndex = 0;
                this.uiWalletRecord.m_list.scrollPane.lockHeader(0);
            });
        }

    }

    //监听头部刷新控制器
    onSizeChanged(): void {
        if (this._header_c1.selectedIndex == 2)
            return;
        if (this._header.height > this._header.sourceHeight)
            this._header_c1.selectedIndex = 1;
        else
            this._header_c1.selectedIndex = 0;
    }

    //上拉加载
    private async onPullUpToRefresh(evt: Laya.Event): Promise<void> {
        var footer: fgui.GComponent = this.uiWalletRecord.m_list.scrollPane.footer.asCom;

        footer.getController("c1").selectedIndex = 1;
        this.uiWalletRecord.m_list.scrollPane.lockFooter(footer.sourceHeight);

        //Simulate a async resquest
        //Simulate a async resquest       
        if (this.data.length < this._total) {
            this._pageNum++
            //Simulate a async resquest
            var result = await this.getList();
            if (this.uiWalletRecord.isDisposed)
                return;

            //Refresh completed
            footer.getController("c1").selectedIndex = 0;
            this.uiWalletRecord.m_list.scrollPane.lockFooter(0);
        } else {
            this.uiWalletRecord.m_list.scrollPane.lockFooter(0);
            return;
        }

    }




    back() {
        Laya.Scene.open(Router.Charge, true, null, Laya.Handler.create(this, () => {
        }))
    }


    destroy() {
        this.uiWalletRecord.dispose()
    }
}