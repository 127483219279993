/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_b_btn from "./UI_b_btn";
import UI_x_btn from "./UI_x_btn";

export default class UI_TMDXDSArea extends fgui.GComponent {

	public m_b:UI_b_btn;
	public m_x:UI_x_btn;
	public m_d:UI_b_btn;
	public m_s:UI_x_btn;
	public static URL:string = "ui://61ic9kfkddvkz7j";

	public static createInstance():UI_TMDXDSArea {
		return <UI_TMDXDSArea>(fgui.UIPackage.createObject("game", "TMDXDSArea"));
	}

	protected onConstruct():void {
		this.m_b = <UI_b_btn>(this.getChild("b"));
		this.m_x = <UI_x_btn>(this.getChild("x"));
		this.m_d = <UI_b_btn>(this.getChild("d"));
		this.m_s = <UI_x_btn>(this.getChild("s"));
	}
}