import { IPConifg } from "../config/NetConfig";
import HttpHelper from "../helper/HttpHelper";
import I18nHelper from "../helper/I18nHelper";
import { LayaHelper } from "../helper/LayaHelper";
import { UIHelper } from "../helper/UIHelper";
import NetManager from "../net/NetManager";
import LocalUtil from "../util/LocalUtil";
export default class Http {



    public httpGets(url: string, callback: (arg0: { code: number; }) => void, params?: any) {
        try {
            var xhr = new XMLHttpRequest() // new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                // if ((xhr.status >= 301 && xhr.status <= 308) && xhr.getResponseHeader("Location")) {
                //     console.log(redirectUrl );
                //     // 如果收到302重定向，获取新的URL并发起请求
                //     var redirectUrl = xhr.getResponseHeader('Location');
                //     //; 可以选择在控制台输出新的URL
                //     // 再次发起请求到新的URL
                //     xhr.open("GET", redirectUrl, true);
                //     xhr.send()
                // }


                if (xhr.status == 4001) {
                    let res = { code: 4001, msg: xhr.responseText }
                    UIHelper.showMsg(xhr.responseText)
                    HttpHelper.getInstance().logOut()
                }

                if (xhr.readyState === 4 && (xhr.status >= 200 && xhr.status < 300)) {
                    // notice.emit("loadingBoxOff")
                    var respone = xhr.responseText;
                    var myJSON = JSON.parse(respone);
                    callback(myJSON);
                } else if (xhr.readyState === 4 && xhr.status == 404) {
                    let res = { code: 0 }
                    res.code = 1000
                    callback(res)
                }
            };




            if (params) {
                const urlWithParams = `${url}?${new URLSearchParams(params)}`;
                xhr.open("GET", urlWithParams, true);
            } else {
                xhr.open("GET", url, true);
            }
            // xhr.open("GET", url, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("accept-language", this.getLang());
            xhr.setRequestHeader("token", NetManager.getInstance().token)

            xhr.timeout = 8000;
            xhr.ontimeout = function (event) {
                if (callback) {
                    let res = { code: 0, msg: '' }
                    res.msg = "Network Timeout"
                    res.code = 1001
                    callback(res)
                }
            }
            xhr.onerror = function (error) {
                console.log(error);

                UIHelper.showMsg("Network Error")

                // showWarningDialog(_Strings.systemError);
                setTimeout(function () {
                    let res = { code: 0, msg: '' }
                    res.code = 1002
                    res.msg = "Network Error"
                    callback(res)
                }, 500);
            };
            xhr.send();
        } catch (error) {
            let res = { code: 0 }
            res.code = 1002
            callback(res)
            UIHelper.showMsg("Network Error")

        }
        return xhr
        // notice.emit("loadingBoxOn")
    }
    public httpPost(url: string, params: {}, callback: (arg0: any) => void) {
        // notice.emit("loadingBoxOn")
        try {
            var xhr = new XMLHttpRequest()//new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                if (xhr.status == 4001) {
                    let res = { code: 4001, msg: xhr.responseText }
                    UIHelper.showMsg(xhr.responseText)
                    HttpHelper.getInstance().logOut()
                }
                if (xhr.readyState === 4 && (xhr.status >= 200 && xhr.status < 300)) {
                    var respone = xhr.responseText;
                    var myJSON = JSON.parse(respone);
                    callback(myJSON);
                } else {
                    // callback(-1);
                }
            }
            xhr.open("POST", url, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.setRequestHeader("accept-language", this.getLang());
            // xhr.setRequestHeader("AuthToken", "f1dZDerpEy/RvsNuWMUz8Q==")
            xhr.setRequestHeader("token", NetManager.getInstance().token)
            xhr.timeout = 8000;
            xhr.ontimeout = function (event) {
                if (callback) {
                    let res = { code: 0 }
                    res.code = 1001
                    callback(res)
                }
            }
            xhr.onerror = function (error) {
                // showWarningDialog(_Strings.systemError);
                console.log(error);

                UIHelper.showMsg("Network Error")


                setTimeout(function () {
                    let res = { code: 0 }
                    res.code = 1002
                    callback(res)
                }, 500);
            };

            xhr.send(JSON.stringify(params));
        } catch (error) {
            let res = { code: 0 }
            res.code = 1002
            callback(res)
            UIHelper.showMsg("Network Error")
            console.log(error);

        }

        return xhr

    }
    public getLang() {

        let language = LocalUtil.stringForKey("lang", I18nHelper.getLoaclLang())
        return language
    }
    /**
     * 登陆
     * 
     */
    public login(data: any, callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgUser/login`
        return this.httpPost(url, data, callback)
    }
    /**
    * 获取验证码
    * 
    */
    public getVerifyCode(callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgUser/verifyCode`
        return this.httpGets(url, callback)
    }
    public sendEmailCode(data: any, callback: any) {


        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgUser/sendCode`
        return this.httpGets(url, callback, data)
    }
    /**
    * 注册
    * 
    */
    public register(data: any, callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgUser/register?password=${data.password}&phone=${data.phone}&name=${data.name}&checkPassword=${data.checkPassword}&code=${data.code}&uuid=${data.uuid}&tgAccount=${data.tgAccount}`
        return this.httpGets(url, callback)
    }

    public findPwd(data: any, callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgUser/findPwd`
        return this.httpPost(url, data, callback)
    }

    /**
   * 首页游戏列表
   * 
   */
    public getGameList(callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/games/list`
        return this.httpGets(url, callback)
    }
    /**
      * 获取游戏活动
      * 
      */
    public getActive(callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/games/activityTypeList`
        return this.httpGets(url, callback)
    }
    /**
     * 修改密码
     * 
     */
    public modifyPwd(data: any, callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgUser/updatePwd`
        return this.httpPost(url, data, callback)
    }


    /**
     * 绑定邮箱
     */
    public userBind(data: any, callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgUser/userBind`
        return this.httpPost(url, data, callback)
    }


    /**
    * 获取注单
    * 
    */
    public selectBetRecords(data: any, callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgUser/selectBetRecords?date=${data.date}`
        return this.httpGets(url, callback)
    }
    /**
    * 获取反水列表
    * 
    */
    public getBackWater(data: any, callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgUser/selectWater?date=${data.date}`
        return this.httpGets(url, callback)
    }

    /**
    * 获取变动类型
    * 
    */
    public getSelectLogType(data: any, callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgUser/selectLogType`
        return this.httpGets(url, callback, data,)
    }



    /**
    * 绑定u币地址
    * 
    */
    public bindCard(data: any, callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgUser//bindUCoinAddr`
        return this.httpGets(url, callback, data)
    }
    /**
   * 获取公告
   * 
   */
    public getNotice(callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/notice/getNotice`
        return this.httpGets(url, callback)
    }
    /**
     * 获取轮播图
     * 
     */
    public getSwiper(callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/games/bannerList`
        return this.httpGets(url, callback)
    }


    /**
    * 获取客服列表
    * 
    */
    public getCustomer(callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgUser/getOnlineService`
        return this.httpGets(url, callback)
    }


    public getGameBetItems(data: any, callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/games/getGameBetItems?gameCode=${data.gameCode}&roomId=${data.roomId}`
        return this.httpGets(url, callback)
    }




    public userBetInfo(data: any, callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/games/bet`
        return this.httpPost(url, data, callback)
    }

    /** 开奖记录 */
    public lotteryRecord(data: any, callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/games/lotteryRecord`
        return this.httpGets(url, callback, data)
    }

    /** 查询余额 */
    public getBalance(callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgUser/getBalance`
        return this.httpGets(url, callback)
    }
    /** 查询个人信息 */
    public getUserInfo(callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgUser/getUserInfo`
        return this.httpGets(url, callback)
    }


    /** 房间消息 */
    public getUserMessage(roomId: number, callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgUser/getUserMessage?roomId=${roomId}`
        return this.httpGets(url, callback)
    }


    /** 修改支付密码 */
    public updateWithdrawalPwd(data: any, callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgUser/updateWithdrawalPwd`
        return this.httpPost(url, data, callback)
    }


    /** 获取房间配置信息 */
    public getRoomInfo(roomId: any, callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/games/roomInfo?roomId=${roomId}`
        return this.httpGets(url, callback)
    }

    /** 绑定地址 */
    public addMyBankCard(data: any, callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgUser/addMyBankCard`
        return this.httpPost(url, data, callback)
    }

    /** 获取提现地址 */
    public getMyBankCard(callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgUser/getMyBankCard`
        return this.httpGets(url, callback)
    }

    /** 删除提现地址 */
    public delMyBankCard(data: any, callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgUser/delMyBankCard`
        return this.httpPost(url, data, callback)
    }

    /** 银行卡列表 */
    public getBankList(callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgUser/getBankList`
        return this.httpGets(url, callback)
    }

    /** 获取充值通道 */
    public getRechargeConfig(callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgPay/getRechargeConfig`
        return this.httpGets(url, callback)
    }

    /** 提交订单 */
    public createPayOrder(data: { payId: any; money: any; }, callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgPay/createOrder?payId=${data.payId}&money=${data.money}`
        return this.httpPost(url, {}, callback)
    }

    /** 排行榜 */
    public getRankList(data: any, callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgUser/winRank?date=${data.date}&roomId=${data.roomId}`
        return this.httpGets(url, callback)
    }

    /** 获取支付USDT */
    public getUSDT(callback: (arg0: { code: number; }) => void) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgUser/getBossUsdtAddress`
        return this.httpGets(url, callback)
    }


    /** 头像上传 */
    public uploadHead(data: any, callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgUser/uploadHead`
        return this.httpPost(url, data, callback)
    }

    /** 获取最新开奖结果 */
    public newOpenLottery(roomId: any, callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/games/newOpenLottery?roomId=${roomId}`
        return this.httpGets(url, callback)
    }

    /** 存款记录 */
    public rechargeOrderList(data: any, callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgPay/rechargeOrderList`
        return this.httpGets(url, callback)
    }
    /** 提现记录 */
    public withdrawOrderList(data: any, callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgWithdrawal/withdrawOrderList`
        return this.httpGets(url, callback)
    }

    /** 提现订单 */
    public createOrder(data: any, callback: any) {
        console.log("data", data);

        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgWithdrawal/createOrder`
        return this.httpPost(url, data, callback)
    }

    public selectBalanceLog(data: any, callback: any) {
        let url
        if (data.logType) {
            url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgUser/selectBalanceLog?date=${data.date}`

        } else {
            url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgUser/selectBalanceLog?date=${data.date}`

        }
        return this.httpGets(url, callback)
    }


    public applyRefund(id: any, callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgUser/applyRefund/${id}`
        return this.httpPost(url, {}, callback)
    }



    public userGetAgentInfo(callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgUser/userGetAgentInfo`
        return this.httpGets(url, callback)
    }


    public applyAgent(isCredit: number, callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgUser/applyAgent/${isCredit}`
        return this.httpPost(url, {}, callback)
    }


    /**
     * 获取翻译字典
     * @param langTag 
     * @param callback 
     * @returns 
     */
    public getLanguageDict(langTag: string, callback: any) {
        let url = IPConifg.httpIp  + `/api/lgUser/getLanguageDict?language=${langTag}`
        return this.httpGets(url, callback)
    }



    public getGlobalParameter(key: string, callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgUser/getGlobalParameter`
        return this.httpGets(url, callback)
    }


    /**
     * 更新用户昵称
     * @param data 
     * @param callback 
     * @returns 
     */
    public updateUsername(data: any, callback: any) {
        let url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgUser/updateUsername?newName=${data.newName}`
        return this.httpGets(url, callback)
    }

    /**
     * 
     * @param agentOrUser  1-查代理 2-查用户 不传-查所有
     * @param callback 
     * @returns 
     */
    public getSubAgentAndUser(callback: any, agentOrUser?: any) {
        let url = ""
        if (agentOrUser) {
            url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgUser/getSubAgentAndUser?agentOrUser=${agentOrUser}`
        } else {
            url = IPConifg.httpIp + IPConifg.httpPort + `/api/lgUser/getSubAgentAndUser`
        }
        return this.httpGets(url, callback)
    }

};
export let http = new Http();