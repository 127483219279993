import { E_ScreenMode } from "./config/Enum";
import I18nHelper from "./helper/I18nHelper";
import { UIManager } from "./helper/UIManager";
import { http } from "./net/Http";
import { Loading } from "./ui/loading/Loading";
import LocalUtil from "./util/LocalUtil";
const { regClass, property } = Laya;
@regClass('e60XQm7tTY2BwFAdxb8D1g')
export class Main extends Laya.Script {


    onStart() {
        if (LocalUtil.boolForKey("isFirst")) {
            LocalUtil.setBool(false,"isFirst")
            localStorage.clear()
        }


        Laya.stage.addChild(fgui.GRoot.inst.displayObject);
        Laya.Config.isAlpha = true;
        Laya.stage.bgColor = null;
        Laya.stage.screenMode = E_ScreenMode.vertical
        this.init()
        this.createVideo()
    }
    async init() {
        I18nHelper.init()

        let lang = LocalUtil.stringForKey("lang", I18nHelper.getLoaclLang())
        http.getLanguageDict(lang, (res: { code: number; msg: string; data: any; }) => {
            console.log("翻译字典", res);
            if (res.code == 200) {
                I18nHelper.addResourceBundle(lang, res.data)
            }
        })


        Laya.stage.screenMode = UIManager.getOr()
        if (Laya.stage.screenMode == E_ScreenMode.horizontal) {
            Laya.stage.width = 1080
            Laya.stage.height = 1920
            new Loading(E_ScreenMode.horizontal)
            Laya.stage.scaleMode = "showall"
        } else {
            Laya.stage.width = 1080
            Laya.stage.height = 1920
            new Loading(E_ScreenMode.vertical)
            Laya.stage.scaleMode = "fixedwidth"
        }

    }


    createVideo() {
        let div = document.createElement("div");
        div.className = "progress-bar";
        let div1 = document.createElement("div");
        div1.className = "progress";
        div1.style.width = "0%";
        let div2 = document.createElement("div");
        div2.className = "progress-text";
        div2.innerHTML = "0%";
        div.appendChild(div1)
        div.appendChild(div2)
        document.body.appendChild(div);

  

        let video = document.createElement("img") as HTMLImageElement
        video.id = "loadingVideo"
        video.style.width = "100%"
        video.style.height = "100%"
        video.style.objectFit = "cover"
        // video.style.position = "absolute"
        // video.style.top = "50%"
        // video.style.transform = "translateY(-50%)"
        // video.style.height = "100%"
        // video.autoplay = true
        // video.loop = true
        // video.muted = true
        video.src = "/resources/loading.gif"
        document.body.appendChild(video)


        let layaCanvas = document.getElementById("layaCanvas")
        layaCanvas.style.display = "none"

     
    }




}