/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_Button5 from "./UI_Button5";

export default class UI_BindEmailWindow extends fgui.GComponent {

	public m_mask:fgui.GGraph;
	public m_username:fgui.GTextInput;
	public m_authCode:UI_Button5;
	public m_phone:fgui.GGroup;
	public m_authCodeInput:fgui.GTextInput;
	public m_authNode:fgui.GGroup;
	public m_password:fgui.GTextInput;
	public m_psd:fgui.GGroup;
	public m_reg_password2:fgui.GTextInput;
	public m_repsd:fgui.GGroup;
	public m_registerBtn:fgui.GButton;
	public m_form:fgui.GGroup;
	public m_back:fgui.GButton;
	public static URL:string = "ui://d7esdcvti3x2zau";

	public static createInstance():UI_BindEmailWindow {
		return <UI_BindEmailWindow>(fgui.UIPackage.createObject("windows", "BindEmailWindow"));
	}

	protected onConstruct():void {
		this.m_mask = <fgui.GGraph>(this.getChild("mask"));
		this.m_username = <fgui.GTextInput>(this.getChild("username"));
		this.m_authCode = <UI_Button5>(this.getChild("authCode"));
		this.m_phone = <fgui.GGroup>(this.getChild("phone"));
		this.m_authCodeInput = <fgui.GTextInput>(this.getChild("authCodeInput"));
		this.m_authNode = <fgui.GGroup>(this.getChild("authNode"));
		this.m_password = <fgui.GTextInput>(this.getChild("password"));
		this.m_psd = <fgui.GGroup>(this.getChild("psd"));
		this.m_reg_password2 = <fgui.GTextInput>(this.getChild("reg_password2"));
		this.m_repsd = <fgui.GGroup>(this.getChild("repsd"));
		this.m_registerBtn = <fgui.GButton>(this.getChild("registerBtn"));
		this.m_form = <fgui.GGroup>(this.getChild("form"));
		this.m_back = <fgui.GButton>(this.getChild("back"));
	}
}