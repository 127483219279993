import { E_ScreenMode } from "../../../config/Enum"
import { cnf_event } from "../../../config/Event_cnf"
import UI_Charge from "../../../fgui/charge/UI_Charge"
import ConfigManager from "../../../helper/ConfigManager"
import HttpHelper from "../../../helper/HttpHelper"
import I18nHelper from "../../../helper/I18nHelper"
import { notice } from "../../../helper/NoticeHelper"
import { UIHelper } from "../../../helper/UIHelper"
import PlayerDataManager from "../../../model/PlayerDataManager"
import { Router } from "../../../util/router"
import { UIBase } from "../../scene/UIBase"
import { WalletRecordScene } from "../../scene/WalletRecordScene"
import { CustomerWindow } from "../../windows/CustomerWindow"

export class Charge extends UIBase {
    private reloadNums: number = 0
    private or: E_ScreenMode
    private uiCharge: UI_Charge

    constructor(or: E_ScreenMode) {
        super(or == E_ScreenMode.horizontal ? UI_Charge.createInstance() : UI_Charge.createInstance());
        this.onUILoaded()
    }
    onUILoaded() {
        this.uiCharge = this.UI_Item as UI_Charge

        fgui.GRoot.inst.addChild(this.uiCharge)
        console.log("this.uiCharge:", this.uiCharge);

        this.uiCharge.m_back.on(Laya.Event.CLICK, this, () => {
            Laya.Scene.open(Router.Wallet, true, null, Laya.Handler.create(this, () => {
            }))
        })
        //刷新余额
        this.uiCharge.m_my_wallet.m_refsh_btn.onClick(this, () => {
            this.uiCharge.m_my_wallet.m_refsh_btn.m_t0.play()
            HttpHelper.getInstance().getUserBalance()
            Laya.timer.once(2000, this, async () => {
                this.uiCharge.m_my_wallet.m_refsh_btn.m_t0.stop()
            });
        })

        this.uiCharge.m_my_wallet.m_wallet_btn.onClick(this, () => {
            Laya.Scene.open(Router.Wallet, true, null, Laya.Handler.create(this, () => {
            }))
        })

        //跳转提现
        this.uiCharge.m_my_wallet.m_cash_btn.onClick(this, () => {
            console.log("打印了?");
            Laya.Scene.open(Router.Cash, true, null, Laya.Handler.create(this, () => {
            }))
        })


        //跳转存款记录
        this.uiCharge.m_withdrawal.onClick(this, () => {
            Laya.Scene.open(Router.WalletRecord, true, { "type": "1" }, Laya.Handler.create(this, (res: Laya.Scene) => {

                let walletRecordScene: WalletRecordScene = res.getComponent(WalletRecordScene)
                walletRecordScene.onOpened({ "type": 1 })
            }))
        })
        //跳转提现记录
        this.uiCharge.m_deprec.onClick(this, () => {
            Laya.Scene.open(Router.WalletRecord, true, { "type": "0" }, Laya.Handler.create(this, (res: Laya.Scene) => {

                let walletRecordScene: WalletRecordScene = res.getComponent(WalletRecordScene)
                walletRecordScene.onOpened({ "type": 0 })
            }))
        })

        notice.on(cnf_event.OnBalanceChanged,this.OnBalanceChanged)

        this.OnBalanceChanged()
      //客服列表
   this.uiCharge.m_help_btn.onClick(this, this.onCustomerClick)
}
//客服列表
customerWindow: CustomerWindow = null
onCustomerClick() {
    if (!ConfigManager.getInstance().Customer) {
        UIHelper.showMsg(I18nHelper.t('noService'))
        return
    }
    if (ConfigManager.getInstance().Customer.length > 0) {
        if (!this.customerWindow) {
            this.customerWindow = new CustomerWindow(ConfigManager.getInstance().Customer)
        }
        UIHelper.showWindow(this.customerWindow)
    }
    else {
        UIHelper.showMsg(I18nHelper.t('noService'))
    }
}

    OnBalanceChanged = ()=>{
        this.uiCharge.m_my_wallet.m_num.text = PlayerDataManager.getInstance().getBalance().toString()
    }

    destroy() {
        notice.off(cnf_event.OnBalanceChanged,this.OnBalanceChanged)

        this.uiCharge.dispose()
    }
}