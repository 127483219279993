/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_message_tips extends fgui.GComponent {

	public m_message:fgui.GTextField;
	public static URL:string = "ui://1exd9k18nojpe";

	public static createInstance():UI_message_tips {
		return <UI_message_tips>(fgui.UIPackage.createObject("common", "message_tips"));
	}

	protected onConstruct():void {
		this.m_message = <fgui.GTextField>(this.getChild("message"));
	}
}