/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_modify extends fgui.GComponent {

	public m_oldPassword:fgui.GTextInput;
	public m_old_psd:fgui.GGroup;
	public m_newPassword:fgui.GTextInput;
	public m_new_psd:fgui.GGroup;
	public m_twoPassword:fgui.GTextInput;
	public m_new_again_psd:fgui.GGroup;
	public m_modifyBtn:fgui.GButton;
	public m_back:fgui.GButton;
	public m_help_btn:fgui.GButton;
	public static URL:string = "ui://z7wn7v8qix0t0";

	public static createInstance():UI_modify {
		return <UI_modify>(fgui.UIPackage.createObject("modify", "modify"));
	}

	protected onConstruct():void {
		this.m_oldPassword = <fgui.GTextInput>(this.getChild("oldPassword"));
		this.m_old_psd = <fgui.GGroup>(this.getChild("old_psd"));
		this.m_newPassword = <fgui.GTextInput>(this.getChild("newPassword"));
		this.m_new_psd = <fgui.GGroup>(this.getChild("new_psd"));
		this.m_twoPassword = <fgui.GTextInput>(this.getChild("twoPassword"));
		this.m_new_again_psd = <fgui.GGroup>(this.getChild("new_again_psd"));
		this.m_modifyBtn = <fgui.GButton>(this.getChild("modifyBtn"));
		this.m_back = <fgui.GButton>(this.getChild("back"));
		this.m_help_btn = <fgui.GButton>(this.getChild("help_btn"));
	}
}