import { IPConifg } from "../../config/NetConfig";
import UI_CustomerWindow from "../../fgui/windows/UI_CustomerWindow";
import UI_customer_item_window from "../../fgui/windows/UI_customer_item_window";
import I18nHelper from "../../helper/I18nHelper";
import { UIHelper } from "../../helper/UIHelper";
import PlayerDataManager from "../../model/PlayerDataManager";
export class CustomerWindow extends fgui.Window {
    private customer_list: fgui.GList
    private customerData: any
    public constructor(data: any) {
        super();
        this.customerData = data
    }
    protected onInit(): void {
        console.log(this.customerData)
        this.contentPane = UI_CustomerWindow.createInstance();
        this.customer_list = this.contentPane.getChild("customerList")
        this.customer_list.itemRenderer = Laya.Handler.create(this, this.renderListItem, null, false);
        this.customer_list.numItems = this.customerData.length;
        this.setPivot(0.5, 0.5);
        this.center();


        this.contentPane.getChild('mask').onClick(this,()=>{
            this.doHideAnimation()
        })
    }
    protected doShowAnimation(): void {
        this.setScale(0.1, 0.1);
        fgui.GTween.to2(0.1, 0.1, 1, 1, 0.3)
            .setTarget(this, this.setScale)
            .setEase(fgui.EaseType.QuadOut)
            .onComplete(this.onShown, this);
    }
    protected doHideAnimation(): void {
        fgui.GTween.to2(1, 1, 0.1, 0.1, 0.3)
            .setTarget(this, this.setScale)
            .setEase(fgui.EaseType.QuadOut)
            .onComplete(this.hideImmediately, this);
    }
    //列表
    private renderListItem(index: number, item: UI_customer_item_window): void {
        (item.m_avatar.getChild("avatar") as fgui.GLoader).url =  this.customerData[index].head
        item.getChild("title").text = this.customerData[index].pairCode ? I18nHelper.t(this.customerData[index].pairCode): this.customerData[index].name
        item.getChild("account").text =  this.customerData[index].name
        item.getChild("btn").onClick(this, this.onCopyClick, [this.customerData[index]])
    }


    // 复制并打开如微信之类
    protected onCopyClick(args: any): void {
        if (args.isOutChain == "2") {
            window.open(`${args.account}&visitor_id=${PlayerDataManager.getInstance().getPlayerInfo().id}&visitor_name=${PlayerDataManager.getInstance().getPlayerInfo().name}`)
        } else {
            UIHelper.showIFrame(`${args.account}&visitor_id=${PlayerDataManager.getInstance().getPlayerInfo().id}&visitor_name=${PlayerDataManager.getInstance().getPlayerInfo().name}`)
        }


        // window.open(args)
        return
        let str = args

        if (args.includes("weixin://")) {
            str = args.replace("weixin://", "")
        }


        if (navigator.clipboard) {
            navigator.clipboard.writeText(str)
        } else {
            const input = document.createElement('textarea')
            input.value = str
            document.body.appendChild(input)
            input.select()
            document.execCommand('copy')
            document.body.removeChild(input)
        }
        UIHelper.showMsg(I18nHelper.t('copySuccess'))

  
    }


    destroy() {
        this.customer_list.dispose
    }
}
