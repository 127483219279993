/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_toggleCom extends fgui.GComponent {

	public m_c1:fgui.Controller;
	public m_toggleBtn:fgui.GButton;
	public m_circle:fgui.GImage;
	public m_circle2:fgui.GImage;
	public static URL:string = "ui://q1kq7fv1mzb726";

	public static createInstance():UI_toggleCom {
		return <UI_toggleCom>(fgui.UIPackage.createObject("chat", "toggleCom"));
	}

	protected onConstruct():void {
		this.m_c1 = this.getController("c1");
		this.m_toggleBtn = <fgui.GButton>(this.getChild("toggleBtn"));
		this.m_circle = <fgui.GImage>(this.getChild("circle"));
		this.m_circle2 = <fgui.GImage>(this.getChild("circle2"));
	}
}