import { E_ScreenMode } from "../../config/Enum";
import ConfigManager from "../../helper/ConfigManager";
import { Router } from "../../util/router";
import { UIHelper } from "../../helper/UIHelper";
import { UIBase } from "../scene/UIBase";
import { CustomerWindow } from "../windows/CustomerWindow";
import UI_langSetting from "../../fgui/lang/UI_langSetting";
import LocalUtil from "../../util/LocalUtil";
import I18nHelper from "../../helper/I18nHelper";
export class Lang extends UIBase {
    private or: E_ScreenMode
    private ui: UI_langSetting
    constructor(or: E_ScreenMode) {
        super(or == E_ScreenMode.horizontal ? UI_langSetting.createInstance() : UI_langSetting.createInstance());
        this.onUILoaded()
    }
    onUILoaded() {
        this.ui = this.UI_Item as UI_langSetting
        fgui.GRoot.inst.addChild(this.ui);
     
        this.ui.m_back.onClick(this, () => {
            Laya.Scene.open(Router.Me, true, null, Laya.Handler.create(this, () => {
            }))
        })
        //客服列表
        this.ui.m_help_btn.onClick(this, this.onCustomerClick)
        this.ui.m_country_btn.on(fairygui.Events.CLICK_ITEM, this, this.onChanged);

    }

    onChanged(item:fairygui.GButton){
        console.log("当前选中的值：",item.name);
        LocalUtil.setString(item.name,"lang")
        window.location.reload()
    }
    //客服列表
    customerWindow: CustomerWindow = null
    onCustomerClick() {
        if (!ConfigManager.getInstance().Customer) {
            UIHelper.showMsg(I18nHelper.t('noService'))
            return
        }
        if (ConfigManager.getInstance().Customer.length > 0) {
            if (!this.customerWindow) {
                this.customerWindow = new CustomerWindow(ConfigManager.getInstance().Customer)
            }
            UIHelper.showWindow(this.customerWindow)
        }
        else {
            UIHelper.showMsg(I18nHelper.t('noService'))
        }
    }
    destroy() {
        this.ui.dispose()
    }
}