/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_gameDes extends fgui.GComponent {

	public m_mask:fgui.GGraph;
	public m_content:fgui.GRichTextField;
	public static URL:string = "ui://61ic9kfk10j9vz9s";

	public static createInstance():UI_gameDes {
		return <UI_gameDes>(fgui.UIPackage.createObject("game", "gameDes"));
	}

	protected onConstruct():void {
		this.m_mask = <fgui.GGraph>(this.getChild("mask"));
		this.m_content = <fgui.GRichTextField>(this.getChild("content"));
	}
}