import { AudioManager } from "./AudioManager";
export default class AudioHelper {
    static BgmAudio = {
        1010: 'audio/login.mp3',
        1011: 'changan',
        1201: 'haorizi',
    };
    //按钮
    static playDefaultBtn() {
        AudioManager.playAudio("audio/dakai");
    }
    //打开声音
    static playOpenAudio() {
        AudioManager.playAudio("audio/dakai");
    }
    //关闭声音
    static playCloseAudio() {
        AudioManager.playAudio("audio/guanbi");
    }
    static playMusic(url: string) {
        Laya.SoundManager.playMusic(url, 0);
        // AudioManager.playMusic(url);
    }

    static stopMusic() {
        AudioManager.stopMusic();
    }

    static playAudio(url: string) {
        AudioManager.playAudio(url);
    }
    static stopPlay(audioUrl: string) {
        AudioManager.stopPlay(audioUrl);
    }
    //错误
    static playError() {
        Laya.SoundManager.playSound("resources/sound/tic.mp3", 1)
    }

    // 倒计时提示音
    static playTips() {
        Laya.SoundManager.playSound("resources/sound/tic.mp3", 1)
    }

    // 最后十秒
    static play10s() {
        let lang = "chinese"
        Laya.SoundManager.playSound(`resources/sound/${lang}/last10s.mp3`, 1)
    }

    // 加入房间
    static playJoin() {
        let lang = "chinese"
        Laya.SoundManager.playSound(`resources/sound/${lang}/join.mp3`, 1)
    }

    // 开始投注
    static playStart() {
        let lang = "chinese"
        Laya.SoundManager.playSound(`resources/sound/${lang}/start.mp3`, 1)
    }

    // 开始投注
    static playStop() {
        let lang = "chinese"
        Laya.SoundManager.playSound(`resources/sound/${lang}/stop.mp3`, 1)
    }

    // 播放谁赢
    static playWin(winName: string) {
        let lang = "chinese"
        Laya.SoundManager.playSound(`resources/sound/${lang}/${winName}.mp3`, 1)
    }

    // 投注成功
    static playBet() {
        let lang = "chinese"
        Laya.SoundManager.playSound(`resources/sound/betsuc.mp3`, 1)
    }

    // 播放点数
    static playPoint(soundName: string) {
        let lang = "chinese"
        Laya.SoundManager.playSound(`resources/sound/${lang}/${soundName}.mp3`, 1)
    }
}