/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_game_btn extends fgui.GButton {

	public m_t0:fgui.Transition;
	public static URL:string = "ui://q1kq7fv1pgc023";

	public static createInstance():UI_game_btn {
		return <UI_game_btn>(fgui.UIPackage.createObject("chat", "game_btn"));
	}

	protected onConstruct():void {
		this.m_t0 = this.getTransition("t0");
	}
}