/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_bet_btn2 from "./UI_bet_btn2";

export default class UI_TM10Area extends fgui.GComponent {

	public m_1:UI_bet_btn2;
	public m_2:UI_bet_btn2;
	public m_3:UI_bet_btn2;
	public m_4:UI_bet_btn2;
	public m_5:UI_bet_btn2;
	public m_6:UI_bet_btn2;
	public m_7:UI_bet_btn2;
	public m_8:UI_bet_btn2;
	public m_9:UI_bet_btn2;
	public m_10:UI_bet_btn2;
	public static URL:string = "ui://61ic9kfkbe8tvza0";

	public static createInstance():UI_TM10Area {
		return <UI_TM10Area>(fgui.UIPackage.createObject("game", "TM10Area"));
	}

	protected onConstruct():void {
		this.m_1 = <UI_bet_btn2>(this.getChild("1"));
		this.m_2 = <UI_bet_btn2>(this.getChild("2"));
		this.m_3 = <UI_bet_btn2>(this.getChild("3"));
		this.m_4 = <UI_bet_btn2>(this.getChild("4"));
		this.m_5 = <UI_bet_btn2>(this.getChild("5"));
		this.m_6 = <UI_bet_btn2>(this.getChild("6"));
		this.m_7 = <UI_bet_btn2>(this.getChild("7"));
		this.m_8 = <UI_bet_btn2>(this.getChild("8"));
		this.m_9 = <UI_bet_btn2>(this.getChild("9"));
		this.m_10 = <UI_bet_btn2>(this.getChild("10"));
	}
}