/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_RecordBall extends fgui.GComponent {

	public m_ball:fgui.GLoader;
	public static URL:string = "ui://d7esdcvtmeuszab";

	public static createInstance():UI_RecordBall {
		return <UI_RecordBall>(fgui.UIPackage.createObject("windows", "RecordBall"));
	}

	protected onConstruct():void {
		this.m_ball = <fgui.GLoader>(this.getChild("ball"));
	}
}