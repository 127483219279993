/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_NumRecordRow from "./UI_NumRecordRow";

export default class UI_NumThrend extends fgui.GComponent {

	public m_list:fgui.GList;
	public m_title:UI_NumRecordRow;
	public static URL:string = "ui://d7esdcvtmi8lz9n";

	public static createInstance():UI_NumThrend {
		return <UI_NumThrend>(fgui.UIPackage.createObject("windows", "NumThrend"));
	}

	protected onConstruct():void {
		this.m_list = <fgui.GList>(this.getChild("list"));
		this.m_title = <UI_NumRecordRow>(this.getChild("title"));
	}
}