/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_rank_btn extends fgui.GButton {

	public m_avtor:fgui.GComponent;
	public m_money:fgui.GTextField;
	public static URL:string = "ui://61ic9kfk10j9vz9q";

	public static createInstance():UI_rank_btn {
		return <UI_rank_btn>(fgui.UIPackage.createObject("game", "rank_btn"));
	}

	protected onConstruct():void {
		this.m_avtor = <fgui.GComponent>(this.getChild("avtor"));
		this.m_money = <fgui.GTextField>(this.getChild("money"));
	}
}