/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_avatar from "./UI_avatar";
import UI_ComboBox2_popup from "./UI_ComboBox2_popup";
import UI_message_tips from "./UI_message_tips";
import UI_Header from "./UI_Header";
import UI_Footer from "./UI_Footer";
import UI_ButtomIcon from "./UI_ButtomIcon";

export default class commonBinder {
	public static bindAll():void {
		fgui.UIObjectFactory.setExtension(UI_avatar.URL, UI_avatar);
		fgui.UIObjectFactory.setExtension(UI_ComboBox2_popup.URL, UI_ComboBox2_popup);
		fgui.UIObjectFactory.setExtension(UI_message_tips.URL, UI_message_tips);
		fgui.UIObjectFactory.setExtension(UI_Header.URL, UI_Header);
		fgui.UIObjectFactory.setExtension(UI_Footer.URL, UI_Footer);
		fgui.UIObjectFactory.setExtension(UI_ButtomIcon.URL, UI_ButtomIcon);
	}
}