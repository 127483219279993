/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_title from "./UI_title";
import UI_marquee from "./UI_marquee";
import UI_Personal from "./UI_Personal";

export default class UI_homeContent extends fgui.GComponent {

	public m_title:UI_title;
	public m_tablist:fgui.GList;
	public m_gameList:fgui.GList;
	public m_swiper:fgui.GList;
	public m_notice:UI_marquee;
	public m_personal:UI_Personal;
	public static URL:string = "ui://bi3ljjpymi8lz83";

	public static createInstance():UI_homeContent {
		return <UI_homeContent>(fgui.UIPackage.createObject("home", "homeContent"));
	}

	protected onConstruct():void {
		this.m_title = <UI_title>(this.getChild("title"));
		this.m_tablist = <fgui.GList>(this.getChild("tablist"));
		this.m_gameList = <fgui.GList>(this.getChild("gameList"));
		this.m_swiper = <fgui.GList>(this.getChild("swiper"));
		this.m_notice = <UI_marquee>(this.getChild("notice"));
		this.m_personal = <UI_Personal>(this.getChild("personal"));
	}
}