/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_wallet_record extends fgui.GComponent {

	public m_c1:fgui.Controller;
	public m_back:fgui.GButton;
	public m_title:fgui.GTextField;
	public m_search:fgui.GButton;
	public m_start_time:fgui.GTextInput;
	public m_end_time:fgui.GTextInput;
	public m_btn_list:fgui.GList;
	public m_top:fgui.GGroup;
	public m_list:fgui.GList;
	public m_m1:fgui.GTextField;
	public m_m2:fgui.GTextField;
	public m_m3:fgui.GTextField;
	public m_help_btn:fgui.GButton;
	public static URL:string = "ui://o6l13e8abvur9";

	public static createInstance():UI_wallet_record {
		return <UI_wallet_record>(fgui.UIPackage.createObject("wallet_record", "wallet_record"));
	}

	protected onConstruct():void {
		this.m_c1 = this.getController("c1");
		this.m_back = <fgui.GButton>(this.getChild("back"));
		this.m_title = <fgui.GTextField>(this.getChild("title"));
		this.m_search = <fgui.GButton>(this.getChild("search"));
		this.m_start_time = <fgui.GTextInput>(this.getChild("start_time"));
		this.m_end_time = <fgui.GTextInput>(this.getChild("end_time"));
		this.m_btn_list = <fgui.GList>(this.getChild("btn_list"));
		this.m_top = <fgui.GGroup>(this.getChild("top"));
		this.m_list = <fgui.GList>(this.getChild("list"));
		this.m_m1 = <fgui.GTextField>(this.getChild("m1"));
		this.m_m2 = <fgui.GTextField>(this.getChild("m2"));
		this.m_m3 = <fgui.GTextField>(this.getChild("m3"));
		this.m_help_btn = <fgui.GButton>(this.getChild("help_btn"));
	}
}