export const GameEvent = {
    /**
     * 桌面彩池发生变化
     */
    OnTablePoolChanged: "OnTablePoolChanged",
    /**
     * 开奖事件
     */
    OnResult: "OnResult",
    /**
     * 停止下注事件
     */
    OnStopBetting: "OnStopBetting",
    /**
     * 开始下注
     */
    OnStartBetting: "OnStartBetting",
    /**
     * 开局
     */
    OnNewRound: "OnNewRound",
    /**
     * 用户余额发生改变
     */
    OnBalanceChanged: "OnBalanceChanged",
    /**
     * 通知发生改变
     */
    OnNoticeChange: "OnNoticeChange",
    /**
     * 系统维护
     */
    OnSystemMaintenance: "OnSystemMaintenance",
    /**
     * 与服务器断开连接
     */
    OnDisconnect: "disconnect",
    /**
     * 与服务器连接成功
     */
    Onconnect: "connect",
    /**
     * 与服务器重新连接成功
     */
    Onreconnect: "reconnect",
    /**
    * 服务器连接错误
    */
    OnError: "error",
    /**
    * 向服务器发送我的位置
    */
    OnJoin: "join",
    /**
     * 桌台数据发生变化
     */
    OnTableModelHasChange: "OnTableModelHasChange",
    /**
     * 计时器
     */
    OnTimeTicker: "OnTimeTicker",
    /**
     * 清注
     */
    OnClearBeting: "OnClearBeting",
    /**
    * 踢下线
    */
    OnKick: "OnKick",
    /**
     * 牌点显示
     */
    OnCardReached: "OnCardReached",
    /**
     * 退出游戏
     */
    LogoutGame: "LogoutGame",
    /**
     * 用户临时下注通知
     */
    UserTempBet: "UserTempBet",
    /**
     * 取消投注
     */
    CancelBetting: "CancelBetting",
    /**
     * 全路显示
     */
    ShowAllRoad: "ShowAllRoad",
    /**
    * 大路显示
    */
    ShowBigRoad: "ShowBigRoad",
    /**
     * 好路置顶
     */
    GoodTop: "GoodTop",
    /**
     * 切换横竖屏
     */
    ChangeOr: "ChangeOr",

    /**
     * 确认发送
     */
    SendMsg:"SendMsg",
}