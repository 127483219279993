/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_btn extends fgui.GButton {

	public m_c1:fgui.Controller;
	public static URL:string = "ui://00tbrg0lklaa82";

	public static createInstance():UI_btn {
		return <UI_btn>(fgui.UIPackage.createObject("login", "btn"));
	}

	protected onConstruct():void {
		this.m_c1 = this.getController("c1");
	}
}