/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_title extends fgui.GComponent {

	public m_logo:fgui.GLoader;
	public m_settingBtn:fgui.GButton;
	public m_signBtn:fgui.GButton;
	public m_menuBtn:fgui.GButton;
	public m_Text:fgui.GTextField;
	public static URL:string = "ui://bi3ljjpyc03pz8e";

	public static createInstance():UI_title {
		return <UI_title>(fgui.UIPackage.createObject("home", "title"));
	}

	protected onConstruct():void {
		this.m_logo = <fgui.GLoader>(this.getChild("logo"));
		this.m_settingBtn = <fgui.GButton>(this.getChild("settingBtn"));
		this.m_signBtn = <fgui.GButton>(this.getChild("signBtn"));
		this.m_menuBtn = <fgui.GButton>(this.getChild("menuBtn"));
		this.m_Text = <fgui.GTextField>(this.getChild("Text"));
	}
}