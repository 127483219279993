import { E_ScreenMode } from "../../config/Enum";
import ConfigManager from "../../helper/ConfigManager"
import  { http } from "../../net/Http"
import { Router } from "../../util/router"
import { IDictType, ISelectWater } from "../../config/interface"
import { UIHelper } from "../../helper/UIHelper";
import { LayaHelper } from "../../helper/LayaHelper";
import { UIBase } from "../scene/UIBase";
import { CustomerWindow } from "../windows/CustomerWindow";
import UI_balance_record from "../../fgui/balance_record/UI_balance_record";
import UI_balance_row from "../../fgui/balance_record/UI_balance_row";
import I18nHelper from "../../helper/I18nHelper";
export class BalanceRecord extends UIBase {
    private reloadNums: number = 0
    private or: E_ScreenMode
    private uibalance: UI_balance_record
    data: ISelectWater[] = []
    usedData: ISelectWater[] = []
    private start_time: fgui.GTextInput
    private end_time: fgui.GTextInput

    xhrList: XMLHttpRequest[] = []
    private timeData: {
        beginTime: string,
        endTime: string
    }
    //页码
    private _pageNum: number = 1;
    private _total: number = 0;
    //类型
    private dictTypeList: IDictType[]
    private selectLogType: IDictType = { name: '', value: '', pairCode: '' }
    //刷新头部
    private _header: fairygui.GComponent;
    //刷新头部控制器
    private _header_c1: fgui.Controller;
    constructor(or: E_ScreenMode) {
        super(or == E_ScreenMode.horizontal ? UI_balance_record.createInstance() : UI_balance_record.createInstance());
        this.onUILoaded()
    }
    onUILoaded() {
        this.uibalance = this.UI_Item as UI_balance_record
        fgui.GRoot.inst.addChild(this.uibalance)
        //刷新控制器
        this._header = this.uibalance.m_list.scrollPane.header
        this._header_c1 = this._header.getController("c1");
        this._header.on(fgui.Events.SIZE_CHANGED, this, this.onSizeChanged);

        this.uibalance.m_list.on(fgui.Events.PULL_DOWN_RELEASE, this, this.onPullDownToRefresh);
        this.uibalance.m_list.on(fgui.Events.PULL_UP_RELEASE, this, this.onPullUpToRefresh);
        //刷新控制器

        this.start_time = this.uibalance.m_start_time
        this.end_time = this.uibalance.m_end_time
        this.initTime()

        this.getList();
        this.uibalance.m_back.onClick(this, this.back)
        this.uibalance.m_list.itemRenderer = Laya.Handler.create(this, this.renderListItem, null, false)
        //搜索
        this.uibalance.m_search.onClick(this, () => {
            this.timeData = {
                "beginTime": this.start_time.text,
                "endTime": this.end_time.text,
            }
            this._pageNum = 1
            this.getList();
            this.uibalance.m_btn_list.selectNone()
        })
        this.uibalance.m_btn_list.getChild('today').onClick(this, () => {
            console.log('今天', LayaHelper.getDays())
            this.timeData = {
                "beginTime": LayaHelper.getDays().startTime,
                "endTime": LayaHelper.getDays().endTime
            }
            this._pageNum = 1
            this.start_time.text = this.timeData.beginTime
            this.end_time.text = this.timeData.endTime
            this.getList();

        })
        this.uibalance.m_btn_list.getChild('yesyerday').onClick(this, () => {
            console.log('昨天', LayaHelper.getLastDays())
            this.timeData = {
                "beginTime": LayaHelper.getLastDays().startTime,
                "endTime": LayaHelper.getLastDays().endTime
            }
            this._pageNum = 1
            this.start_time.text = this.timeData.beginTime
            this.end_time.text = this.timeData.endTime
            this.getList();
        })
        this.uibalance.m_btn_list.getChild('seven_day').onClick(this, () => {
            console.log('近7天', LayaHelper.getCurrWeekDays())
            this.timeData = {
                "beginTime": LayaHelper.getCurrWeekDays().startTime,
                "endTime": LayaHelper.getCurrWeekDays().endTime
            }
            this._pageNum = 1
            this.start_time.text = this.timeData.beginTime
            this.end_time.text = this.timeData.endTime
            this.getList();
        })
        this.uibalance.m_btn_list.getChild('month_day').onClick(this, () => {
            console.log('近1个月', LayaHelper.getCurrMonthDays())
            this.timeData = {
                "beginTime": LayaHelper.getCurrMonthDays().startTime,
                "endTime": LayaHelper.getCurrMonthDays().endTime
            }
            this._pageNum = 1
            this.start_time.text = this.timeData.beginTime
            this.end_time.text = this.timeData.endTime
            this.getList();
        })
        this.getBalanceType()
        //客服列表
        this.uibalance.m_help_btn.onClick(this, this.onCustomerClick)
    }
    //客服列表
    customerWindow: CustomerWindow = null
    onCustomerClick() {
        if (!ConfigManager.getInstance().Customer) {
            UIHelper.showMsg(I18nHelper.t('noService'))
            return
        }
        if (ConfigManager.getInstance().Customer.length > 0) {
            if (!this.customerWindow) {
                this.customerWindow = new CustomerWindow(ConfigManager.getInstance().Customer)
            }
            UIHelper.showWindow(this.customerWindow)
        }
        else {
            UIHelper.showMsg(I18nHelper.t('noService'))
        }
    }

    // loType = ["上分", "下分", "下注", "赢分", "输分", "反水", "清注", "撤回下注"]
    renderListItem(index: number, obj: UI_balance_row) {
        obj.m_logType.text = I18nHelper.t(this.usedData[index]?.typeCode)
        obj.m_changeBalance.text = this.usedData[index].changeBalance.toString()
        obj.m_newBalance.text = this.usedData[index].newBalance.toString()
        obj.m_oldBalance.text = this.usedData[index].oldBalance.toString()
        obj.m_remark.text =  I18nHelper.t(this.usedData[index].pairCode)
        obj.m_time.text = this.usedData[index].createTime
    }

    renderTypeListItem(index: number, obj: fairygui.GObject) {
        let item = obj as fgui.GButton
        if (index == 0) {
            item.text = I18nHelper.t('all')
            item.onClick(this, () => {
                this.selectLogType = { name: '', value: '', pairCode: '' }
                this.selectDataForLogType()
            })
            item.selected = true
        } else {
            item.text = I18nHelper.t(this.dictTypeList[index - 1].pairCode)
            console.log(this.dictTypeList[index - 1].pairCode);
            item.onClick(this, () => {
                this.selectLogType = this.dictTypeList[index - 1]
                this.selectDataForLogType()
            })
        }


    }

    selectDataForLogType(){
        if (this.selectLogType.value == '') {
            return
        }

        

        this.usedData = this.data.filter((item: ISelectWater) => {
            
            return item.logType == this.selectLogType.value
        })

        
        
        this.uibalance.m_list.numItems = this.usedData.length

    }


    protected initTime() {
        let time = new Date();
        let timeText = time.toISOString().slice(0, 10);
        this.start_time.text = timeText
        this.end_time.text = timeText
        this.start_time._displayObject.type = Laya.Input.TYPE_DATE
        // this.start_time._displayObject.bgColor = "#525152"
        this.start_time._displayObject.align = "center"
        this.end_time._displayObject.type = Laya.Input.TYPE_DATE
        // this.end_time._displayObject.bgColor = "#525152"
        this.end_time._displayObject.align = "center"
        this.timeData = {
            "beginTime": LayaHelper.getDays().startTime,
            "endTime": LayaHelper.getDays().endTime
        }
    }

    //获取列表
    getList() {
        this.start_time.text =  this.timeData.beginTime
        this.end_time.text = this.timeData.endTime
        let params:any = {
            date:  this.timeData.beginTime,
        }

        if (this.selectLogType.value) {
            params.logType = this.selectLogType.value
        }
        console.log(params)

        let xhr = http.selectBalanceLog(params, (res: any) => {
            if (res.code == 200) {
                this._total = res.total
                console.log(res)
              
                this.data = res.data
                this.usedData = this.data
                this.uibalance.m_list.numItems = this.data.length
            } else {
                UIHelper.showMsg(I18nHelper.t(res.msg))
            }

        });
        this.xhrList.push(xhr)
    }

    //获取变动类型
    getBalanceType() {
        let xhr = http.getSelectLogType(null, (res: any) => {
            if (res.code == 200) {
                console.log(res)
                this.dictTypeList = res.data
                this.uibalance.m_type_list.itemRenderer = Laya.Handler.create(this, this.renderTypeListItem, null, false)
                this.uibalance.m_type_list.numItems = this.dictTypeList.length + 1
            } else {
                this.uibalance.m_list.y = this.uibalance.m_list.y - 80
                this.uibalance.m_title.y = this.uibalance.m_title.y - 80
            }
        });
        this.xhrList.push(xhr)

    }

    //下拉加载
    private async onPullDownToRefresh(evt: Laya.Event): Promise<void> {
        console.log('准备');
        // console.log(header);

        if (this._header_c1.selectedIndex == 1) {
            this._header_c1.selectedIndex = 2;
            this.uibalance.m_list.scrollPane.lockHeader(this._header.sourceHeight);
            this._pageNum = 1
             this.getList();
            this._header_c1.selectedIndex = 0;
            this.uibalance.m_list.scrollPane.lockHeader(0);
        }

    }

    //监听头部刷新控制器
    onSizeChanged(): void {
        if (this._header_c1.selectedIndex == 2)
            return;
        if (this._header.height > this._header.sourceHeight)
            this._header_c1.selectedIndex = 1;
        else
            this._header_c1.selectedIndex = 0;
    }

    //上拉加载
    private async onPullUpToRefresh(evt: Laya.Event): Promise<void> {
        var footer: fgui.GComponent = this.uibalance.m_list.scrollPane.footer.asCom;

        footer.getController("c1").selectedIndex = 1;
        this.uibalance.m_list.scrollPane.lockFooter(footer.sourceHeight);

        //Simulate a async resquest       
        if (this.data.length < this._total) {
            this._pageNum++
            //Simulate a async resquest
             this.getList();
            footer.getController("c1").selectedIndex = 0;
            this.uibalance.m_list.scrollPane.lockFooter(0);
        } else {
            this.uibalance.m_list.scrollPane.lockFooter(0);
            return;
        }
    }




    back() {
        Laya.Scene.open(Router.Me, true, null, Laya.Handler.create(this, () => {
        }))
    }


    destroy() {
        this.xhrList.forEach((xhr) => {
            console.log("取消", xhr);

            xhr.abort()
        })
        this.uibalance.dispose()
    }
}