import { IActive } from "../config/interface";

export default class ActiveDataManager {
    //对象
    private static instance: ActiveDataManager;
 

    private xhrList:XMLHttpRequest[] = []
    
    private activeData:IActive[] = [];

    //单利模式
    public static getInstance(): ActiveDataManager {
        if (this.instance == null) {
            this.instance = new ActiveDataManager();
        }
        return this.instance;
    }
    private isActiveDataUpDate = true;
    setActiveData(data:IActive[]){
        if(this.isActiveDataUpDate){
            this.activeData = [];
            data.forEach((e)=>{
                this.activeData.push(e);
            })
            this.isActiveDataUpDate = false;
        }
    }
    getActiveData():IActive[]{
        return this.activeData
    }
    setIsActiveDataUpDate(bool:boolean){
        this.isActiveDataUpDate = bool;
    }
    getIsActiveDataUpDate():boolean{
        return this.isActiveDataUpDate;
    }
    //构造函数
    constructor() {
    }
    
}