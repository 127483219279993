/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_wallet_row extends fgui.GComponent {

	public m_m1:fgui.GTextField;
	public m_m2:fgui.GTextField;
	public m_m3:fgui.GTextField;
	public static URL:string = "ui://o6l13e8abvur7";

	public static createInstance():UI_wallet_row {
		return <UI_wallet_row>(fgui.UIPackage.createObject("wallet_record", "wallet_row"));
	}

	protected onConstruct():void {
		this.m_m1 = <fgui.GTextField>(this.getChild("m1"));
		this.m_m2 = <fgui.GTextField>(this.getChild("m2"));
		this.m_m3 = <fgui.GTextField>(this.getChild("m3"));
	}
}