/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_winFrame from "./UI_winFrame";

export default class UI_ChipsWindow extends fgui.GComponent {

	public m_mask:fgui.GGraph;
	public m_frame:UI_winFrame;
	public m_chips_btn_list:fgui.GList;
	public m_sure_btn:fgui.GButton;
	public m_cancel_btn:fgui.GButton;
	public static URL:string = "ui://d7esdcvtmzb7z8n";

	public static createInstance():UI_ChipsWindow {
		return <UI_ChipsWindow>(fgui.UIPackage.createObject("windows", "ChipsWindow"));
	}

	protected onConstruct():void {
		this.m_mask = <fgui.GGraph>(this.getChild("mask"));
		this.m_frame = <UI_winFrame>(this.getChild("frame"));
		this.m_chips_btn_list = <fgui.GList>(this.getChild("chips_btn_list"));
		this.m_sure_btn = <fgui.GButton>(this.getChild("sure_btn"));
		this.m_cancel_btn = <fgui.GButton>(this.getChild("cancel_btn"));
	}
}