/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_row extends fgui.GComponent {

	public m_waterBtn:fgui.GButton;
	public m_waterTimer:fgui.GTextField;
	public m_totalBet:fgui.GTextField;
	public m_per:fgui.GTextField;
	public m_waterAmount:fgui.GTextField;
	public m_yes_t:fgui.GTextField;
	public static URL:string = "ui://zx95u0njg1nbg";

	public static createInstance():UI_row {
		return <UI_row>(fgui.UIPackage.createObject("backwater", "row"));
	}

	protected onConstruct():void {
		this.m_waterBtn = <fgui.GButton>(this.getChild("waterBtn"));
		this.m_waterTimer = <fgui.GTextField>(this.getChild("waterTimer"));
		this.m_totalBet = <fgui.GTextField>(this.getChild("totalBet"));
		this.m_per = <fgui.GTextField>(this.getChild("per"));
		this.m_waterAmount = <fgui.GTextField>(this.getChild("waterAmount"));
		this.m_yes_t = <fgui.GTextField>(this.getChild("yes_t"));
	}
}