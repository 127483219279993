import { E_ScreenMode } from "../../config/Enum";
import UI_backwater from "../../fgui/backwater/UI_backwater";
import ConfigManager from "../../helper/ConfigManager"
import  { http } from "../../net/Http"
import { Router } from "../../util/router"
import UI_row from "../../fgui/backwater/UI_row";
import { IDictType, IWater } from "../../config/interface"
import { UIHelper } from "../../helper/UIHelper";
import { LayaHelper } from "../../helper/LayaHelper";
import { UIBase } from "../scene/UIBase";
import { CustomerWindow } from "../windows/CustomerWindow";
import I18nHelper from "../../helper/I18nHelper";
export class Backwater extends UIBase {
    private reloadNums: number = 0
    private or: E_ScreenMode
    private uibackwater: UI_backwater
    data: IWater[] = []
    private start_time: fgui.GTextInput
    private end_time: fgui.GTextInput
    private timeData: {
        beginTime: string,
        endTime: string
    }
    //页码
    private _pageNum: number = 1;
    private _total: number = 0;
 
    //刷新头部
    private _header: fairygui.GComponent;
    //刷新头部控制器
    private _header_c1: fgui.Controller;


    xhrList: XMLHttpRequest[] = []
    constructor(or: E_ScreenMode) {
        super(or == E_ScreenMode.horizontal ? UI_backwater.createInstance() : UI_backwater.createInstance());
        this.onUILoaded()
    }
    onUILoaded() {
        this.uibackwater = this.UI_Item as UI_backwater
        fgui.GRoot.inst.addChild(this.uibackwater)
        //刷新控制器
        this._header = this.uibackwater.m_list.scrollPane.header
        this._header_c1 = this._header.getController("c1");
        this._header.on(fgui.Events.SIZE_CHANGED, this, this.onSizeChanged);

        this.uibackwater.m_list.on(fgui.Events.PULL_DOWN_RELEASE, this, this.onPullDownToRefresh);
        this.uibackwater.m_list.on(fgui.Events.PULL_UP_RELEASE, this, this.onPullUpToRefresh);
        //刷新控制器

        this.start_time = this.uibackwater.m_start_time
        this.end_time = this.uibackwater.m_end_time
        this.initTime()

        this.uibackwater.m_back.onClick(this, this.back)
        this.uibackwater.m_list.itemRenderer = Laya.Handler.create(this, this.renderListItem, null, false)
        //搜索
        this.uibackwater.m_search.onClick(this, () => {
            this.timeData = {
                "beginTime": this.start_time.text,
                "endTime": this.end_time.text,
            }
            this._pageNum = 1
            this.getList();
            this.uibackwater.m_btn_list.selectNone()
        })
      

        //客服列表
        this.uibackwater.m_help_btn.onClick(this, this.onCustomerClick)
    }
    //客服列表
    customerWindow: CustomerWindow = null
    onCustomerClick() {
        if (!ConfigManager.getInstance().Customer) {
            UIHelper.showMsg(I18nHelper.t('noService'))
            return
        }
        if (ConfigManager.getInstance().Customer.length > 0) {
            if (!this.customerWindow) {
                this.customerWindow = new CustomerWindow(ConfigManager.getInstance().Customer)
            }
            UIHelper.showWindow(this.customerWindow)
        }
        else {
            UIHelper.showMsg(I18nHelper.t('noService'))
        }
    }
    renderListItem(index: number, obj: UI_row) {
        obj.m_per.text = this.data[index].per.toString()
        obj.m_totalBet.text = this.data[index].totalBet.toString()
        obj.m_waterAmount.text = this.data[index].waterAmount.toString()
        obj.m_waterTimer.text = this.data[index].statisticsDate.substring(0, 10)

        if (this.data[index].waterType != 1) {
            obj.m_waterBtn.visible = true

            obj.m_waterBtn.onClick(this, () => {
                let xhr = http.applyRefund(this.data[index].id, (res: any) => {
                    if (res.code == 200) {
                        this.getList()
                    }
                    UIHelper.showMsg(I18nHelper.t(res.msg))
                })
                this.xhrList.push(xhr)
            })

            obj.m_yes_t.visible = false
        } else {
            obj.m_waterBtn.visible = false
            obj.m_yes_t.visible = true
        }
    }




    protected initTime() {
        let time = new Date();
        let timeText = time.toISOString().slice(0, 10);
        this.start_time.text = timeText
        this.end_time.text = timeText
        this.start_time._displayObject.type = Laya.Input.TYPE_DATE
        // this.start_time._displayObject.bgColor = "#525152"
        this.start_time._displayObject.align = "center"
        this.end_time._displayObject.type = Laya.Input.TYPE_DATE
        // this.end_time._displayObject.bgColor = "#525152"
        this.end_time._displayObject.align = "center"
        this.timeData = {
            "beginTime": LayaHelper.getDays().startTime,
            "endTime": LayaHelper.getDays().endTime
        }
        this.getList()
    }

    //获取列表
    getList() {
        this.start_time.text = this.timeData.beginTime
        this.end_time.text = this.timeData.beginTime
        var params = {
            date: this.timeData.beginTime
        }

        return new Promise((resolve, reject) => {
            let xhr = http.getBackWater(params, (res: any) => {
                if (res.code == 200) {
                    this._total = res.total
                    console.log(res)
                   
                    this.data = res.data
                    this.uibackwater.m_list.numItems = this.data.length


                    resolve(true)
                } else {
                    UIHelper.showMsg(I18nHelper.t(res.msg))
                    reject(false)
                }

            });
            this.xhrList.push(xhr)
        });
    }

   

    //下拉加载
    private async onPullDownToRefresh(evt: Laya.Event): Promise<void> {
        console.log('准备');
        // console.log(header);

        if (this._header_c1.selectedIndex == 1) {
            this._header_c1.selectedIndex = 2;
            this.uibackwater.m_list.scrollPane.lockHeader(this._header.sourceHeight);
            this._pageNum = 1
            await this.getList();
            this._header_c1.selectedIndex = 0;
            this.uibackwater.m_list.scrollPane.lockHeader(0);
        }

    }

    //监听头部刷新控制器
    onSizeChanged(): void {
        if (this._header_c1.selectedIndex == 2)
            return;
        if (this._header.height > this._header.sourceHeight)
            this._header_c1.selectedIndex = 1;
        else
            this._header_c1.selectedIndex = 0;
    }

    //上拉加载
    private async onPullUpToRefresh(evt: Laya.Event): Promise<void> {
        var footer: fgui.GComponent = this.uibackwater.m_list.scrollPane.footer.asCom;

        footer.getController("c1").selectedIndex = 1;
        this.uibackwater.m_list.scrollPane.lockFooter(footer.sourceHeight);

        //Simulate a async resquest       
        if (this.data.length < this._total) {
            this._pageNum++
            //Simulate a async resquest
            await this.getList();
            footer.getController("c1").selectedIndex = 0;
            this.uibackwater.m_list.scrollPane.lockFooter(0);
        } else {
            this.uibackwater.m_list.scrollPane.lockFooter(0);
            return;
        }
    }




    back() {
        Laya.Scene.open(Router.Me, true, null, Laya.Handler.create(this, () => {
        }))
    }


    destroy() {
        this.xhrList.forEach((xhr) => {
            console.log("取消", xhr);

            xhr.abort()
        })
        this.uibackwater.dispose()
    }
}