/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_wallet_row from "./UI_wallet_row";
import UI_Footer from "./UI_Footer";
import UI_wallet_record from "./UI_wallet_record";

export default class wallet_recordBinder {
	public static bindAll():void {
		fgui.UIObjectFactory.setExtension(UI_wallet_row.URL, UI_wallet_row);
		fgui.UIObjectFactory.setExtension(UI_Footer.URL, UI_Footer);
		fgui.UIObjectFactory.setExtension(UI_wallet_record.URL, UI_wallet_record);
	}
}