/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_FourNum extends fgui.GComponent {

	public m_r1:fgui.GButton;
	public m_r2:fgui.GButton;
	public m_r3:fgui.GButton;
	public m_r4:fgui.GButton;
	public static URL:string = "ui://61ic9kfkbe8tvza1";

	public static createInstance():UI_FourNum {
		return <UI_FourNum>(fgui.UIPackage.createObject("game", "FourNum"));
	}

	protected onConstruct():void {
		this.m_r1 = <fgui.GButton>(this.getChild("r1"));
		this.m_r2 = <fgui.GButton>(this.getChild("r2"));
		this.m_r3 = <fgui.GButton>(this.getChild("r3"));
		this.m_r4 = <fgui.GButton>(this.getChild("r4"));
	}
}