import { cnf_event } from "../../config/Event_cnf";
import { GameEvent } from "../../config/GameEvent";
import { IPConifg } from "../../config/NetConfig";
import { IChatMes, IGameRoom } from "../../config/interface";
import UI_Chat from "../../fgui/chat/UI_chat";
import UI_chatLeft from "../../fgui/chat/UI_chatLeft";
import UI_avatar from "../../fgui/common/UI_avatar";
import I18nHelper from "../../helper/I18nHelper";
import { LayaHelper } from "../../helper/LayaHelper";
import { notice } from "../../helper/NoticeHelper";
import PlayerDataManager from "../../model/PlayerDataManager";
import { http } from "../../net/Http";
import NetManager from "../../net/NetManager";
import LocalUtil from "../../util/LocalUtil";


export class Chat {

    uiChat: UI_Chat
    messages: IChatMes[] = []
    roomData: IGameRoom
    chatArea: fgui.GComponent
    xhrList: XMLHttpRequest[] = [];
    constructor(ui: UI_Chat, data: IGameRoom, chatArea: fgui.GComponent) {
        this.uiChat = ui
        this.roomData = data
        this.chatArea = chatArea
        this.onUILoaded()
    }

    testTimer: number = null
    onUILoaded() {
        this.uiChat.m_list.setVirtual()
        this.uiChat.m_list.itemProvider = Laya.Handler.create(this, this.getListItemResource, null, false);
        this.uiChat.m_list.itemRenderer = Laya.Handler.create(this, this.renderListItem, null, false);

        this.uiChat.m_btnSend1.onClick(this, this.onSubmit)
        // this.uiChat.m_input1._displayObject.color = "#490f24"
        // this.uiChat.m_input1.color = "#490f24"
        // this.uiChat.m_input1.displayObject.bgColor = "#490f24"

        let self = this

        this.uiChat.m_toggleCom.selected = LocalUtil.boolForKey("chatToggle", false)
         
        document.body.addEventListener('keydown', function(event) {
            if (event.key === 'Enter') { // 检查按下的是否是回车键
                // 调用你想要执行的函数
                self.onSubmit()
                event.preventDefault(); // 阻止回车键的默认行为（例如提交表单）
            }
        });
        

        this.uiChat.m_input1.on(Laya.Event.FOCUS,this,()=>{
            console.log("focus")
            document.getElementsByTagName("input")[0].enterKeyHint = "send"
        })
      

        notice.on(cnf_event.roomSendMsgEvent + this.roomData.id, this.onChatMes)
        notice.on(cnf_event.roomBarrageEvent + this.roomData.id, this.onChatBarrage)
        notice.on(GameEvent.SendMsg , this.onSubmit)
        let xhr =  http.getUserMessage(this.roomData.id, (data: any) => {
            if (data.code == 200) {
                this.messages = data.data
                this.uiChat.m_list.numItems = this.messages.length
                this.uiChat.m_list.scrollPane.scrollBottom()
            }
        })
        this.xhrList.push(xhr)

        let testArr = [
            "我们都是戏子，在别人的故事里，流着自己的眼泪。",
            "优于别人，并不高贵，真正的高贵应该是优于过去的自己。",
            "流下绝望的眼泪，洗不掉注定的结",
            "一个笑就击败了一辈子，一滴泪就还清了一个人。一人花开，一人花落，这些年从头到尾，无人问询。",
            "优等的心，不必华丽，但必须坚固。",
            "人生如路。须在荒凉中走出繁华的风景来。",
            "等待延续，与幸福那么近，与你那么远",
            "我顿足，我懊恼，我哭泣，恨不得把自己撕成碎片",
            "人生就像迷宫，我们用上半生找寻入口，用下半生找寻出口。",
            "我从没被谁知道，所以也没被谁忘记。在别人的回忆中生活，并不是我的目的。",
            "哪里会有人喜欢孤独，不过是不喜欢失望。",
            "如果真相是种伤害，请选择谎言。如果谎言是一种伤害，请选择沉默。如果沉默是一种伤害，请选择离开。",
            "记忆，就好像是神话里的筛子筛去了垃圾，保留了金沙。",
            "水拥抱着风，风却迷恋着天空;风孕育着云，云却化作水。唯美之音，轻轻地来，淡淡地飘走。",
            "最美的不是花灯供守，而是风雨同舟。最爱的不是情话满口，而是偕老白头。",
            "不在乎天长地久，只在乎曾经拥有。",
            "最美的不是下雨天，是曾与你躲过雨的屋檐。",
            "向后看，才懂得生活，向前看，才能生活。",

            "碎了一地的诺言，拼凑不回的昨天。",
            "隔着眼泪看世界，整个世界都在哭。"
        ]


        // this.testTimer = setInterval(() => {
        //     let index = LayaHelper.getRandom(0, testArr.length - 1)
        //     let index2 = LayaHelper.getRandom(0, testArr.length - 1)
        //     NetManager.getInstance().sendRoomMsg(testArr[index], this.roomData.id)
        //     NetManager.getInstance().sendBarrageMsg(testArr[index2], this.roomData.id)
        // }, 5000)
    }

    onChatBarrage = (data: IChatMes) => {
        this.createDM(data)
    }

    private getListItemResource(index: number): string {

        let message = this.messages[index]

        if (message.senderId == PlayerDataManager.getInstance().getPlayerInfo().id)
            return "ui://q1kq7fv1reg61c";
        else
            return "ui://q1kq7fv1reg61d";
    }

    private renderListItem(index: number, item: UI_chatLeft): void {

        item.m_msg.displayObject.maxWidth = item.m_msg.maxWidth;
        item.m_msg.minWidth = 280

        item.m_msg.text = this.messages[index].obj;
        item.m_msg.ensureSizeCorrect();
        item.m_sendName.text = this.messages[index].senderName ? this.messages[index].senderName : I18nHelper.t('numName')
        item.m_time.text = this.messages[index]?.date ?  this.messages[index]?.date.substring(11) : "";
        if (this.messages[index].userHead) {
            (item.m_sendIcon as UI_avatar).m_avatar.url =  this.messages[index].userHead
        }else{
            // item.m_sendIcon1.alpha = 1
        }
        (item.m_sendIcon as UI_avatar).m_avatar.url =  this.messages[index].userHead

        // let msg = "xxxx";
        // if (!msg.fromMe)
        // item.icon = fgui.UIPackage.getItemURL("Chat", msg.senderIcon);
    }


    onChatMes = (data: IChatMes) => {
        console.log("收到的聊天消息", data);
        this.messages.push(data)

        // if (this.messages.length > 100){
        //     this.messages.splice(0, this.messages.length - 100);
        // }

        this.uiChat.m_list.numItems = this.messages.length
        this.uiChat.m_list.scrollPane.scrollBottom()
    }

    tweenList: Laya.Tween[] = []
    createDM(data: IChatMes) {
        let text = new fairygui.GTextField()
        text.text = data.obj
        text.fontSize = LayaHelper.getRandom(26, 50)
        text.color = '#' + (Math.random() * 0xffffff << 0).toString(16);
        let x = this.chatArea.width
        let y = LayaHelper.getRandom(50, 500)
        text.setXY(x, y)
        this.chatArea.addChild(text)

        console.log(this.chatArea.height);
        console.log(y);


        let tween = Laya.Tween.to(text, { x: -text.width }, 5000, null, Laya.Handler.create(this, () => {
            text && text.dispose()
        }))
        this.tweenList.push(tween)
    }


     onSubmit =()=> {
        if (this.uiChat.m_input1.text.trim() == "") {
            return
        }
        if (this.uiChat.m_toggleCom.selected) {

            NetManager.getInstance().sendBarrageMsg(this.uiChat.m_input1.text, this.roomData.id)
        } else {
            NetManager.getInstance().sendRoomMsg(this.uiChat.m_input1.text, this.roomData.id)
        }

        LocalUtil.setBool(this.uiChat.m_toggleCom.selected,"chatToggle")
        this.uiChat.m_input1.text = ""
        this.uiChat.m_input1.requestFocus()
    }

    destroy() {
        clearInterval(this.testTimer)
        this.tweenList.forEach((e) => {
            e.clear()
        })

        this.xhrList.forEach((xhr)=>{
            xhr.abort()
        })
        notice.off(cnf_event.roomBarrageEvent + this.roomData.id, this.onChatBarrage)
        notice.off(cnf_event.roomSendMsgEvent + this.roomData.id, this.onChatMes)
        notice.off(GameEvent.SendMsg , this.onSubmit)

    }
}
