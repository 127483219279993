export let SOCKET_EVENTS = {
    CONNECT: 'connect',
    DISCONNECT: 'disconnect',
    CONNECT_ERROR: 'connect_error',
    HEARTBEAT: 'heartbeat',
    HELLO: 'hello',
    PANG: 'pang',
};
// 测试
export let IPConifg = {
    httpIp: "https://app.blgame.bet",
    socketIp: "https://ws.blgame.bet",
    assetIp: '',
    agentIp: '',
    assetPort: "",
    httpPort: '',
    socketPort: "",
    liveIp: ''
}
export let IMGConfig = {
    mianLogoImgUrl: "mianLogoImgUrl",
}
{
    // if (true) {
    //     console.log = () => {}
    // }
    // if (!window.location.href.includes('version')) {
    //     window.location.href = window.location.href + '?version='+new Date().getTime()
    // }
}