import { ILiveConfig, IMusic } from "../config/interface";

export default class ConfigManager {

    //对象
    private static instance: ConfigManager;


    // 直播视频配置数据
    public liveConfig: ILiveConfig = null

    // 当前播放的歌曲名
    public musicName: string = null

    public BgmMusic: IMusic[] = []

    //客服列表
    public Customer: any[] = []

    public isDev: boolean = true

    //广告通知
    public notices: string = ""

    //单利模式
    public static getInstance(): ConfigManager {
        if (this.instance == null) {
            this.instance = new ConfigManager();
        }
        return this.instance;
    }

    public setLiveConfig(data: any) {
        this.liveConfig = data
        this.liveConfig.content = JSON.parse(data.content)
        console.log('this.liveConfig:', this.liveConfig);
    }

}
