/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_GameOption extends fgui.GComponent {

	public m_list_btn:fgui.GButton;
	public m_mask:fgui.GGraph;
	public m_bg:fgui.GImage;
	public m_history_btn:fgui.GButton;
	public m_bet_record_btn:fgui.GButton;
	public m_rank_btn:fgui.GButton;
	public m_gamelist_btn:fgui.GButton;
	public m_changebgm_btn:fgui.GButton;
	public m_net:fgui.GButton;
	public m_play_des_btn:fgui.GButton;
	public m_full1_btn:fgui.GButton;
	public m_videoList:fgui.GList;
	public m_btns:fgui.GGroup;
	public static URL:string = "ui://61ic9kfk10j9vz95";

	public static createInstance():UI_GameOption {
		return <UI_GameOption>(fgui.UIPackage.createObject("game", "GameOption"));
	}

	protected onConstruct():void {
		this.m_list_btn = <fgui.GButton>(this.getChild("list_btn"));
		this.m_mask = <fgui.GGraph>(this.getChild("mask"));
		this.m_bg = <fgui.GImage>(this.getChild("bg"));
		this.m_history_btn = <fgui.GButton>(this.getChild("history_btn"));
		this.m_bet_record_btn = <fgui.GButton>(this.getChild("bet_record_btn"));
		this.m_rank_btn = <fgui.GButton>(this.getChild("rank_btn"));
		this.m_gamelist_btn = <fgui.GButton>(this.getChild("gamelist_btn"));
		this.m_changebgm_btn = <fgui.GButton>(this.getChild("changebgm_btn"));
		this.m_net = <fgui.GButton>(this.getChild("net"));
		this.m_play_des_btn = <fgui.GButton>(this.getChild("play_des_btn"));
		this.m_full1_btn = <fgui.GButton>(this.getChild("full1_btn"));
		this.m_videoList = <fgui.GList>(this.getChild("videoList"));
		this.m_btns = <fgui.GGroup>(this.getChild("btns"));
	}
}