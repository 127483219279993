/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_frame extends fgui.GLabel {

	public m_contentArea:fgui.GLoader;
	public m_closeButton:fgui.GButton;
	public static URL:string = "ui://d7esdcvtnvh60";

	public static createInstance():UI_frame {
		return <UI_frame>(fgui.UIPackage.createObject("windows", "frame"));
	}

	protected onConstruct():void {
		this.m_contentArea = <fgui.GLoader>(this.getChild("contentArea"));
		this.m_closeButton = <fgui.GButton>(this.getChild("closeButton"));
	}
}