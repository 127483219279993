/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_DelWindow extends fgui.GComponent {

	public m_comfirm:fgui.GButton;
	public m_cancel:fgui.GButton;
	public static URL:string = "ui://8xnwynuhhat2o";

	public static createInstance():UI_DelWindow {
		return <UI_DelWindow>(fgui.UIPackage.createObject("cash", "DelWindow"));
	}

	protected onConstruct():void {
		this.m_comfirm = <fgui.GButton>(this.getChild("comfirm"));
		this.m_cancel = <fgui.GButton>(this.getChild("cancel"));
	}
}