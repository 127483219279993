/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_title from "./UI_title";
import UI_marquee from "./UI_marquee";
import UI_marqueeText from "./UI_marqueeText";
import UI_Button from "./UI_Button";
import UI_Personal from "./UI_Personal";
import UI_homeContent from "./UI_homeContent";
import UI_row from "./UI_row";
import UI_Footer from "./UI_Footer";
import UI_refsh from "./UI_refsh";
import UI_Home from "./UI_Home";

export default class homeBinder {
	public static bindAll():void {
		fgui.UIObjectFactory.setExtension(UI_title.URL, UI_title);
		fgui.UIObjectFactory.setExtension(UI_marquee.URL, UI_marquee);
		fgui.UIObjectFactory.setExtension(UI_marqueeText.URL, UI_marqueeText);
		fgui.UIObjectFactory.setExtension(UI_Button.URL, UI_Button);
		fgui.UIObjectFactory.setExtension(UI_Personal.URL, UI_Personal);
		fgui.UIObjectFactory.setExtension(UI_homeContent.URL, UI_homeContent);
		fgui.UIObjectFactory.setExtension(UI_row.URL, UI_row);
		fgui.UIObjectFactory.setExtension(UI_Footer.URL, UI_Footer);
		fgui.UIObjectFactory.setExtension(UI_refsh.URL, UI_refsh);
		fgui.UIObjectFactory.setExtension(UI_Home.URL, UI_Home);
	}
}