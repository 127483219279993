/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_menuList extends fgui.GComponent {

	public m_unsettled:fgui.GButton;
	public m_settled:fgui.GButton;
	public m_backwater:fgui.GButton;
	public m_balance:fgui.GButton;
	public m_modifypassword:fgui.GButton;
	public m_pay_password:fgui.GButton;
	public m_lang_btn:fgui.GButton;
	public m_logout:fgui.GButton;
	public m_agent_btn:fgui.GButton;
	public m_customer_btn:fgui.GButton;
	public static URL:string = "ui://61q84xa5mi8l1c";

	public static createInstance():UI_menuList {
		return <UI_menuList>(fgui.UIPackage.createObject("me", "menuList"));
	}

	protected onConstruct():void {
		this.m_unsettled = <fgui.GButton>(this.getChild("unsettled"));
		this.m_settled = <fgui.GButton>(this.getChild("settled"));
		this.m_backwater = <fgui.GButton>(this.getChild("backwater"));
		this.m_balance = <fgui.GButton>(this.getChild("balance"));
		this.m_modifypassword = <fgui.GButton>(this.getChild("modifypassword"));
		this.m_pay_password = <fgui.GButton>(this.getChild("pay_password"));
		this.m_lang_btn = <fgui.GButton>(this.getChild("lang_btn"));
		this.m_logout = <fgui.GButton>(this.getChild("logout"));
		this.m_agent_btn = <fgui.GButton>(this.getChild("agent_btn"));
		this.m_customer_btn = <fgui.GButton>(this.getChild("customer_btn"));
	}
}