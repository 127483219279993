import { cnf_event } from "../config/Event_cnf";
import { SOCKET_EVENTS, IPConifg } from "../config/NetConfig";
import AudioHelper from "../helper/AudioHelper";
import HttpHelper from "../helper/HttpHelper";
import { notice } from "../helper/NoticeHelper"
import { UIHelper } from "../helper/UIHelper";
import PlayerDataManager from "../model/PlayerDataManager";
import LocalUtil from "../util/LocalUtil";
import { http } from "./Http";
interface ProtocolHandlers {
    [key: string]: (data: any) => void;
}
export default class NetManager {
    intervalTime: number = null;
    timeOut: number = null;
    HEART_TIME_OUT = false; //是否超时
    HEART_TIME = 5000; //10秒一次心跳包
    HEART_TIME_NUM = 0; //超时次数
    socket: any = null;
    token: string = ""
    //加入房间的ID
    SOCKET_URL = IPConifg.socketIp + IPConifg.socketPort //
    roomId: number = null
    static instance: NetManager = null;
    protocolHandlers: ProtocolHandlers = {
        //心跳包返
        heartbeat: () => {
            // this.onResponseHeartBeat()
        },
        // reconnecting: () => {
        //     console.log('断线重连');
        //     self.isReconnecting= true
        //     UIHelper.showMsg("loading")
        //     //UIHelper.showMsg("确认弹窗")
        // },
        // reconnect: () => {
        //     console.log('重连');
        //     self.isReconnecting= true
        //     UIHelper.showMsg("loading")
        //     //UIHelper.showMsg("确认弹窗")
        // },

        //链接错误
        // connect_error: (data: any) => {
        //     console.log('连接服务器时出错：');
        //     self.isReconnecting = true
        //     UIHelper.getLoadingBox().showErrorMsg(i18nMgr._getLabel('disconnect', []))
        //     AudioHelper.playError()
        // },
        //链接成功
        connect: (data: any) => {
            console.log("连接成功");
            let time = setTimeout(() => {
                if (this.roomId) {
                    this.joinRoom(this.roomId)
                    clearTimeout(time)
                }
            }, 500)
        },
        //更新用户的最新连接socket,旧的全部关闭
        upsocket: (data: any) => {
            console.log("断开socket链接:", data)
            notice.emit(cnf_event.Upsocket, { data: data })
        },
        //监听用户余额改变
        memberBalanceEvent: (data) => {
            notice.emit(cnf_event.memberBalanceEvent + data.roomId, data)
        },
        //监听房间游戏的变化
        roomGameEvent: (data) => {
            console.log("socket收到roomGameEvent", data);

            notice.emit(cnf_event.roomGameEvent + data.roomId, data)
        },
        //监听房间用户列表
        roomUserInfoListEvent: (data) => {
            notice.emit(cnf_event.roomUserInfoListEvent + data.roomId, data)
        },
        //监听房间用户人数
        upCount: (data) => {
            notice.emit(cnf_event.upCount + data.roomId, data)
        },
        //房间消息
        roomSendMsgEvent: (data) => {
            notice.emit(cnf_event.roomSendMsgEvent + data.topic, data)
        },
        //房间弹幕
        roomBarrageEvent: (data) => {
            notice.emit(cnf_event.roomBarrageEvent + data.topic, data)
        },
        // 所有用户下注记录信息
        allUserBetCodeEvent: (data) => {
            console.log("allUserBetCodeEvent", data);

            notice.emit(cnf_event.allUserBetCodeEvent + data.roomId, data)
        },
        // 用户下注记录信息
        userBetCodeEvent: (data) => {
            console.log("userBetCodeEvent", data);

            notice.emit(cnf_event.userBetCodeEvent, data)
        },
        //  修改下逐项、赔率事件
        changeBetItem: (data) => {
            console.log("changeBetItem", data);

            notice.emit(cnf_event.changeBetItem, data)
        },
        userTotalBetAmountEvent: (data) => {
            console.log("userTotalBetAmountEvent", data);

            notice.emit(cnf_event.userTotalBetAmountEvent, data.obj)
        },

        //监听断开错误
        connect_error: (data) => {
            this.reconnect(1)
        },
        //监听连接超时
        connect_timeout: (data) => {
            this.reconnect(2)
        },
        //监听错误
        error: (data) => {
            this.reconnect(3)
        },
        //断开链接
        disconnect: (data: any) => {
            this.reconnect(4)
        },


        //链接错误
        // connect_error: (data: any) => {
        //     console.log('连接服务器时出错：');
        //     self.isReconnecting = true
        //     UIHelper.getLoadingBox().showErrorMsg(i18nMgr._getLabel('disconnect', []))
        //     AudioHelper.playError()
        // },
    };

    static getInstance() {
        if (!this.instance) {
            this.instance = new NetManager();
        }
        return this.instance;
    }
    constructor() {

    }
    self = this

    inited: boolean = false
    init() {
        if (this.inited) {
            return
        }
        this.inited = true
        if (this.socket) {
            this.socket.removeAllListeners()
            this.socket.disconnect()
            this.socket.close()
            this.socket = null
        }
        this.connect()

        Object.keys(this.protocolHandlers).forEach((protocolName) => {
            this.socket.on(protocolName, (data: any) => {
                // this.onResponseHeartBeat()
                this.protocolHandlers[protocolName](data);
            });
        });
    }


    reconnect(index: number) {
        console.log('net reconnect', index);

        if (NetManager.getInstance().token) {
            if (this.socket) {
                this.socket.removeAllListeners()
                this.socket.disconnect()
                this.socket.close()
                this.socket = null
            }
            this.connect()
            Object.keys(this.protocolHandlers).forEach((protocolName) => {
                this.socket.on(protocolName, (data: any) => {
                    // this.onResponseHeartBeat()
                    this.protocolHandlers[protocolName](data);
                });
            });
        }

    }


    joinRoomTimer: number = null
    joinRoom(roomId: number) {
        this.roomId = roomId
        let event = "roomEnterEvent";//自定义的事件名称
        let message: any = {}
        message.event = event;
        message.roomId = roomId;
        message.userCount = "1";
        message.action = "in";

        this.socket && this.socket.emit("roomEnterEvent", message, function (data1: any, data2: any) {
            console.log("ack1:" + data1);
            console.log("ack2:" + data2);
        });


    }

    sendRoomMsg(content: string, roomId: number) {
        let socketEvent = "roomSendMsgEvent"; //自定义的事件名称
        let message: any = {};
        message.msgType = "roomSendMsgEvent";
        message.topic = roomId
        message.obj = content;
        console.log("发送消息了吗");

        this.socket.emit(socketEvent, message, function (data1: any, data2: any) {
            console.log("ack1:" + data1);
            console.log("ack2:" + data2);
        });
    }

    sendBarrageMsg(content: string, roomId: number) {
        let message: any = {}
        message.msgType = "roomBarrageEvent";
        message.topic = roomId;
        message.obj = content;

        this.socket.emit("roomBarrageEvent", message, function (data1: any, data2: any) {
            console.log("ack1:" + data1);
            console.log("ack2:" + data2);
        });
    }



    leaveRoom(roomId?: number) {
        this.socket && this.socket.emit("roomEvent", { "roomId": this.roomId, "action": "out" });
        this.roomId = null;
    }
    connect() {
        console.log("this.SOCKET_URL", this.SOCKET_URL);

        if (this.socket == null) {
            // @ts-ignore
            this.socket = window.io(this.SOCKET_URL + '?token=' + this.token, {
                transports: ["websocket"]
            })
            // this.startHeartBeat()
        }
    }
    closeSocket() {
        this.leaveRoom()
        if (this.socket) {
            this.socket.close()
        }
    }


}
