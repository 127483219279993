/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_Loading extends fgui.GComponent {

	public m_LoadingProgressBar:fgui.GProgressBar;
	public m_logo:fgui.GLoader;
	public static URL:string = "ui://1gj14ei7pkv26";

	public static createInstance():UI_Loading {
		return <UI_Loading>(fgui.UIPackage.createObject("loading", "Loading"));
	}

	protected onConstruct():void {
		this.m_LoadingProgressBar = <fgui.GProgressBar>(this.getChild("LoadingProgressBar"));
		this.m_logo = <fgui.GLoader>(this.getChild("logo"));
	}
}