/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_row extends fgui.GComponent {

	public m_title:fgui.GTextField;
	public m_pic:fgui.GLoader;
	public static URL:string = "ui://d7esdcvtnreqzaq";

	public static createInstance():UI_row {
		return <UI_row>(fgui.UIPackage.createObject("windows", "row"));
	}

	protected onConstruct():void {
		this.m_title = <fgui.GTextField>(this.getChild("title"));
		this.m_pic = <fgui.GLoader>(this.getChild("pic"));
	}
}