/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_video_pos extends fgui.GComponent {

	public m_video_pos:fgui.GGraph;
	public m_video_click_btn:fgui.GButton;
	public m_full1_btn:fgui.GButton;
	public m_btns:fgui.GGroup;
	public static URL:string = "ui://61ic9kfkmzb7z94";

	public static createInstance():UI_video_pos {
		return <UI_video_pos>(fgui.UIPackage.createObject("game", "video_pos"));
	}

	protected onConstruct():void {
		this.m_video_pos = <fgui.GGraph>(this.getChild("video_pos"));
		this.m_video_click_btn = <fgui.GButton>(this.getChild("video_click_btn"));
		this.m_full1_btn = <fgui.GButton>(this.getChild("full1_btn"));
		this.m_btns = <fgui.GGroup>(this.getChild("btns"));
	}
}