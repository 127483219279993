export class UIBase {

    UI_Item: fgui.GObject = null

    constructor(ui: any) {
        this.UI_Item = ui
        if (Laya.stage.screenMode) {
            
        }
        this.UI_Item.height = Laya.stage.height
    }


    show() {
        this.UI_Item.visible = true
    }

    hide() {
        this.UI_Item.visible = false
    }
}