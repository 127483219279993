/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_btn from "./UI_btn";
import UI_Login from "./UI_Login";
import UI_Button5 from "./UI_Button5";

export default class loginBinder {
	public static bindAll():void {
		fgui.UIObjectFactory.setExtension(UI_btn.URL, UI_btn);
		fgui.UIObjectFactory.setExtension(UI_Login.URL, UI_Login);
		fgui.UIObjectFactory.setExtension(UI_Button5.URL, UI_Button5);
	}
}