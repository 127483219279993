/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_customer_item_window extends fgui.GComponent {

	public m_avatar:fgui.GComponent;
	public m_title:fgui.GTextField;
	public m_account:fgui.GTextField;
	public m_btn:fgui.GButton;
	public static URL:string = "ui://d7esdcvtt53uz8i";

	public static createInstance():UI_customer_item_window {
		return <UI_customer_item_window>(fgui.UIPackage.createObject("windows", "customer_item_window"));
	}

	protected onConstruct():void {
		this.m_avatar = <fgui.GComponent>(this.getChild("avatar"));
		this.m_title = <fgui.GTextField>(this.getChild("title"));
		this.m_account = <fgui.GTextField>(this.getChild("account"));
		this.m_btn = <fgui.GButton>(this.getChild("btn"));
	}
}