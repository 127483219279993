import { E_ScreenMode } from "../../config/Enum";
import { cnf_event } from "../../config/Event_cnf";
import { IGameRoom } from "../../config/interface";
import UI_ChipsWindow from "../../fgui/windows/UI_ChipsWindow";
import { notice } from "../../helper/NoticeHelper";
import { UIHelper } from "../../helper/UIHelper";
import BallDataManager from "../../model/BallDataManager";
import LocalUtil from "../../util/LocalUtil";
export class ChipsWindow extends fgui.Window {
    private chips_btn_list: fairygui.GList
    private selected: any = []
    private chips_number_list: any = []
    private roomData:IGameRoom = null

    public constructor(roomData:IGameRoom) {
        super();
        this.roomData = roomData

        this.chips_number_list = BallDataManager.getInstance().allChipsData.filter((item:number)=>{
            return item >= this.roomData.minAmount && item <= this.roomData.maxAmount
        })

        console.log(this.chips_number_list,BallDataManager.getInstance().allChipsData);
        

    }
    protected onInit(): void {
        this.contentPane = UI_ChipsWindow.createInstance();
        this.contentPane.getChild("sure_btn").onClick(this, this.onSureClick)
        this.contentPane.getChild("cancel_btn").onClick(this, this.onCloseClick)

        this.selected = LocalUtil.objectForKey("chips_selected_list", [])

        this.chips_btn_list = this.contentPane.getChild("chips_btn_list")
        this.chips_btn_list.itemRenderer = Laya.Handler.create(this, this.renderListItem, null, false);
        this.chips_btn_list.numItems = this.chips_number_list.length;
        if(Laya.stage.screenMode != E_ScreenMode.horizontal){
            this.contentPane.getChild("frame").asCom.getChild("closeButton").x = this.contentPane.getChild("frame").asCom.getChild("closeButton").x - 20
            this.contentPane.getChild("frame").asCom.getChild("closeButton").y = this.contentPane.getChild("frame").asCom.getChild("closeButton").y + 20
            this.contentPane.getChild("frame").asCom.getChild("closeButton").scaleX = 1.3
            this.contentPane.getChild("frame").asCom.getChild("closeButton").scaleY = 1.3
        }
        this.setPivot(0.5, 0.5);
        this.center();

        this.contentPane.getChild('mask').onClick(this,()=>{
            this.doHideAnimation()
        })
    }
    protected doShowAnimation(): void {
        this.setScale(0.1, 0.1);
        fgui.GTween.to2(0.1, 0.1, 1, 1, 0.3)
            .setTarget(this, this.setScale)
            .setEase(fgui.EaseType.QuadOut)
            .onComplete(this.onShown, this);
    }
    protected doHideAnimation(): void {
        fgui.GTween.to2(1, 1, 0.1, 0.1, 0.3)
            .setTarget(this, this.setScale)
            .setEase(fgui.EaseType.QuadOut)
            .onComplete(this.hideImmediately, this);
    }
    //所有筹码列表
    private renderListItem(index: number, obj: fgui.GObject): void {
        let item: fgui.GButton = <fgui.GButton>obj;
        item.icon = fgui.UIPackage.getItemURL("game", "chip_" + this.chips_number_list[index]);
        item.getChild("chips_bg").visible = this.selected.includes(this.chips_number_list[index]) 
        item.onClick(this, () => {
            if (this.selected.includes(this.chips_number_list[index])) {
                let n = this.selected.indexOf(this.chips_number_list[index])
                this.selected.splice(n, 1)
                item.getChild("chips_bg").visible = false
            } else {
                // if(this.selected.length  > 3){
                //     UIHelper.showMsg("最多自定义4个筹码")
                //     return
                // }else{
                // }
                this.selected.push(this.chips_number_list[index])
                item.getChild("chips_bg").visible = true
            }
        })
    }
    protected onSureClick(): void {
        console.log(`保存${this.selected}`)
        let arr: any = this.selected
        for (let i = 0; i < arr.length; i++) {
            let flag = false;
            for (let j = 0; j < arr.length - i - 1; j++) {
                if (arr[j] > arr[j + 1]) {
                    const temp = arr[j];
                    arr[j] = arr[j + 1];
                    arr[j + 1] = temp;
                    flag = true;
                }
            }
            if (!flag) break
        }
        console.log(arr)
        arr = arr.filter((item: number) => {
            return item >= this.roomData.minAmount && item <= this.roomData.maxAmount
        })

        LocalUtil.setObject(arr, "chips_selected_list")
        notice.emit(cnf_event.NOTICE_UPDATE_CHIPS, {})
        this.onCloseClick()
    }
    protected onCloseClick(): void {
        UIHelper.closeWindow(this)
    }
    destroy() {
        // this.chips_btn_list.dispose()
    }
}