import { E_ScreenMode } from "../../config/Enum";
import { cnf_event } from "../../config/Event_cnf";
import { IGameRoom, IVideoType } from "../../config/interface";
import UI_Chat from "../../fgui/chat/UI_chat";
import UI_Game from "../../fgui/game/UI_Game";
import UI_GameRecordWindow from "../../fgui/windows/UI_GameRecordWindow";
import { NodePlayerHelper } from "../../helper/WebNodePlayerHelper";
import { notice } from "../../helper/NoticeHelper";
import { UIHelper } from "../../helper/UIHelper";
import BallDataManager from "../../model/BallDataManager";
import PlayerDataManager from "../../model/PlayerDataManager";
import { http } from "../../net/Http";
import NetManager from "../../net/NetManager";
import { Router } from "../../util/router";
import { UIBase } from "../scene/UIBase";
import { BetArea } from "./betArea";
import { BetRecord } from "./betRecord";
import { Chat } from "./chat";
import { ChipsBtns } from "./chipsBtn";
import { GameRecord } from "./gameRecord";
import { PrizeModel } from "./prize";
import { ChipsWindow } from "../windows/ChipsWindow";
import { Rank } from "./rank";
import UI_video1_btn from "../../fgui/game/UI_video1_btn";
import UI_BetRecordWindow from "../../fgui/windows/UI_BetRecordWindow";
import I18nHelper from "../../helper/I18nHelper";
import GlobalParameterManager from "../../model/GlobalParameterManager";
import { GameLIstWindow } from "../windows/GameLIstWindow";
import AudioHelper from "../../helper/AudioHelper";
import LocalUtil from "../../util/LocalUtil";
import HttpHelper from "../../helper/HttpHelper";
import { ConfirmWindow } from "../windows/ConfirmWindow";
export class Game extends UIBase {
    private reloadNums: number = 0
    private or: E_ScreenMode
    private uiGame: UI_Game
    private betArea: BetArea
    private chipBtns: ChipsBtns
    private prizeModel: PrizeModel
    private chat: Chat
    private gameRecord: GameRecord
    private betRecord: BetRecord
    private roomData: IGameRoom = null
    private roomId: number
    rank: Rank;
    xhrList: XMLHttpRequest[] = [];
    timer: number = null
    constructor(or: E_ScreenMode, roomId: number) {
        super(UI_Game.createInstance())
        this.or = or
        this.roomId = roomId
        let xhr = http.getRoomInfo(this.roomId, (res: any) => {
            if (res.code == 200) {
                this.roomData = res.data
                this.onUILoaded()
            } else {
                UIHelper.showMsg(I18nHelper.t(res.msg))
                Laya.Scene.open(Router.Home, true, null, Laya.Handler.create(this, () => {
                }))
            }
        })
        this.xhrList.push(xhr)


    }


    tourTimer: number = null
    onUILoaded() {
        if (PlayerDataManager.getInstance().getPlayerInfo().isTour == 2) {
            this.tourTimer = setTimeout(() => {
                UIHelper.showWindow(new ConfirmWindow(I18nHelper.t('tourTip'), I18nHelper.t('tip'), () => {
                    HttpHelper.getInstance().logOut()
                }, () => {
                    Laya.Scene.open(Router.Home, true, null, Laya.Handler.create(this, () => {
                    }))
                }))
                return
            }, 1000 * 60 * 10)
        }

        this.uiGame = this.UI_Item as UI_Game

        if (this.roomData.videoList) {
            // this.roomData.video = JSON.parse(this.roomData.video as string) as IVideoType[]
            NodePlayerHelper.getInstance().playByUrl("gameVideo", this.roomData.videoList[0].url, this.uiGame.m_video_pos.width, this.uiGame.m_video_pos.height)
            NodePlayerHelper.getInstance().playByUrlFull("gameVideoFull", this.roomData.videoList[0].url, this.uiGame.m_video_pos.width, this.uiGame.m_video_pos.height)
        }

        fgui.GRoot.inst.addChild(this.uiGame);
        this.betArea = new BetArea(this.or, this.uiGame.m_Bet, this.roomData)
        this.chipBtns = new ChipsBtns(this.uiGame.m_Bet.m_chips_list, this.roomData)
        this.prizeModel = new PrizeModel(this.uiGame.m_prize)
        this.uiGame.m_page_title.text = this.roomData.name

        // if (PlayerDataManager.getInstance().getPlayerInfo().isThird == 0) {
        //     this.uiGame.m_back.visible = true
        // } else {
        //     this.uiGame.m_back.visible = false
        // }

        this.uiGame.m_back.onClick(this, () => {
            // let closeres =  Laya.Scene.destroy(Router.Game, Router.Game)
            // console.log("closeres",closeres);

            Laya.Scene.open(Router.Home, true, null, Laya.Handler.create(this, () => {
            }))
        });


        (this.uiGame.m_chat as UI_Chat).m_game.onClick(this, this.showGame)

        this.uiGame.m_Bet.m_mask.onClick(this, this.hideGame)
        this.uiGame.m_Bet.m_hideGameBtn.onClick(this, this.hideGame)

        this.gameRecord = new GameRecord(this.uiGame.m_gameRecordWindow as UI_GameRecordWindow, this.roomData.id, this.roomData.ball);

        this.betRecord = new BetRecord(this.uiGame.m_betRecordWindow as UI_BetRecordWindow);

        // this.uiGame.m_Bet.m_gameRecordBtn.onClick(this,()=>{

        //     this.gameRecord.showWindow()
        // })

        this.uiGame.m_gameOptions.m_bet_record_btn.onClick(this, this.showBetInfo);

        (this.uiGame.m_betRecordWindow as UI_GameRecordWindow).m_mask.onClick(this, this.hideBetInfo)

        this.uiGame.m_gameOptions.m_history_btn.onClick(this, this.showHistory);
        (this.uiGame.m_gameRecordWindow as UI_GameRecordWindow).m_mask.onClick(this, this.hideHistory)


        this.uiGame.m_gameOptions.m_rank_btn.onClick(this, this.showRank);
        this.uiGame.m_Rank.m_mask.onClick(this, this.hideRank)


        this.chat = new Chat(this.uiGame.m_chat as UI_Chat, this.roomData, this.uiGame.m_video_pos)


        this.uiGame.m_Bet.m_balance.setVar('m', PlayerDataManager.getInstance().getBalance())

        this.uiGame.m_gameOptions.m_mask.onClick(this, () => {
            this.uiGame.m_gameOptions.m_btns.visible = false
        })

        this.uiGame.m_gameOptions.m_list_btn.onClick(this, () => {
            this.uiGame.m_gameOptions.m_btns.visible = true
        })

        this.uiGame.m_gameOptions.m_full1_btn.onClick(this, this.fullVideo)
        this.uiGame.m_exitFull.onClick(this, this.exitFullVideo);

        (this.uiGame.m_chat as UI_Chat).m_toggleCom.onClick(this, this.showChatBarrage)

        this.uiGame.m_Bet.m_customChipsBtn.onClick(this, this.showChipsWindow)



        this.uiGame.m_Bet.m_playDes.onClick(this, this.showGameDes)
        this.uiGame.m_gameOptions.m_play_des_btn.onClick(this, this.showGameDes)
        this.uiGame.m_gameDes.m_mask.onClick(this, this.hideGameDes)



        this.uiGame.m_gameOptions.m_gamelist_btn.onClick(this, this.showGameList);

        this.rank = new Rank(this.uiGame.m_Rank, this.roomId)

        this.uiGame.m_gameDes.m_content.text = this.roomData.des

        notice.on(cnf_event.roomGameEvent + this.roomData.id, this.roomChange)
        notice.on(cnf_event.userTotalBetAmountEvent, this.betChange)
        notice.on(cnf_event.OnBalanceChanged, this.balanceChange)
        this.betChange("0")

        this.uiGame.m_gameOptions.m_videoList.itemRenderer = Laya.Handler.create(this, this.renderVideoListItem, null, false);

        if (this.roomData.videoList && this.roomData.videoList.length > 0) {
            this.uiGame.m_gameOptions.m_videoList.numItems = this.roomData.videoList.length
            this.uiGame.m_gameOptions.m_videoList.height = this.uiGame.m_gameOptions.m_videoList._children.length * 60 + ((this.uiGame.m_gameOptions.m_videoList._children.length - 1) * this.uiGame.m_gameOptions.m_videoList.lineGap)
            this.uiGame.m_gameOptions.m_bg.height += this.uiGame.m_gameOptions.m_videoList.height - 170
        }



        this.uiGame.m_video_pos.m_full1_btn.onClick(this, this.fullVideo)
        this.uiGame.m_video_pos.m_video_click_btn.onClick(this, () => {
            // alert("点击了视频")
            this.uiGame.m_video_pos.m_btns.visible = !this.uiGame.m_video_pos.m_btns.visible
            clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                if (this.uiGame && this.uiGame.m_video_pos.m_btns.visible) {
                    this.uiGame.m_video_pos.m_btns.visible = false
                }
            }, 5000)
        })
        this.uiGame.m_logo.url = GlobalParameterManager.getInstance().getLogoUrl();

        if (LocalUtil.stringForKey("bgm", "true") == "true") {
            LocalUtil.setString("true", "bgm")
            AudioHelper.playMusic(this.roomData.music)
            this.uiGame.m_gameOptions.m_changebgm_btn.title = "关闭背景音乐"
        } else {
            this.uiGame.m_gameOptions.m_changebgm_btn.title = "打开背景音乐"
        }
        NetManager.getInstance().joinRoom(this.roomData.id)
        this.uiGame.m_gameOptions.m_changebgm_btn.onClick(this, this.changeBGM)
        let time = 0
        let timer: number = null
        Laya.stage.on(Laya.Event.FOCUS, () => {
            clearInterval(timer)
            if (LocalUtil.stringForKey("bgm", "true") == "true") {
                LocalUtil.setString("true", "bgm")
                AudioHelper.playMusic(this.roomData.music)
                this.uiGame.m_gameOptions.m_changebgm_btn.title = "关闭背景音乐"
            } else {
                this.uiGame.m_gameOptions.m_changebgm_btn.title = "打开背景音乐"
            }
            if (time > 30) {
                time = 0
                if (this.roomData.videoList) {
                    // this.roomData.video = JSON.parse(this.roomData.video as string) as IVideoType[]
                    NodePlayerHelper.getInstance().stopAll()
                    NodePlayerHelper.getInstance().playByUrl("gameVideo", this.roomData.videoList[0].url, this.uiGame.m_video_pos.width, this.uiGame.m_video_pos.height)
                    NodePlayerHelper.getInstance().playByUrlFull("gameVideoFull", this.roomData.videoList[0].url, this.uiGame.m_video_pos.width, this.uiGame.m_video_pos.height)
                }
                NetManager.getInstance().reconnect(5)
            }
            console.log("显示了");
        })

        Laya.stage.on(Laya.Event.BLUR, () => {
            AudioHelper.stopMusic()
            time = 0
            timer = setInterval(() => {
                console.log("进入后台了", time);
                time++
            }, 1000)
        })


        // 监听页面可见性变化的事件
        document.addEventListener('visibilitychange', function () {
            if (document.hidden) {
                console.log('用户已回到后台');
                AudioHelper.stopMusic()
                // 用户回到后台时的处理逻辑
            } else {
                console.log('用户回到前台');
                // 用户回到前台时的处理逻辑
            }
        });
        // // @ts-ignore
        // if (window.navigator.connection) {
        //     // @ts-ignore
        //     window.navigator.connection.addEventListener('change', this.handleConnectionChange.bind(this));
        // } else {
        //     console.log('Network information API not supported');
        // }

    }

    // handleConnectionChange() {
    //     // @ts-ignore
    //     if (window.navigator.connection) {
    //         // @ts-ignore
    //         this.uiGame.m_gameOptions.m_net.title = `网络:${window.navigator.connection.effectiveType} `
    //     }
    // }


    changeBGM = () => {
        if (LocalUtil.stringForKey("bgm") == "true") {
            LocalUtil.setString("false", "bgm")
            AudioHelper.stopMusic()
            this.uiGame.m_gameOptions.m_changebgm_btn.title = "打开背景音乐"
        } else {
            LocalUtil.setString("true", "bgm")
            AudioHelper.playMusic(this.roomData.music)
            this.uiGame.m_gameOptions.m_changebgm_btn.title = "关闭背景音乐"
        }

    }


    renderVideoListItem(index: number, item: UI_video1_btn): void {
        console.log(this.roomData.videoList[index].pairCode, I18nHelper.t(this.roomData.videoList[index].pairCode));

        item.title = I18nHelper.t(this.roomData.videoList[index].pairCode)

        item.onClick(this, () => {
            this.changeVideo(this.roomData.videoList[index].url)
        })
    }

    changeVideo(url: string) {
        this.uiGame.m_gameOptions.m_btns.visible = false

        NodePlayerHelper.getInstance().stopAll()
        NodePlayerHelper.getInstance().playByUrl("gameVideo", url, this.uiGame.m_video_pos.width, this.uiGame.m_video_pos.height)
        NodePlayerHelper.getInstance().playByUrlFull("gameVideoFull", url, this.uiGame.m_video_pos.width, this.uiGame.m_video_pos.height)
    }


    showGameList() {
        UIHelper.showWindow(new GameLIstWindow())
    }


    showChipsWindow() {
        UIHelper.showWindow(new ChipsWindow(this.roomData))
    }

    showChatBarrage = () => {
        if ((this.uiGame.m_chat as UI_Chat).m_toggleCom.selected) {
            this.uiGame.m_video_pos.visible = true
        } else {
            this.uiGame.m_video_pos.visible = false
        }
    }

    fullVideo = () => {
        this.uiGame.m_gameOptions.m_btns.visible = false

        NodePlayerHelper.getInstance().showVideo("gameVideoFull")
        NodePlayerHelper.getInstance().hideVideo("gameVideo")
        this.uiGame.m_exitFull.visible = true
        this.uiGame.m_gameRoot.visible = false
    }

    exitFullVideo = () => {
        NodePlayerHelper.getInstance().showVideo("gameVideo")
        NodePlayerHelper.getInstance().hideVideo("gameVideoFull")
        this.uiGame.m_exitFull.visible = false
        this.uiGame.m_gameRoot.visible = true
    }

    balanceChange = () => {
        this.uiGame.m_Bet.m_balance.setVar('m', PlayerDataManager.getInstance().getBalance())
    }

    betChange = (num: any) => {
        console.log("投注金额:", num);
        this.betRecord.getList()
        this.uiGame.m_Bet.m_betNum.setVar('b', num)
    }


    roomChange = (data: any) => {
        console.log("notice收到roomGameEvent", data);

        this.prizeModel.setData(data)
        BallDataManager.getInstance().nperCode = data.nperCode
        if (data.resultStatus == 0) {
            this.betArea.clearChips()
            this.betArea.canBet = true
            this.balanceChange()
        }
        if (data.resultStatus == 1) {
            this.betArea.canBet = true
        }

        if (data.resultStatus == 2) {
            this.betArea.canBet = false
        }

        if (data.resultStatus == 3) {
            this.betArea.canBet = false
            this.betArea.clearChips()
            this.betChange(0)
        }



    }


    speed: number = 300

    showGameDes = () => {
        this.uiGame.m_gameOptions.m_btns.visible = false

        let y = fgui.GRoot.inst.height - this.uiGame.m_gameDes.height
        this.uiGame.m_gameDes.visible = true
        Laya.Tween.to(this.uiGame.m_gameDes, { y: y }, this.speed, null, Laya.Handler.create(this, () => {
        }))
    }

    hideGameDes = () => {
        Laya.Tween.to(this.uiGame.m_gameDes, { y: 2500 }, this.speed, null, Laya.Handler.create(this, () => {
            this.uiGame.m_gameDes.visible = false
        }))
    }



    showGame = () => {
        let y = fgui.GRoot.inst.height - this.uiGame.m_Bet.height
        this.uiGame.m_Bet.visible = true
        Laya.Tween.to(this.uiGame.m_Bet, { y: y }, this.speed, null, Laya.Handler.create(this, () => {
        }))
    }

    hideGame = () => {
        Laya.Tween.to(this.uiGame.m_Bet, { y: 2500 }, this.speed, null, Laya.Handler.create(this, () => {
            this.uiGame.m_Bet.visible = false
        }))
    }

    showRank = () => {
        this.uiGame.m_gameOptions.m_btns.visible = false
        this.rank.showWindow()

        let y = fgui.GRoot.inst.height - this.uiGame.m_Rank.height
        this.uiGame.m_Rank.visible = true
        Laya.Tween.to(this.uiGame.m_Rank, { y: y }, this.speed, null, Laya.Handler.create(this, () => {
        }))
    }

    hideRank = () => {
        Laya.Tween.to(this.uiGame.m_Rank, { y: 2500 }, this.speed, null, Laya.Handler.create(this, () => {
            this.uiGame.m_Rank.visible = false
        }))
    }



    showHistory = () => {
        this.uiGame.m_gameOptions.m_btns.visible = false
        this.gameRecord.showWindow()

        let y = fgui.GRoot.inst.height - this.uiGame.m_gameRecordWindow.height
        this.uiGame.m_gameRecordWindow.visible = true
        Laya.Tween.to(this.uiGame.m_gameRecordWindow, { y: y }, this.speed, null, Laya.Handler.create(this, () => {
        }))
    }

    hideHistory = () => {
        Laya.Tween.to(this.uiGame.m_gameRecordWindow, { y: 2500 }, this.speed, null, Laya.Handler.create(this, () => {
            this.uiGame.m_gameRecordWindow.visible = false
        }))
    }


    showBetInfo = () => {
        this.uiGame.m_gameOptions.m_btns.visible = false
        this.betRecord.showWindow()

        let y = fgui.GRoot.inst.height - this.uiGame.m_betRecordWindow.height
        this.uiGame.m_betRecordWindow.visible = true
        Laya.Tween.to(this.uiGame.m_betRecordWindow, { y: y }, this.speed, null, Laya.Handler.create(this, () => {
        }))
    }

    hideBetInfo = () => {
        Laya.Tween.to(this.uiGame.m_betRecordWindow, { y: 2500 }, this.speed, null, Laya.Handler.create(this, () => {
            this.uiGame.m_betRecordWindow.visible = false
        }))
    }


    destroy() {
        AudioHelper.stopMusic()
        this.xhrList.forEach((xhr) => {
            xhr.abort()
        })
        Laya.stage.offAll()
        // 监听页面可见性变化的事件
        document.removeEventListener('visibilitychange', function () {
            if (document.hidden) {
                console.log('用户已回到后台');
                AudioHelper.stopMusic()
                // 用户回到后台时的处理逻辑
            } else {
                console.log('用户回到前台');
                // 用户回到前台时的处理逻辑
            }
        });
        clearTimeout(this.tourTimer)
        NetManager.getInstance().leaveRoom()
        console.log("GameView销毁了")
        this.chipBtns && this.chipBtns.destroy()
        this.betArea && this.betArea.destroy()
        this.prizeModel && this.prizeModel.destroy()
        this.chat && this.chat.destroy()
        this.gameRecord && this.gameRecord.destroy()
        this.betRecord && this.betRecord.destroy()
        this.rank && this.rank.destroy()
        notice.off(cnf_event.roomGameEvent + this.roomData.id, this.roomChange)
        notice.off(cnf_event.OnBalanceChanged, this.balanceChange)
        notice.off(cnf_event.userTotalBetAmountEvent, this.betChange)
        NodePlayerHelper.getInstance().stopAll()
        this.uiGame.dispose();
    }
}