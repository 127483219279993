/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_LHBallBtn2 extends fgui.GButton {

	public m_bg:fgui.GGraph;
	public static URL:string = "ui://d7esdcvtc9q3zad";

	public static createInstance():UI_LHBallBtn2 {
		return <UI_LHBallBtn2>(fgui.UIPackage.createObject("windows", "LHBallBtn2"));
	}

	protected onConstruct():void {
		this.m_bg = <fgui.GGraph>(this.getChild("bg"));
	}
}