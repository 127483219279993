/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_usdtQR extends fgui.GComponent {

	public m_mask:fgui.GGraph;
	public m_qr:fgui.GLoader;
	public m_address:fgui.GTextField;
	public m_btn:fgui.GButton;
	public static URL:string = "ui://eelfbt0z10j9v1h";

	public static createInstance():UI_usdtQR {
		return <UI_usdtQR>(fgui.UIPackage.createObject("wallet", "usdtQR"));
	}

	protected onConstruct():void {
		this.m_mask = <fgui.GGraph>(this.getChild("mask"));
		this.m_qr = <fgui.GLoader>(this.getChild("qr"));
		this.m_address = <fgui.GTextField>(this.getChild("address"));
		this.m_btn = <fgui.GButton>(this.getChild("btn"));
	}
}