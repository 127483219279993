/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_agent from "./UI_agent";
import UI_agent_item from "./UI_agent_item";

export default class agentBinder {
	public static bindAll():void {
		fgui.UIObjectFactory.setExtension(UI_agent.URL, UI_agent);
		fgui.UIObjectFactory.setExtension(UI_agent_item.URL, UI_agent_item);
	}
}