/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_video1_btn extends fgui.GButton {

	public m_iconx:fgui.GImage;
	public static URL:string = "ui://61ic9kfk10j9vz9j";

	public static createInstance():UI_video1_btn {
		return <UI_video1_btn>(fgui.UIPackage.createObject("game", "video1_btn"));
	}

	protected onConstruct():void {
		this.m_iconx = <fgui.GImage>(this.getChild("iconx"));
	}
}