import LocalUtil from "../util/LocalUtil";
import ResourceManager from "../util/ResourceManager";
import ConfigManager from "./ConfigManager";
import { LayaHelper } from "./LayaHelper";
export class AudioManager {
    static shared: AudioManager = new AudioManager();
    static prefix: string = "";
    private bgmMusicIndex: number = 0;
    current: Laya.SoundChannel;
    private musicVolume: number;
    private effectVolume: number;
    private voiceVolume: number;
    private audioCache: any;
    private music: string;
    public tempMusicVolume: number
    public tempEffectVolume: number
    constructor() {
        this.current = null;
        this.musicVolume = LocalUtil.floatForKey("music_volume", 1);
        this.effectVolume = LocalUtil.floatForKey("effect_volume", 1);
        this.voiceVolume = LocalUtil.floatForKey("voice_volume", 1);
        this.audioCache = {};
        console.log("this.musicVolume:", this.musicVolume, " this.effectVolume:", this.effectVolume)
    }
    static play(clip: string, loopsNumber: number): Laya.SoundChannel {
        return this.shared.play(clip, loopsNumber);
    }
    static stopPlay(audioUrl: string) {
        Laya.SoundManager.stopSound(audioUrl)
    }

    static stopMusic() {
        Laya.SoundManager.stopMusic()
    }
    static playMusic(url: string) {
        
        this.shared.playMusic(url);
    }
    static playAudio(url: string) {
        this.shared.playAudio(url);
    }
    static playLocalAudio(url: string, idBlock: Function, endBlock: Function) {
        return this.shared.playLocalAudio(url, idBlock, endBlock);
    }
    static setMusicVolume(volume: number) {
        this.shared.setMusicVolume(volume);
    }
    static setEffectVolume(volume: number) {
        this.shared.setEffectVolume(volume);
    }
    static setVoiceVolume(volume: number) {
        this.shared.setMusicVolume(volume);
    }
    static getMusicVolume(): number {
        return this.shared.getMusicVolume();
    }
    static getEffectVolume(): number {
        return this.shared.getEffectVolume();
    }
    static getVoiceVolume(): number {
        return this.shared.getVoiceVolume();
    }
    play(clip: string, loop: number): Laya.SoundChannel {
        Laya.SoundManager.stopSound(this.current.url)
        this.current = Laya.SoundManager.playSound(clip, loop)
        return this.current
    }


    errorCount = 0
    playMusic(url: string) {
        // if (this.music == url) {
        //     return;
        // }
        // let audio = this.audioCache[url];
        // if (audio) {
        //     this.music = url;
        //     Laya.SoundManager.setMusicVolume(this.getMusicVolume())
        //     Laya.SoundManager.playMusic(audio, 1)
        // } else {
            let self = this;
            LayaHelper.loadMP3(url, (resource: any) => {
                if (resource) {
                    self.music = url;
                    self.audioCache[url] = resource;
                    Laya.SoundManager.setMusicVolume(self.getMusicVolume())
                    this.current = Laya.SoundManager.playMusic(url, 1, new Laya.Handler(this.current, (isCom: any) => {
                        if (isCom) {
                            console.log("播放结束")
                            let findIndex = ConfigManager.getInstance().BgmMusic.findIndex((item: { songAddress: string; }) => {
                                return url.includes(item.songAddress)
                            })
                            console.log("当前音乐的下表:", findIndex);
                            if (findIndex == ConfigManager.getInstance().BgmMusic.length - 1) {
                                ConfigManager.getInstance().musicName = ConfigManager.getInstance().BgmMusic[0].songName
                                this.playMusic(ConfigManager.getInstance().BgmMusic[0].songAddress)
                                // UI_SettingWindow.createInstance().getChild("music_select").asComboBox.value = ConfigManager.getInstance().BgmMusic[0].songAddress
                            } else {
                                console.log("当前:", findIndex);
                                ConfigManager.getInstance().musicName = ConfigManager.getInstance().BgmMusic[findIndex + 1].songName
                                this.playMusic(ConfigManager.getInstance().BgmMusic[findIndex + 1].songAddress)
                                // UI_SettingWindow.createInstance().getChild("music_select").asComboBox.value = ConfigManager.getInstance().BgmMusic[findIndex + 1].songAddress
                            }
                        } else {
                            console.log("手动暂停")
                        }
                    }))
                } else {
                    if (this.errorCount == ConfigManager.getInstance().BgmMusic.length) {
                        return
                    }
                    ConfigManager.getInstance().musicName = ConfigManager.getInstance().BgmMusic[this.errorCount].songName
                    this.playMusic(ConfigManager.getInstance().BgmMusic[this.errorCount].songAddress)
                    console.warn(`找不到音乐资源:${url}`);
                    this.errorCount++
                    return;
                }
            })
        // }
    }
    async playAudio(url: string) {
        let audio = this.audioCache[url];
        if (audio) {
            Laya.SoundManager.setSoundVolume(this.getEffectVolume())
            Laya.SoundManager.playSound(url, 1)
        } else {
            let self = this;
            let resource = await ResourceManager.getInstance().loadResource(`${AudioManager.prefix}${url}`, "")
            if (resource) {
                self.music = url;
                self.audioCache[url] = resource;
                Laya.SoundManager.setSoundVolume(self.getEffectVolume())
                Laya.SoundManager.playSound(url, 1)
            }
        }
    }
    async playLocalAudio(url: string, idBlock: Function, endBlock: Function) {
        let self = this;
        let resource = await ResourceManager.getInstance().loadResource(url, "")
        if (resource) {
            self.play(resource, 1);
        }
        return true;
    }
    //音乐
    setMusicVolume(volume: number) {
        this.musicVolume = volume
        Laya.SoundManager.setMusicVolume(this.musicVolume)
        LocalUtil.setFloat(this.musicVolume, "music_volume");
    }
    //特效
    setEffectVolume(volume: number) {
        console.log("  //特效")
        this.effectVolume = Math.floor(volume * 100) / 100;
        Laya.SoundManager.setSoundVolume(volume)
        LocalUtil.setFloat(this.effectVolume, "effect_volume");
    }
    //语音
    setVoiceVolume(volume: number) {
        this.voiceVolume = Math.floor(volume * 100) / 100;
        Laya.SoundManager.setMusicVolume(volume)
        LocalUtil.setFloat(this.voiceVolume, "voice_volume");
    }
    getMusicVolume(): number {
        return this.musicVolume;
    }
    getEffectVolume(): number {
        return this.effectVolume;
    }
    getVoiceVolume(): number {
        return this.voiceVolume;
    }
    //音乐
    setTempMusicVolume(volume: number) {
        LocalUtil.setFloat(volume, "temp_music")
    }
    //特效
    setTempEffectVolume(volume: number) {
        LocalUtil.setFloat(volume, "temp_effects")
    }
    static setMusicMute(isStop: boolean) {
        if (isStop) {
            this.shared.tempMusicVolume = this.shared.musicVolume
            LocalUtil.setFloat(this.shared.tempMusicVolume, "temp_music")
        }
        else {
            console.log("temp_music_stop", LocalUtil.floatForKey("temp_music", 1))
            this.setMusicVolume(LocalUtil.floatForKey("temp_music", 1))
        }
        LocalUtil.setBool(isStop, "music_mute");
    }
    static setEffectMute(isStop: boolean) {
        if (isStop) {
            this.shared.tempEffectVolume = this.shared.effectVolume
            console.log("temp_effects_stop", this.shared.tempEffectVolume)
            LocalUtil.setFloat(this.shared.tempMusicVolume, "temp_effects")
        }
        else {
            console.log("temp_music_stop", LocalUtil.floatForKey("temp_effects", 1))
            this.setEffectVolume(LocalUtil.floatForKey("temp_music", 1))
        }
        LocalUtil.setBool(isStop, "effects_mute");
    }
    randomNumber(max: number, min: number) {
        return Math.round(Math.random() * (max - min))
    }
}
