import { IBetRecord } from "../../config/interface";
import UI_BetRecordRow from "../../fgui/windows/UI_BetRecordRow";
import UI_BetRecordWindow from "../../fgui/windows/UI_BetRecordWindow";
import UI_RecordBall from "../../fgui/windows/UI_RecordBall";
import I18nHelper from "../../helper/I18nHelper";
import { LayaHelper } from "../../helper/LayaHelper";
import { http } from "../../net/Http";

export class BetRecord {

    ui: UI_BetRecordWindow
    data: IBetRecord[] = []
    //刷新头部
    private _header: fairygui.GComponent;
    //刷新头部控制器
    private _header_c1: fgui.Controller;
    private _total: number = 0;
    //页码
    private _pageNum: number = 1;
    start_time: fairygui.GTextInput;
    end_time: fairygui.GTextInput;
    xhrList: XMLHttpRequest[] = [];
    constructor(ui: UI_BetRecordWindow) {

        this.ui = ui
        this.onUILoaded()
    }


    onUILoaded() {

        this._header = this.ui.m_list.scrollPane.header
        this._header_c1 = this._header.getController("c1");
        this._header.on(fgui.Events.SIZE_CHANGED, this, this.onSizeChanged);
        this.ui.m_list.on(fgui.Events.PULL_DOWN_RELEASE, this, this.onPullDownToRefresh);
        this.ui.m_list.on(fgui.Events.PULL_UP_RELEASE, this, this.onPullUpToRefresh);
        // this.ui.m_list.setVirtual()
        this.ui.m_list.itemRenderer = Laya.Handler.create(this, this.renderListItem, null, false);
        this.ui.m_closeButton.onClick(this, () => {
            this.ui.visible = false
        })

        this.ui.m_search.onClick(this, () => {
            this._pageNum = 1
            this.getList();
        })


        this.start_time = this.ui.m_start_time
        this.end_time = this.ui.m_end_time
        this.initTime()

        this.getList()

    }

    protected initTime() {
        let time = new Date();
        let timeText = time.toISOString().slice(0, 10);
        this.start_time.text = timeText
        this.end_time.text = timeText
        this.start_time._displayObject.type = Laya.Input.TYPE_DATE
        // this.start_time._displayObject.bgColor = "#525152"
        this.start_time._displayObject.align = "center"
        this.end_time._displayObject.type = Laya.Input.TYPE_DATE
        // this.end_time._displayObject.bgColor = "#525152"
        this.end_time._displayObject.align = "center"
    }


    showWindow() {
        this.getList()
        this.ui.visible = true
    }


    


    getList() {
        let xhr = http.selectBetRecords({
            // isSettled:0 | 1
            date: this.ui.m_start_time.text,
        }, (res: any) => {
            if (res.code == 200) {
                console.log("接口的数据",res.data.rows);

                this.data = this.mergeBetRecord(res.data.rows)
                // this._total = res.data.total
                this.ui.m_list.numItems = this.data.length
                this.ui.m_allBet.setVar("allBet", res.data.totalBet)
                this.ui.m_allWin.setVar("allWin", res.data.totalWinLose)
                console.log("投注记录:", this.data);

            }
        })
        this.xhrList.push(xhr)
    }


    mergeBetRecord(oldData: IBetRecord[]): IBetRecord[] {
        let data = LayaHelper.deepCopy(oldData)

        return data.reduce((acc: IBetRecord[], current: IBetRecord) => {
            const existing = acc.find((item) => {
                return item.betCode === current.betCode && item.nperCode === current.nperCode
            });
            if (existing) {
                existing.amount += current.amount;
                existing.winLoss += current.winLoss;
            } else {
                acc.push({ ...current });
            }
            return acc;
        }, []);
    }


    private renderListItem(index: number, item: UI_BetRecordRow): void {
        let obj = this.data[index]
        item.m_code.text = obj?.nperCode
        item.m_amount.text = obj.amount.toFixed(2)
        item.m_betCode.text = I18nHelper.t(obj.pairCodeb) + '/' + I18nHelper.t(obj.pairCodee)

        if ( obj.winLoss > 0) {
            item.m_winLoss.color = '#529a3b'
        } else if (obj.winLoss < 0) {
            item.m_winLoss.color = '#ff0000'
        } else {
            item.m_winLoss.color = '#ffffff'
        }
        item.m_winLoss.text = obj.winLoss.toFixed(2)

        if (obj.gameResult) {
            item.m_no.visible = false
            item.m_ballList.visible = true
            if (typeof obj.gameResult == "string") {
                obj.gameResult = JSON.parse(obj.gameResult)
            }
            item.m_ballList.itemRenderer = Laya.Handler.create(this, this.renderBallListItem, [obj.gameResult], false);
            item.m_ballList.numItems = obj.gameResult.length
        } else {
            item.m_ballList.visible = false
            item.m_no.visible = true
        }
    }

    private renderBallListItem(data: number[], index: number, item: UI_RecordBall): void {
        item.width = (item.parent.width - 20) / data.length
        item.m_ball.url = fgui.UIPackage.getItemURL("game", "ball" + data[index]);
    }



    //下拉加载
    private async onPullDownToRefresh(evt: Laya.Event): Promise<void> {
        console.log('准备');
        // console.log(header);

        if (this._header_c1.selectedIndex == 1) {
            this._header_c1.selectedIndex = 2;
            this.ui.m_list.scrollPane.lockHeader(this._header.sourceHeight);
            this._pageNum = 1
            await this.getList();
            if (this.ui.isDisposed) {
                return;
            }
            this._header_c1.selectedIndex = 0;
            this.ui.m_list.scrollPane.lockHeader(0);
        }



    }

    //监听头部刷新控制器
    onSizeChanged(): void {
        if (this._header_c1.selectedIndex == 2)
            return;
        if (this._header.height > this._header.sourceHeight)
            this._header_c1.selectedIndex = 1;
        else
            this._header_c1.selectedIndex = 0;
    }

    //上拉加载
    private async onPullUpToRefresh(evt: Laya.Event): Promise<void> {
        var footer: fgui.GComponent = this.ui.m_list.scrollPane.footer.asCom;
        footer.getController("c1").selectedIndex = 1;
        this.ui.m_list.scrollPane.lockFooter(footer.sourceHeight);

        if (this.data.length < this._total) {
            this._pageNum++
            //Simulate a async resquest
            var result = await this.getList();
            if (this.ui.isDisposed)
                return;

            //Refresh completed
            footer.getController("c1").selectedIndex = 0;
            this.ui.m_list.scrollPane.lockFooter(0);
        } else {
            this.ui.m_list.scrollPane.lockFooter(0);
            return;
        }

    }

    destroy() {
        this.xhrList.forEach((xhr) => {
            xhr.abort()
        })
    }
}