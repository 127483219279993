/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_ImageActive extends fgui.GComponent {

	public m_image:fgui.GLoader;
	public static URL:string = "ui://6stwwrff8r18m";

	public static createInstance():UI_ImageActive {
		return <UI_ImageActive>(fgui.UIPackage.createObject("active", "ImageActive"));
	}

	protected onConstruct():void {
		this.m_image = <fgui.GLoader>(this.getChild("image"));
	}
}