/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_EightNum extends fgui.GComponent {

	public m_r1:fgui.GButton;
	public m_r2:fgui.GButton;
	public m_r3:fgui.GButton;
	public m_r4:fgui.GButton;
	public m_r5:fgui.GButton;
	public m_r6:fgui.GButton;
	public m_r7:fgui.GButton;
	public m_r8:fgui.GButton;
	public static URL:string = "ui://61ic9kfkbe8tvz9y";

	public static createInstance():UI_EightNum {
		return <UI_EightNum>(fgui.UIPackage.createObject("game", "EightNum"));
	}

	protected onConstruct():void {
		this.m_r1 = <fgui.GButton>(this.getChild("r1"));
		this.m_r2 = <fgui.GButton>(this.getChild("r2"));
		this.m_r3 = <fgui.GButton>(this.getChild("r3"));
		this.m_r4 = <fgui.GButton>(this.getChild("r4"));
		this.m_r5 = <fgui.GButton>(this.getChild("r5"));
		this.m_r6 = <fgui.GButton>(this.getChild("r6"));
		this.m_r7 = <fgui.GButton>(this.getChild("r7"));
		this.m_r8 = <fgui.GButton>(this.getChild("r8"));
	}
}