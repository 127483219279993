/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_backwater extends fgui.GComponent {

	public m_c1:fgui.Controller;
	public m_back:fgui.GButton;
	public m_search:fgui.GButton;
	public m_start_time:fgui.GTextInput;
	public m_end_time:fgui.GTextInput;
	public m_btn_list:fgui.GList;
	public m_top:fgui.GGroup;
	public m_title:fgui.GGroup;
	public m_list:fgui.GList;
	public m_help_btn:fgui.GButton;
	public m_yes:fgui.GButton;
	public m_no:fgui.GButton;
	public static URL:string = "ui://zx95u0njix0t1";

	public static createInstance():UI_backwater {
		return <UI_backwater>(fgui.UIPackage.createObject("backwater", "backwater"));
	}

	protected onConstruct():void {
		this.m_c1 = this.getController("c1");
		this.m_back = <fgui.GButton>(this.getChild("back"));
		this.m_search = <fgui.GButton>(this.getChild("search"));
		this.m_start_time = <fgui.GTextInput>(this.getChild("start_time"));
		this.m_end_time = <fgui.GTextInput>(this.getChild("end_time"));
		this.m_btn_list = <fgui.GList>(this.getChild("btn_list"));
		this.m_top = <fgui.GGroup>(this.getChild("top"));
		this.m_title = <fgui.GGroup>(this.getChild("title"));
		this.m_list = <fgui.GList>(this.getChild("list"));
		this.m_help_btn = <fgui.GButton>(this.getChild("help_btn"));
		this.m_yes = <fgui.GButton>(this.getChild("yes"));
		this.m_no = <fgui.GButton>(this.getChild("no"));
	}
}