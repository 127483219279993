import { E_ScreenMode } from "../../config/Enum";
import ConfigManager from "../../helper/ConfigManager"
import { http } from "../../net/Http"
import { Router } from "../../util/router"
import { IBetRecord } from "../../config/interface"
import { UIHelper } from "../../helper/UIHelper";
import UI_Betting_order from "../../fgui/betting_order/UI_Betting_order";
import { UIBase } from "../scene/UIBase";
import { CustomerWindow } from "../windows/CustomerWindow";
import I18nHelper from "../../helper/I18nHelper";
import { LayaHelper } from "../../helper/LayaHelper";
import UI_betting_order_settled from "../../fgui/betting_order/UI_betting_order_settled";
export class BettingOrder extends UIBase {

    private uiBetting_order: UI_Betting_order
    data: IBetRecord[] = []
    // private start_time: fgui.GTextInput
    // private end_time: fgui.GTextInput
    //刷新头部
    private _header: fairygui.GComponent;
    //刷新头部控制器
    private _header_c1: fgui.Controller;
    private _total: number = 0;
    //页码
    private _pageNum: number = 1;
    //数据类型
    private settledType: number = 0;

    private start_time: fgui.GTextInput
    private end_time: fgui.GTextInput

    xhrList: XMLHttpRequest[] = []
    private timeData: {
        beginTime: string,
        endTime: string
    }
    constructor(or: E_ScreenMode, type: number) {
        super(or == E_ScreenMode.horizontal ? UI_Betting_order.createInstance() : UI_Betting_order.createInstance());
        this.settledType = type
        this.onUILoaded()
    }

    onUILoaded() {
        this.uiBetting_order = this.UI_Item as UI_Betting_order
        fgui.GRoot.inst.addChild(this.uiBetting_order)

        if (this.settledType == 1) {
            this.uiBetting_order.m_settled.visible = true
            this.uiBetting_order.m_unsettled.visible = false
        }else{
            this.uiBetting_order.m_settled.visible = false
            this.uiBetting_order.m_unsettled.visible = true
        }

        // //刷新控制器
        this._header = this.uiBetting_order.m_list.scrollPane.header
        this._header_c1 = this._header.getController("c1");
        this._header.on(fgui.Events.SIZE_CHANGED, this, this.onSizeChanged);
        this.uiBetting_order.m_list.on(fgui.Events.PULL_DOWN_RELEASE, this, this.onPullDownToRefresh);
        this.uiBetting_order.m_list.on(fgui.Events.PULL_UP_RELEASE, this, this.onPullUpToRefresh);
        // //刷新控制器

        // this.start_time = this.uibackwater.m_start_time
        // this.end_time = this.uibackwater.m_end_time
        // this.initTime()
        this.uiBetting_order.m_back.onClick(this, this.back)
        this.uiBetting_order.m_title.text = this.settledType == 1 ? I18nHelper.t('settledBets') : I18nHelper.t('unsettledBets')
        this.start_time = this.uiBetting_order.m_start_time
        this.end_time = this.uiBetting_order.m_end_time
        this.initTime()

        this.getList();
        this.uiBetting_order.m_back.onClick(this, this.back)
        this.uiBetting_order.m_list.itemProvider = Laya.Handler.create(this, this.getListItemResource, null, false);

        this.uiBetting_order.m_list.itemRenderer = Laya.Handler.create(this, this.renderListItem, null, false)
        //搜索
        this.uiBetting_order.m_search.onClick(this, () => {
            this.timeData = {
                "beginTime": this.start_time.text,
                "endTime": this.end_time.text,
            }
            this._pageNum = 1
            this.getList();
            this.uiBetting_order.m_btn_list.selectNone()
        })
        this.uiBetting_order.m_btn_list.getChild('today').onClick(this, () => {
            console.log('今天', LayaHelper.getDays())
            this.timeData = {
                "beginTime": LayaHelper.getDays().startTime,
                "endTime": LayaHelper.getDays().endTime
            }
            this._pageNum = 1
            this.start_time.text = this.timeData.beginTime
            this.end_time.text = this.timeData.endTime
            this.getList();

        })
        this.uiBetting_order.m_btn_list.getChild('yesyerday').onClick(this, () => {
            console.log('昨天', LayaHelper.getLastDays())
            this.timeData = {
                "beginTime": LayaHelper.getLastDays().startTime,
                "endTime": LayaHelper.getLastDays().endTime
            }
            this._pageNum = 1
            this.start_time.text = this.timeData.beginTime
            this.end_time.text = this.timeData.endTime
            this.getList();
        })
        this.uiBetting_order.m_btn_list.getChild('seven_day').onClick(this, () => {
            console.log('近7天', LayaHelper.getCurrWeekDays())
            this.timeData = {
                "beginTime": LayaHelper.getCurrWeekDays().startTime,
                "endTime": LayaHelper.getCurrWeekDays().endTime
            }
            this._pageNum = 1
            this.start_time.text = this.timeData.beginTime
            this.end_time.text = this.timeData.endTime
            this.getList();
        })
        this.uiBetting_order.m_btn_list.getChild('month_day').onClick(this, () => {
            console.log('近1个月', LayaHelper.getCurrMonthDays())
            this.timeData = {
                "beginTime": LayaHelper.getCurrMonthDays().startTime,
                "endTime": LayaHelper.getCurrMonthDays().endTime
            }
            this._pageNum = 1
            this.start_time.text = this.timeData.beginTime
            this.end_time.text = this.timeData.endTime
            this.getList();
        })  //客服列表
        this.uiBetting_order.m_help_btn.onClick(this, this.onCustomerClick)
    }
    //客服列表
    customerWindow: CustomerWindow = null
    onCustomerClick() {
        if (!ConfigManager.getInstance().Customer) {
            UIHelper.showMsg(I18nHelper.t('noService'))
            return
        }
        if (ConfigManager.getInstance().Customer.length > 0) {
            if (!this.customerWindow) {
                this.customerWindow = new CustomerWindow(ConfigManager.getInstance().Customer)
            }
            UIHelper.showWindow(this.customerWindow)
        }
        else {
            UIHelper.showMsg(I18nHelper.t('noService'))
        }
    }

    private getListItemResource(index: number): string {
        if (this.settledType == 1) {
            return "ui://n8dzzxx0ywm74";
        }else{
            return "ui://n8dzzxx0n5qtc";
        }
    }
    private renderListItem(index: number, item: UI_betting_order_settled): void {
        let obj = this.data[index]
        item.m_nperCode.text = obj?.nperCode
        item.m_amount.text = obj.amount.toFixed(2)

        item.m_betCode.text = I18nHelper.t(obj.pairCodeb) + '/' + I18nHelper.t(obj.pairCodee)

        item.m_betTime.text = obj.betTime
        if (obj.gameResult) {
            item.m_isWin.text = obj.isWin == 0 ? I18nHelper.t('lose') : I18nHelper.t('win')
            obj.gameResult = obj.gameResult.replace("[", "")
            obj.gameResult = obj.gameResult.replace("]", "")
            item.m_gameResult.text = obj.gameResult
        }else{
            item.m_gameResult.text = I18nHelper.t('prizeDraw')
        }
        item.m_odds.text = obj.odds.toString()

        if ( obj.winLoss > 0) {
            item.m_winLoss.color = '#529a3b'
        } else if (obj.winLoss < 0) {
            item.m_winLoss.color = '#ff0000'
        } else {
            item.m_winLoss.color = '#ffffff'
        }
        item.m_winLoss.text = obj.winLoss.toFixed(2)

     
        let text = ""
        switch (obj.status) {
            case 0:
                text = I18nHelper.t('unsettled')
                break;
            case 1:
                text = I18nHelper.t('settled')
                break;
            case 2:
                text = I18nHelper.t('canceled')
                break;
            default:
                break;
        }
        item.m_status.text = text


    }

    setSettledType(num: number) {
        this.settledType = num;
    }

    protected initTime() {
        let time = new Date();
        let timeText = time.toISOString().slice(0, 10);
        this.start_time.text = timeText
        this.end_time.text = timeText
        this.start_time._displayObject.type = Laya.Input.TYPE_DATE
        // this.start_time._displayObject.bgColor = "#525152"
        this.start_time._displayObject.align = "center"
        this.end_time._displayObject.type = Laya.Input.TYPE_DATE
        // this.end_time._displayObject.bgColor = "#525152"
        this.end_time._displayObject.align = "center"
        this.timeData = {
            "beginTime": LayaHelper.getDays().startTime,
            "endTime": LayaHelper.getDays().endTime
        }
    }
    //获取列表
    getList() {
        return new Promise((resolve, reject) => {
            console.log('this.settledType,', this.settledType,)
            let xhr = http.selectBetRecords({
                date: this.timeData.beginTime,
            }, (data: any) => {
                if (data.code == 200) {
                    this._total = data.total
                    console.log(data)
                    //settledType 已结算0 未结算1  status 0未结算 status 1已结算
                    let tmpRows: any[] = [];
                    data.data.rows && data.data.rows.forEach((e: any) => {
                        if (this.settledType == 1) {
                            if (e.status != 0) {
                                tmpRows.push(e);
                            }
                        } else {
                            if (e.status == 0) {
                                tmpRows.push(e);
                            }
                        }

                    })

                    this.data =this.mergeBetRecord(tmpRows) 
                    console.log(this.data)
                    this.uiBetting_order.m_list.numItems = this.data.length
                    console.log("投注记录:", data);
                    resolve(true)
                } else {
                    reject(false)
                }
            })
            this.xhrList.push(xhr)
        });
    }

    mergeBetRecord(oldData: IBetRecord[]): IBetRecord[] {
        let data = LayaHelper.deepCopy(oldData)

        return data.reduce((acc: IBetRecord[], current: IBetRecord) => {
            const existing = acc.find((item) => {
                return item.betCode === current.betCode && item.nperCode === current.nperCode
            });
            if (existing) {
                existing.amount += current.amount;
                existing.winLoss += current.winLoss;
            } else {
                acc.push({ ...current });
            }
            return acc;
        }, []);
    }

    // protected initTime() {
    //     let time = new Date();
    //     let timeText = time.toISOString().slice(0, 10);
    //     this.start_time.text = timeText
    //     this.end_time.text = timeText
    //     this.start_time._displayObject.type = Laya.Input.TYPE_DATE
    //     this.start_time._displayObject.bgColor = "#525152"
    //     this.start_time._displayObject.align = "center"
    //     this.end_time._displayObject.type = Laya.Input.TYPE_DATE
    //     this.end_time._displayObject.bgColor = "#525152"
    //     this.end_time._displayObject.align = "center"
    //     this.getList()
    // }


    //下拉加载
    private async onPullDownToRefresh(evt: Laya.Event): Promise<void> {
        console.log('准备');
        // console.log(header);

        if (this._header_c1.selectedIndex == 1) {
            this._header_c1.selectedIndex = 2;
            this.uiBetting_order.m_list.scrollPane.lockHeader(this._header.sourceHeight);
            this._pageNum = 1
            Laya.timer.once(1000, this, async () => {
                var result = await this.getList();
                if (this.uiBetting_order.isDisposed) {
                    return;
                }
                this._header_c1.selectedIndex = 0;
                this.uiBetting_order.m_list.scrollPane.lockHeader(0);
            });
        }



    }

    //监听头部刷新控制器
    onSizeChanged(): void {
        if (this._header_c1.selectedIndex == 2)
            return;
        if (this._header.height > this._header.sourceHeight)
            this._header_c1.selectedIndex = 1;
        else
            this._header_c1.selectedIndex = 0;
    }

    //上拉加载
    private async onPullUpToRefresh(evt: Laya.Event): Promise<void> {
        var footer: fgui.GComponent = this.uiBetting_order.m_list.scrollPane.footer.asCom;

        footer.getController("c1").selectedIndex = 1;
        this.uiBetting_order.m_list.scrollPane.lockFooter(footer.sourceHeight);
        console.log(this.data.length);
        console.log(this._total);

        if (this.data.length < this._total) {
            this._pageNum++
            //Simulate a async resquest
            var result = await this.getList();
            if (this.uiBetting_order.isDisposed)
                return;

            //Refresh completed
            footer.getController("c1").selectedIndex = 0;
            this.uiBetting_order.m_list.scrollPane.lockFooter(0);
        } else {
            this.uiBetting_order.m_list.scrollPane.lockFooter(0);
            return;
        }

    }




    back() {
        Laya.Scene.open(Router.Me, true, null, Laya.Handler.create(this, () => {
        }))
    }


    destroy() {
        this.xhrList.forEach((xhr) => {
            console.log("取消", xhr);

            xhr.abort()
        })
        this.uiBetting_order.dispose()
    }
}