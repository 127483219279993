/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_Button5 extends fgui.GButton {

	public m_time:fgui.GTextField;
	public static URL:string = "ui://d7esdcvti3x2zb0";

	public static createInstance():UI_Button5 {
		return <UI_Button5>(fgui.UIPackage.createObject("windows", "Button5"));
	}

	protected onConstruct():void {
		this.m_time = <fgui.GTextField>(this.getChild("time"));
	}
}