import { IMGConfig, IPConifg } from "../config/NetConfig";
import { http } from "../net/Http";


export default class GlobalParameterManager {
    //对象
    private static instance: GlobalParameterManager;


    private xhrList: XMLHttpRequest[] = []

    private maimLogoImgUrl: string = "";
    //单利模式
    public static getInstance(): GlobalParameterManager {
        if (this.instance == null) {
            this.instance = new GlobalParameterManager();
        }
        return this.instance;
    }
    initData(callBack: Function) {
        let xhr = http.getGlobalParameter(IMGConfig.mianLogoImgUrl, async (res: any) => {
            if (res.code == 200) {
                console.log("?????", res.data)
                let logodata =  (res.data as any[]).find((item)=>{
                    return item.key == IMGConfig.mianLogoImgUrl
                })

                if (logodata) {
                    this.maimLogoImgUrl = logodata.content
                }
                console.log("?????", this.maimLogoImgUrl)
                callBack();
                // 开始加载远程图片资源
                //    await Laya.loader.load(this.maimLogoImgUrl, Laya.Handler.create(this, this.onLoadComplete), Laya.Handler.create(this, this.onLoadProgress));

            } else {
                this.maimLogoImgUrl = "";
                callBack();
            }
        })

    }

    // 加载完成的回调函数
    onLoadComplete(res: any) {
        if (res != null || res.length != 0) {

            console.log("加载成功", res)
        } else {
            console.log("加载失败", res)
        }

    }

    // 加载进度的回调函数
    onLoadProgress(progress: any) {
        console.log("Loading: " + progress + "%");
    }

    getLogoUrl(): string {
        return this.maimLogoImgUrl;
    }
    //构造函数
    constructor() {
    }

}