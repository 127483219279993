/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_balance_row extends fgui.GComponent {

	public m_logType:fgui.GTextField;
	public m_oldBalance:fgui.GTextField;
	public m_remark:fgui.GTextField;
	public m_time:fgui.GTextField;
	public m_newBalance:fgui.GTextField;
	public m_changeBalance:fgui.GTextField;
	public static URL:string = "ui://y95vh6ppmi8lb";

	public static createInstance():UI_balance_row {
		return <UI_balance_row>(fgui.UIPackage.createObject("balance_record", "balance_row"));
	}

	protected onConstruct():void {
		this.m_logType = <fgui.GTextField>(this.getChild("logType"));
		this.m_oldBalance = <fgui.GTextField>(this.getChild("oldBalance"));
		this.m_remark = <fgui.GTextField>(this.getChild("remark"));
		this.m_time = <fgui.GTextField>(this.getChild("time"));
		this.m_newBalance = <fgui.GTextField>(this.getChild("newBalance"));
		this.m_changeBalance = <fgui.GTextField>(this.getChild("changeBalance"));
	}
}