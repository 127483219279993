/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_marquee extends fgui.GComponent {

	public m_marquee_text:fgui.GTextField;
	public m_marequee_list:fgui.GList;
	public static URL:string = "ui://bi3ljjpyfbdez7f";

	public static createInstance():UI_marquee {
		return <UI_marquee>(fgui.UIPackage.createObject("home", "marquee"));
	}

	protected onConstruct():void {
		this.m_marquee_text = <fgui.GTextField>(this.getChild("marquee_text"));
		this.m_marequee_list = <fgui.GList>(this.getChild("marequee_list"));
	}
}