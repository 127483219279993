/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_GameOption from "./UI_GameOption";
import UI_video1_btn from "./UI_video1_btn";
import UI_Rank from "./UI_Rank";
import UI_rank_btn from "./UI_rank_btn";
import UI_gameDes from "./UI_gameDes";
import UI_EightNum from "./UI_EightNum";
import UI_TenNum from "./UI_TenNum";
import UI_TM10Area from "./UI_TM10Area";
import UI_FourNum from "./UI_FourNum";
import UI_FiveNum from "./UI_FiveNum";
import UI_chips_btn from "./UI_chips_btn";
import UI_chips_list from "./UI_chips_list";
import UI_TM8Area from "./UI_TM8Area";
import UI_Bet from "./UI_Bet";
import UI_LHArea from "./UI_LHArea";
import UI_TMDXDSArea from "./UI_TMDXDSArea";
import UI_GYHBet from "./UI_GYHBet";
import UI_bet_btn2 from "./UI_bet_btn2";
import UI_prize from "./UI_prize";
import UI_ball from "./UI_ball";
import UI_l_btn from "./UI_l_btn";
import UI_h_btn from "./UI_h_btn";
import UI_b_btn from "./UI_b_btn";
import UI_x_btn from "./UI_x_btn";
import UI_video_pos from "./UI_video_pos";
import UI_bet_chip from "./UI_bet_chip";
import UI_bet_btn from "./UI_bet_btn";
import UI_bet_info from "./UI_bet_info";
import UI_Game from "./UI_Game";

export default class gameBinder {
	public static bindAll():void {
		fgui.UIObjectFactory.setExtension(UI_GameOption.URL, UI_GameOption);
		fgui.UIObjectFactory.setExtension(UI_video1_btn.URL, UI_video1_btn);
		fgui.UIObjectFactory.setExtension(UI_Rank.URL, UI_Rank);
		fgui.UIObjectFactory.setExtension(UI_rank_btn.URL, UI_rank_btn);
		fgui.UIObjectFactory.setExtension(UI_gameDes.URL, UI_gameDes);
		fgui.UIObjectFactory.setExtension(UI_EightNum.URL, UI_EightNum);
		fgui.UIObjectFactory.setExtension(UI_TenNum.URL, UI_TenNum);
		fgui.UIObjectFactory.setExtension(UI_TM10Area.URL, UI_TM10Area);
		fgui.UIObjectFactory.setExtension(UI_FourNum.URL, UI_FourNum);
		fgui.UIObjectFactory.setExtension(UI_FiveNum.URL, UI_FiveNum);
		fgui.UIObjectFactory.setExtension(UI_chips_btn.URL, UI_chips_btn);
		fgui.UIObjectFactory.setExtension(UI_chips_list.URL, UI_chips_list);
		fgui.UIObjectFactory.setExtension(UI_TM8Area.URL, UI_TM8Area);
		fgui.UIObjectFactory.setExtension(UI_Bet.URL, UI_Bet);
		fgui.UIObjectFactory.setExtension(UI_LHArea.URL, UI_LHArea);
		fgui.UIObjectFactory.setExtension(UI_TMDXDSArea.URL, UI_TMDXDSArea);
		fgui.UIObjectFactory.setExtension(UI_GYHBet.URL, UI_GYHBet);
		fgui.UIObjectFactory.setExtension(UI_bet_btn2.URL, UI_bet_btn2);
		fgui.UIObjectFactory.setExtension(UI_prize.URL, UI_prize);
		fgui.UIObjectFactory.setExtension(UI_ball.URL, UI_ball);
		fgui.UIObjectFactory.setExtension(UI_l_btn.URL, UI_l_btn);
		fgui.UIObjectFactory.setExtension(UI_h_btn.URL, UI_h_btn);
		fgui.UIObjectFactory.setExtension(UI_b_btn.URL, UI_b_btn);
		fgui.UIObjectFactory.setExtension(UI_x_btn.URL, UI_x_btn);
		fgui.UIObjectFactory.setExtension(UI_video_pos.URL, UI_video_pos);
		fgui.UIObjectFactory.setExtension(UI_bet_chip.URL, UI_bet_chip);
		fgui.UIObjectFactory.setExtension(UI_bet_btn.URL, UI_bet_btn);
		fgui.UIObjectFactory.setExtension(UI_bet_info.URL, UI_bet_info);
		fgui.UIObjectFactory.setExtension(UI_Game.URL, UI_Game);
	}
}