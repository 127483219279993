import { E_ScreenMode } from "../../config/Enum";
import { IPConifg } from "../../config/NetConfig";
import UI_ButtomIcon from "../../fgui/common/UI_ButtomIcon";
import UI_Me from "../../fgui/me/UI_Me";
import ConfigManager from "../../helper/ConfigManager";
import HttpHelper from "../../helper/HttpHelper";
import I18nHelper from "../../helper/I18nHelper";
import { UIHelper } from "../../helper/UIHelper";
import PlayerDataManager from "../../model/PlayerDataManager";
import { Router } from "../../util/router";
import { BettingOrderScene } from "../scene/BettingOrderScence";
import { UIBase } from "../scene/UIBase";
import { BindEmailWindow } from "../windows/BindEmailWindow";
import { ConfirmWindow } from "../windows/ConfirmWindow";
import { CustomerWindow } from "../windows/CustomerWindow";
export class Me extends UIBase {
    private reloadNums: number = 0
    private or: E_ScreenMode
    private uime: UI_Me
    constructor(or: E_ScreenMode) {
        super(or == E_ScreenMode.horizontal ? UI_Me.createInstance() : UI_Me.createInstance());
        this.onUILoaded()
    }
    onUILoaded() {
        this.uime = this.UI_Item as UI_Me
        fgui.GRoot.inst.addChild(this.uime);
        this.uime.m_name.text = PlayerDataManager.getInstance().getPlayerInfo().name
        this.uime.m_account.text = 'Id:' + PlayerDataManager.getInstance().getPlayerInfo().id
        // this.uime.m_balance.text = PlayerDataManager.getInstance().getBalance().toString()

        // this.uime.m_refsh_btn.onClick(this, HttpHelper.getInstance().getUserBalance)


        if (PlayerDataManager.getInstance().getPlayerInfo().isThird == 1) {
            this.uime.m_menuList.m_logout.visible = false
        }

        //刷新余额
        // this.uime.m_refsh_btn.onClick(this, () => {
        //     this.uime.m_refsh_btn.m_t0.play()
        //     HttpHelper.getInstance().getUserBalance()
        //     Laya.timer.once(2000, this, async () => {
        //         this.uime.m_refsh_btn.m_t0.stop()
        //     });
        // })


        this.createButtom();

        // this.uime.m_cash_btn.onClick(this, () => {
        //     console.log("打印了?");
        //     Laya.Scene.open(Router.Charge, true, null, Laya.Handler.create(this, () => {
        //     }))
        // });
        // this.uime.m_avatar.getChild('avatarBtn').onClick(this,this.clickAvator);
        this.onClickMenu();
        (this.uime.m_avatar.getChild("avatar") as fgui.GLoader).url =  PlayerDataManager.getInstance().getPlayerInfo().avatar


        this.uime.m_updateUserInfo.onClick(this,()=>{
            console.log("嗲你了修改头像和信息");
            Laya.Scene.open(Router.UpdateUser, true)
        })
        // this.createAvator()

        this.uime.m_copyId.onClick(this,this.onCopyId)
    }

   // 复制并打开如微信之类
   protected onCopyId(): void {

    let str = PlayerDataManager.getInstance().getPlayerInfo().id



    if (navigator.clipboard) {
        navigator.clipboard.writeText(str)
    } else {
        const input = document.createElement('textarea')
        input.value = str
        document.body.appendChild(input)
        input.select()
        document.execCommand('copy')
        document.body.removeChild(input)
    }
    UIHelper.showMsg(I18nHelper.t('copySuccess'))


}

    onClickMenu() {



        //跳转未结算注单
        this.uime.m_menuList.m_unsettled.onClick(this, () => {
            if (PlayerDataManager.getInstance().getPlayerInfo().isTour == 2) {
                UIHelper.showWindow(new ConfirmWindow(I18nHelper.t('tourTip'), I18nHelper.t('tip'), () => {
                    HttpHelper.getInstance().logOut()
                }, () => { }))
                return
            }

            Laya.Scene.open(Router.BettingOrder, true, { "type": "1" }, Laya.Handler.create(this, (res: Laya.Scene) => {

                let bettingOrderScene: BettingOrderScene = res.getComponent(BettingOrderScene)
                bettingOrderScene.onOpened({ "type": 1 })
            }))
        })
        //跳转已结算注单
        this.uime.m_menuList.m_settled.onClick(this, () => {
            if (PlayerDataManager.getInstance().getPlayerInfo().isTour == 2) {
                UIHelper.showWindow(new ConfirmWindow(I18nHelper.t('tourTip'), I18nHelper.t('tip'), () => {
                    HttpHelper.getInstance().logOut()
                }, () => { }))
                return
            }

            Laya.Scene.open(Router.BettingOrder, true, { "type": "0" }, Laya.Handler.create(this, (res: Laya.Scene) => {

                let bettingOrderScene: BettingOrderScene = res.getComponent(BettingOrderScene)
                bettingOrderScene.onOpened({ "type": 0 })
            }))
        })

        //跳转反水记录
        this.uime.m_menuList.m_backwater.onClick(this, () => {
            if (PlayerDataManager.getInstance().getPlayerInfo().isTour == 2) {
                UIHelper.showWindow(new ConfirmWindow(I18nHelper.t('tourTip'), I18nHelper.t('tip'), () => {
                    HttpHelper.getInstance().logOut()
                }, () => { }))
                return
            }

            Laya.Scene.open(Router.Backwater, true, null, Laya.Handler.create(this, () => {
            }))
        })

        //跳转余额记录
        this.uime.m_menuList.m_balance.onClick(this, () => {
            if (PlayerDataManager.getInstance().getPlayerInfo().isTour == 2) {
                UIHelper.showWindow(new ConfirmWindow(I18nHelper.t('tourTip'), I18nHelper.t('tip'), () => {
                    HttpHelper.getInstance().logOut()
                }, () => { }))
                return
            }

            Laya.Scene.open(Router.BalanceRecord, true, null, Laya.Handler.create(this, () => {
            }))
        })
        //跳转修改密码
        this.uime.m_menuList.m_modifypassword.onClick(this, () => {
            
            if (PlayerDataManager.getInstance().getPlayerInfo().isTour == 2) {
                UIHelper.showWindow(new ConfirmWindow(I18nHelper.t('tourTip'), I18nHelper.t('tip'), () => {
                    HttpHelper.getInstance().logOut()
                }, () => { }))
                return
            }
            Laya.Scene.open(Router.Modify, true, null, Laya.Handler.create(this, () => {
            }))
        })
        //跳转修改支付密码
        this.uime.m_menuList.m_pay_password.onClick(this, () => {
            if (PlayerDataManager.getInstance().getPlayerInfo().isTour == 2) {
                UIHelper.showWindow(new ConfirmWindow(I18nHelper.t('tourTip'), I18nHelper.t('tip'), () => {
                    HttpHelper.getInstance().logOut()
                }, () => { }))
                return
            }
            Laya.Scene.open(Router.PayModify, true, null, Laya.Handler.create(this, () => {
            }))
        })


        //跳转代理中心
        this.uime.m_menuList.m_agent_btn.onClick(this, () => {
            if (PlayerDataManager.getInstance().getPlayerInfo().isTour == 2) {
                UIHelper.showWindow(new ConfirmWindow(I18nHelper.t('tourTip'), I18nHelper.t('tip'), () => {
                    HttpHelper.getInstance().logOut()
                }, () => { }))
                return
            }
            Laya.Scene.open(Router.Agent, true, null, Laya.Handler.create(this, () => {
            }))
        })
        //客服列表
        this.uime.m_menuList.m_customer_btn.onClick(this, this.onCustomerClick)
        //跳转语言
        this.uime.m_menuList.m_lang_btn.onClick(this, () => {
            Laya.Scene.open(Router.Lang, true, null, Laya.Handler.create(this, () => {
            }))
        })
        //退出登录
        this.uime.m_menuList.m_logout.onClick(this, () => {
            HttpHelper.getInstance().logOut()
        })
    }

       bottomTimer:number = null
    createButtom() {
        let m_buttom = this.uime.m_bottom as UI_ButtomIcon

        m_buttom.m_me_icon_active.visible = true
        m_buttom.m_me_icon_bg.visible = false
        m_buttom.m_me_t.color = "#099B36"

        m_buttom.m_me_ani.play()

        m_buttom.m_home.onClick(this, () => {
            Laya.Scene.open(Router.Home, true, null, Laya.Handler.create(this, () => {
            }))
        })

        m_buttom.m_active.onClick(this, () => {
            Laya.Scene.open(Router.Active, true, null, Laya.Handler.create(this, () => {
            }))
        })

        m_buttom.m_wallet.onClick(this, () => {
            Laya.Scene.open(Router.Wallet, true, null, Laya.Handler.create(this, () => {
            }))
        })

        // m_buttom.m_my.onClick(this, () => {
        //     Laya.Scene.open(Router.Me, true, null, Laya.Handler.create(this, () => {
        //     }))
        // })
    }
    
 
    

    //客服列表
    customerWindow: CustomerWindow = null
    onCustomerClick() {
        if (!ConfigManager.getInstance().Customer) {
            UIHelper.showMsg(I18nHelper.t('noService'))
            return
        }
        if (ConfigManager.getInstance().Customer.length > 0) {
            if (!this.customerWindow) {
                this.customerWindow = new CustomerWindow(ConfigManager.getInstance().Customer)
            }
            UIHelper.showWindow(this.customerWindow)
        }
        else {
            UIHelper.showMsg(I18nHelper.t('noService'))
        }
    }




    destroy() {
        clearTimeout(this.bottomTimer)
        this.uime.dispose();
    }
}