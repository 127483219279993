import { E_ScreenMode } from "../../../config/Enum"
import { IBankCard } from "../../../config/interface"
import UI_bind_wallet from "../../../fgui/bind_wallet/UI_bind_wallet"
import ConfigManager from "../../../helper/ConfigManager"
import I18nHelper from "../../../helper/I18nHelper"
import { UIHelper } from "../../../helper/UIHelper"
import { http } from "../../../net/Http"
import { Router } from "../../../util/router"
import { UIBase } from "../../scene/UIBase"
import { CustomerWindow } from "../../windows/CustomerWindow"

export class BindWallet extends UIBase {
    private reloadNums: number = 0
    private or: E_ScreenMode
    private uiBindCard: UI_bind_wallet

    private data: IBankCard = null
    private bindData: {
        name: string,
        bankCode: string
    } = null
    constructor(or: E_ScreenMode, data: IBankCard, bindData: { name: string; bankCode: string }) {
        super(or == E_ScreenMode.horizontal ? UI_bind_wallet.createInstance() : UI_bind_wallet.createInstance());
        this.data = data
        this.bindData = bindData
        this.onUILoaded()
    }
    onUILoaded() {
        this.uiBindCard = this.UI_Item as UI_bind_wallet
        fgui.GRoot.inst.addChild(this.uiBindCard);
        this.uiBindCard.m_back.onClick(this, () => {
            Laya.Scene.open(Router.Cash, true, null, Laya.Handler.create(this, () => {
            }))
        })
        //提交
        this.uiBindCard.m_modifyBtn.onClick(this, this.bindUsdt)

        //客服列表
        this.uiBindCard.m_help_btn.onClick(this, this.onCustomerClick)
        this.uiBindCard.m_title.text = this.bindData.name
        if (this.data) {
            this.uiBindCard.m_name_input.text = this.data.usdtAddr
        }
    }
    //客服列表
    customerWindow: CustomerWindow = null
    onCustomerClick() {
        if (!ConfigManager.getInstance().Customer) {
            UIHelper.showMsg(I18nHelper.t('noService'))
            return
        }
        if (ConfigManager.getInstance().Customer.length > 0) {
            if (!this.customerWindow) {
                this.customerWindow = new CustomerWindow(ConfigManager.getInstance().Customer)
            }
            UIHelper.showWindow(this.customerWindow)
        }
        else {
            UIHelper.showMsg(I18nHelper.t('noService'))
        }
    }

    bindUsdt() {
        console.log("11111");

        let data = {
            id:this.data ? this.data.id:null,
            usdtAddr: this.uiBindCard.m_name_input.text,
            network: "TRC",
            name: "",
            withdrawType: "usdt"
        }

        http.addMyBankCard(data, (data: any) => {
            if (data.code == 200) {
                UIHelper.showMsg(I18nHelper.t(data.msg))
                Laya.Scene.open(Router.Cash, true, null, Laya.Handler.create(this, () => {
                }))
            } else {
                UIHelper.showMsg(I18nHelper.t(data.msg))
            }
        })
    }

    destroy() {
        this.uiBindCard.dispose()
    }
}