/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_h_btn from "./UI_h_btn";
import UI_l_btn from "./UI_l_btn";

export default class UI_LHArea extends fgui.GComponent {

	public m_h:UI_h_btn;
	public m_l:UI_l_btn;
	public static URL:string = "ui://61ic9kfkddvkz7i";

	public static createInstance():UI_LHArea {
		return <UI_LHArea>(fgui.UIPackage.createObject("game", "LHArea"));
	}

	protected onConstruct():void {
		this.m_h = <UI_h_btn>(this.getChild("h"));
		this.m_l = <UI_l_btn>(this.getChild("l"));
	}
}