/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_refsh_btn from "./UI_refsh_btn";

export default class UI_Detail extends fgui.GComponent {

	public m_num:fgui.GTextField;
	public m_refsh_btn:UI_refsh_btn;
	public m_cash_btn:fgui.GButton;
	public m_wallet_btn:fgui.GButton;
	public static URL:string = "ui://e59xd1qaywm7k";

	public static createInstance():UI_Detail {
		return <UI_Detail>(fgui.UIPackage.createObject("charge", "Detail"));
	}

	protected onConstruct():void {
		this.m_num = <fgui.GTextField>(this.getChild("num"));
		this.m_refsh_btn = <UI_refsh_btn>(this.getChild("refsh_btn"));
		this.m_cash_btn = <fgui.GButton>(this.getChild("cash_btn"));
		this.m_wallet_btn = <fgui.GButton>(this.getChild("wallet_btn"));
	}
}