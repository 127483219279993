
export default class LocalUtil{

    static setBool(value:boolean,key:string){
        localStorage.setItem(key,value ?"true":"false")
    }

    static setString(value:string,key:string){
        if(!key || key.length<1){
            return;
        }
        if(value==null || value.length<1){
            value="";
        }
        localStorage.setItem(key,value)
    }

    static setFloat(value:number,key:string){
        localStorage.setItem(key,value.toString())
    }


    static setObject(value:Object,key:string){
        localStorage.setItem(key,JSON.stringify(value))
    }

    static boolForKey(key:string,valid:boolean=false):boolean{
        let result= localStorage.getItem(key);
        if(!result){
            return valid;
        }
        if(result=="true"){
            return true;
        }else if(result=="false"){
            return false;
        }
        return valid;
    }

    static floatForKey(key:string,valid:number):number{
        let item=  localStorage.getItem(key);
        if(!item){
            return valid;
        }
        let result=parseFloat(item);
        return result;
    }

    static stringForKey(key:string,valid:string=""):string{
        let result:string=localStorage.getItem(key);
        if(!result){
            return valid;
        }
        return result;
    }


    static objectForKey(key:string,valid:Object={}):Object{
        let result:string=localStorage.getItem(key);
        result = JSON.parse(result)
        if(!result){
            return valid;
        }
        return result;
    }

    static clear(){
        localStorage.clear()
    }

    static getQueryString(key:string) {
        //1、url截取?之后的字符串(不包含?)
        var pathSearch = window.location.search.substr(1);
        var result = [];
        //2、以&为界截取参数键值对
        var paramItems = pathSearch.split("&");
        console.log(paramItems);
        // 3、将键值对形式的参数存入数组
        for (var i = 0; i < paramItems.length; i++) {
            var paramKey = paramItems[i].split("=")[0];
            var paramValue = paramItems[i].split("=")[1];
            result.push({
                key: paramKey,
                value: paramValue
            });
        }
        // 4、遍历key值
        for (var j = 0; j < result.length; j++) {
            if (result[j].key === key) {
                console.log(result[j].value);
                return result[j].value;
            }
        }
        return null;
    }
}
