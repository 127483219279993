/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_DelWindow from "./UI_DelWindow";
import UI_refsh_btn from "./UI_refsh_btn";

export default class UI_Cash extends fgui.GComponent {

	public m_back:fgui.GButton;
	public m_help_btn:fgui.GButton;
	public m_list:fgui.GList;
	public m_comfirm:fgui.GButton;
	public m_cash_input:fgui.GTextInput;
	public m_delWindow:UI_DelWindow;
	public m_tabList:fgui.GList;
	public m_num:fgui.GTextField;
	public m_refsh_btn:UI_refsh_btn;
	public static URL:string = "ui://8xnwynuhg54o1";

	public static createInstance():UI_Cash {
		return <UI_Cash>(fgui.UIPackage.createObject("cash", "Cash"));
	}

	protected onConstruct():void {
		this.m_back = <fgui.GButton>(this.getChild("back"));
		this.m_help_btn = <fgui.GButton>(this.getChild("help_btn"));
		this.m_list = <fgui.GList>(this.getChild("list"));
		this.m_comfirm = <fgui.GButton>(this.getChild("comfirm"));
		this.m_cash_input = <fgui.GTextInput>(this.getChild("cash_input"));
		this.m_delWindow = <UI_DelWindow>(this.getChild("delWindow"));
		this.m_tabList = <fgui.GList>(this.getChild("tabList"));
		this.m_num = <fgui.GTextField>(this.getChild("num"));
		this.m_refsh_btn = <UI_refsh_btn>(this.getChild("refsh_btn"));
	}
}