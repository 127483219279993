import { E_userType } from "../config/Enum";
import { IUserInfoType } from "../config/interface";


export default class PlayerDataManager {

    private static instance: PlayerDataManager;
    public playerName: string;

    private playerInfo: IUserInfoType = {
        id: "",
        avatar: "",
        name: "",
        nickname: "",
        phone: "",
        balance: 0,
        isPwd: 0,
        isThird: 0,
        isTour: 0
    }

    private orientation: boolean = false

    public isVip = false

    //余额454  175.5
    private balance: number = 0;
    //用户类型
    public userType: E_userType = null;
    //用户退出游戏
    public signOut = false
    //用户查询游戏记录数据
    private userGameBetInfo: {
        gameRecordStartTime: string,
        gameRecordEndTime: string,
        pageNum: number,
        betList: any,
    } = {
            gameRecordStartTime: "",
            gameRecordEndTime: "",
            pageNum: 0,
            betList: {},
        }

    public isTest: boolean = false
    serviceList: any[] = [];

    private constructor() {
        this.playerName = "";
        this.balance = 0;
    }

    public static getInstance(): PlayerDataManager {
        if (this.instance == null) {
            this.instance = new PlayerDataManager();
        }

        return this.instance;
    }

    public setPlayerInfo(info: IUserInfoType) {
        this.signOut = false
        this.playerInfo = info
        this.balance = info.balance
    }

    setAvatar(file: string){
        this.playerInfo.avatar = file
    }

    public getPlayerInfo() {
        return this.playerInfo;
    }


    public updatePlayerName(newName: string): void {
        this.playerInfo.name = newName;
        this.playerInfo.nickname = newName;
    }

    //更新钱
    public setBalance(num: any): void {
        this.playerInfo.balance = num
        this.balance = num;
        console.log(" this.balance:", this.balance)
    }
    //获取钱
    public getBalance() {
        return this.balance;
    }

    public getPlayerId() {
        return this.playerInfo.id
    }
    //用户退出登陆
    public setSignOut(isOut: boolean) {
        this.signOut = isOut
    }
    public getSignOut() {
        return this.signOut
    }

    //用户查询游戏记录 开始时间
    public getGameRecordStartTime() {
        return this.userGameBetInfo.gameRecordStartTime
    }
    public setGameRecordStartTime(time: string) {
        this.userGameBetInfo.gameRecordStartTime = time
    }

    //用户查询游戏记录 结束时间
    public getGameRecordEndTime() {
        return this.userGameBetInfo.gameRecordEndTime
    }
    public setGameRecordEndTime(time: string) {
        this.userGameBetInfo.gameRecordEndTime = time
    }

    //根据页码获取数据
    public getUserBetList() {
        return this.userGameBetInfo.betList[this.userGameBetInfo.pageNum]
    }

    //设置数据下注记录数据
    public setUserBetList(data: any) {
        this.userGameBetInfo.betList[this.userGameBetInfo.pageNum] = data;
    }

    //获取一条记录数据详情
    public getOneBetListInfo(index: number) {
        return this.userGameBetInfo.betList[this.userGameBetInfo.pageNum][index];
    }

    //获取记录页码
    public getUserBetPageNum() {
        return this.userGameBetInfo.pageNum;
    }
    //设置获取记录页码
    public setUserBetPageNum(pageNum: number) {
        let num = this.userGameBetInfo.pageNum;
        this.userGameBetInfo.pageNum = num + pageNum;
    }

    public getUserGameBetInfo() {
        return this.userGameBetInfo;
    }

    //清除数据
    public removeBetInfo() {
        this.userGameBetInfo.gameRecordEndTime = ""
        this.userGameBetInfo.pageNum = 0
        this.userGameBetInfo.betList = {}
    }
}
