/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_GameRecordRow extends fgui.GComponent {

	public m_code:fgui.GTextField;
	public m_ballList:fgui.GList;
	public m_no:fgui.GTextField;
	public static URL:string = "ui://d7esdcvtnvh62";

	public static createInstance():UI_GameRecordRow {
		return <UI_GameRecordRow>(fgui.UIPackage.createObject("windows", "GameRecordRow"));
	}

	protected onConstruct():void {
		this.m_code = <fgui.GTextField>(this.getChild("code"));
		this.m_ballList = <fgui.GList>(this.getChild("ballList"));
		this.m_no = <fgui.GTextField>(this.getChild("no"));
	}
}