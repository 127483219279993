/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_ComboBox2_popup extends fgui.GComponent {

	public m_list:fgui.GList;
	public static URL:string = "ui://1exd9k18mi8lv2a";

	public static createInstance():UI_ComboBox2_popup {
		return <UI_ComboBox2_popup>(fgui.UIPackage.createObject("common", "ComboBox2_popup"));
	}

	protected onConstruct():void {
		this.m_list = <fgui.GList>(this.getChild("list"));
	}
}