/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_agent_item from "./UI_agent_item";

export default class UI_agent extends fgui.GComponent {

	public m_back:fgui.GButton;
	public m_help_btn:fgui.GButton;
	public m_avatorMe:fgui.GComponent;
	public m_submitAgent0:fgui.GButton;
	public m_submitAgent1:fgui.GButton;
	public m_agentId_me:fgui.GTextField;
	public m_username_me:fgui.GTextField;
	public m_odds:fgui.GButton;
	public m_hasagent:fgui.GGroup;
	public m_qrBtn:fgui.GButton;
	public m_linkBtn:fgui.GButton;
	public m_link:fgui.GTextField;
	public m_qrcode:fgui.GLoader;
	public m_hasLink:fgui.GGroup;
	public m_noagenttop:fgui.GButton;
	public m_listTitle:UI_agent_item;
	public m_useList:fgui.GList;
	public static URL:string = "ui://ysuys7gbmi8l0";

	public static createInstance():UI_agent {
		return <UI_agent>(fgui.UIPackage.createObject("agent", "agent"));
	}

	protected onConstruct():void {
		this.m_back = <fgui.GButton>(this.getChild("back"));
		this.m_help_btn = <fgui.GButton>(this.getChild("help_btn"));
		this.m_avatorMe = <fgui.GComponent>(this.getChild("avatorMe"));
		this.m_submitAgent0 = <fgui.GButton>(this.getChild("submitAgent0"));
		this.m_submitAgent1 = <fgui.GButton>(this.getChild("submitAgent1"));
		this.m_agentId_me = <fgui.GTextField>(this.getChild("agentId_me"));
		this.m_username_me = <fgui.GTextField>(this.getChild("username_me"));
		this.m_odds = <fgui.GButton>(this.getChild("odds"));
		this.m_hasagent = <fgui.GGroup>(this.getChild("hasagent"));
		this.m_qrBtn = <fgui.GButton>(this.getChild("qrBtn"));
		this.m_linkBtn = <fgui.GButton>(this.getChild("linkBtn"));
		this.m_link = <fgui.GTextField>(this.getChild("link"));
		this.m_qrcode = <fgui.GLoader>(this.getChild("qrcode"));
		this.m_hasLink = <fgui.GGroup>(this.getChild("hasLink"));
		this.m_noagenttop = <fgui.GButton>(this.getChild("noagenttop"));
		this.m_listTitle = <UI_agent_item>(this.getChild("listTitle"));
		this.m_useList = <fgui.GList>(this.getChild("useList"));
	}
}