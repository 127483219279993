/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_ImageActive from "./UI_ImageActive";
import UI_Footer from "./UI_Footer";
import UI_Active from "./UI_Active";

export default class activeBinder {
	public static bindAll():void {
		fgui.UIObjectFactory.setExtension(UI_ImageActive.URL, UI_ImageActive);
		fgui.UIObjectFactory.setExtension(UI_Footer.URL, UI_Footer);
		fgui.UIObjectFactory.setExtension(UI_Active.URL, UI_Active);
	}
}