import UI_ConfirmWindow from "../../fgui/windows/UI_ConfirmWindow";
import { UIHelper } from "../../helper/UIHelper";
export class ConfirmWindow extends fgui.Window {
    content: string;
    title:string;
    confirm:any;
    cancel:any
    canBack:boolean
    public constructor(content: string,title:string,confirm:any,cancel:any,canBack:boolean = true) {
        super();
        this.content = content
        this.title = title
        this.confirm = confirm
        this.cancel = cancel
        this.canBack = canBack
    }
    protected onInit(): void {
        this.contentPane = UI_ConfirmWindow.createInstance();

        let testPane = UI_ConfirmWindow.createInstance();

        // @ts-ignore
        this.contentPane.m_title.text = this.title
        // @ts-ignore
        this.contentPane.m_content.text = this.content
        // @ts-ignore
        this.contentPane.m_cancel_btn.onClick(this,()=>{
            this.cancel()
            UIHelper.closeWindow(this)
        })
        // @ts-ignore
        this.contentPane.m_sure_btn.onClick(this,()=>{
            this.confirm()
            UIHelper.closeWindow(this)
        })

        this.setPivot(0.5, 0.5);
        this.center();

        // if (this.canBack) {
        //     this.contentPane.getChild('mask').onClick(this,()=>{
        //         this.doHideAnimation()
        //     })
        // }
    }
    protected doShowAnimation(): void {
        this.setScale(0.1, 0.1);
        fgui.GTween.to2(0.1, 0.1, 1, 1, 0.3)
            .setTarget(this, this.setScale)
            .setEase(fgui.EaseType.QuadOut)
            .onComplete(this.onShown, this);
    }
    protected doHideAnimation(): void {
        fgui.GTween.to2(1, 1, 0.1, 0.1, 0.3)
            .setTarget(this, this.setScale)
            .setEase(fgui.EaseType.QuadOut)
            .onComplete(this.hideImmediately, this);
    }
 


    destroy() {
    }
}
