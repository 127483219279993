/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_prize extends fgui.GComponent {

	public m_ball_list:fgui.GList;
	public m_lastNperCode:fgui.GTextField;
	public m_nperCode:fgui.GTextField;
	public m_time:fgui.GTextField;
	public static URL:string = "ui://61ic9kfkhe7mz7o";

	public static createInstance():UI_prize {
		return <UI_prize>(fgui.UIPackage.createObject("game", "prize"));
	}

	protected onConstruct():void {
		this.m_ball_list = <fgui.GList>(this.getChild("ball_list"));
		this.m_lastNperCode = <fgui.GTextField>(this.getChild("lastNperCode"));
		this.m_nperCode = <fgui.GTextField>(this.getChild("nperCode"));
		this.m_time = <fgui.GTextField>(this.getChild("time"));
	}
}