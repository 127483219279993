/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_refsh_btn extends fgui.GButton {

	public m_t0:fgui.Transition;
	public static URL:string = "ui://8xnwynuhaluku";

	public static createInstance():UI_refsh_btn {
		return <UI_refsh_btn>(fgui.UIPackage.createObject("cash", "refsh_btn"));
	}

	protected onConstruct():void {
		this.m_t0 = this.getTransition("t0");
	}
}