/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_homeContent from "./UI_homeContent";

export default class UI_Home extends fgui.GComponent {

	public m_tabs:fgui.Controller;
	public m_page:fgui.Controller;
	public m_homeContent:UI_homeContent;
	public m_buttom:fgui.GComponent;
	public static URL:string = "ui://bi3ljjpyw6t9z6u";

	public static createInstance():UI_Home {
		return <UI_Home>(fgui.UIPackage.createObject("home", "Home"));
	}

	protected onConstruct():void {
		this.m_tabs = this.getController("tabs");
		this.m_page = this.getController("page");
		this.m_homeContent = <UI_homeContent>(this.getChild("homeContent"));
		this.m_buttom = <fgui.GComponent>(this.getChild("buttom"));
	}
}