/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_menuList from "./UI_menuList";
import UI_Me from "./UI_Me";

export default class meBinder {
	public static bindAll():void {
		fgui.UIObjectFactory.setExtension(UI_menuList.URL, UI_menuList);
		fgui.UIObjectFactory.setExtension(UI_Me.URL, UI_Me);
	}
}