/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_bank_bottom extends fgui.GComponent {

	public m_list:fgui.GList;
	public static URL:string = "ui://ohc6kw3ebvurb";

	public static createInstance():UI_bank_bottom {
		return <UI_bank_bottom>(fgui.UIPackage.createObject("bind_card", "bank_bottom"));
	}

	protected onConstruct():void {
		this.m_list = <fgui.GList>(this.getChild("list"));
	}
}