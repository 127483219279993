/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_Active extends fgui.GComponent {

	public m_pics:fgui.GList;
	public m_help_btn:fgui.GButton;
	public m_buttom:fgui.GComponent;
	public static URL:string = "ui://6stwwrffw6t9n";

	public static createInstance():UI_Active {
		return <UI_Active>(fgui.UIPackage.createObject("active", "Active"));
	}

	protected onConstruct():void {
		this.m_pics = <fgui.GList>(this.getChild("pics"));
		this.m_help_btn = <fgui.GButton>(this.getChild("help_btn"));
		this.m_buttom = <fgui.GComponent>(this.getChild("buttom"));
	}
}