/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_NumBallBtn3 extends fgui.GButton {

	public m_red:fgui.GGraph;
	public m_line:fgui.GGraph;
	public static URL:string = "ui://d7esdcvtc9q3zac";

	public static createInstance():UI_NumBallBtn3 {
		return <UI_NumBallBtn3>(fgui.UIPackage.createObject("windows", "NumBallBtn3"));
	}

	protected onConstruct():void {
		this.m_red = <fgui.GGraph>(this.getChild("red"));
		this.m_line = <fgui.GGraph>(this.getChild("line"));
	}
}