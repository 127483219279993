/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_video_pos from "./UI_video_pos";
import UI_GameOption from "./UI_GameOption";
import UI_prize from "./UI_prize";
import UI_Bet from "./UI_Bet";
import UI_Rank from "./UI_Rank";
import UI_gameDes from "./UI_gameDes";

export default class UI_Game extends fgui.GComponent {

	public m_type:fgui.Controller;
	public m_tabs:fgui.Controller;
	public m_video_pos:UI_video_pos;
	public m_gameOptions:UI_GameOption;
	public m_prize:UI_prize;
	public m_bet_btn:fgui.GButton;
	public m_chat_btn:fgui.GButton;
	public m_rank_btn:fgui.GButton;
	public m_tabs_2:fgui.GGroup;
	public m_chat:fgui.GComponent;
	public m_toggleCom:fgui.GComponent;
	public m_page_title:fgui.GTextField;
	public m_back:fgui.GButton;
	public m_logo:fgui.GLoader;
	public m_Bet:UI_Bet;
	public m_gameRecordWindow:fgui.GComponent;
	public m_betRecordWindow:fgui.GComponent;
	public m_Rank:UI_Rank;
	public m_gameDes:UI_gameDes;
	public m_gameRoot:fgui.GGroup;
	public m_exitFull:fgui.GButton;
	public static URL:string = "ui://61ic9kfkve2iz4z";

	public static createInstance():UI_Game {
		return <UI_Game>(fgui.UIPackage.createObject("game", "Game"));
	}

	protected onConstruct():void {
		this.m_type = this.getController("type");
		this.m_tabs = this.getController("tabs");
		this.m_video_pos = <UI_video_pos>(this.getChild("video_pos"));
		this.m_gameOptions = <UI_GameOption>(this.getChild("gameOptions"));
		this.m_prize = <UI_prize>(this.getChild("prize"));
		this.m_bet_btn = <fgui.GButton>(this.getChild("bet_btn"));
		this.m_chat_btn = <fgui.GButton>(this.getChild("chat_btn"));
		this.m_rank_btn = <fgui.GButton>(this.getChild("rank_btn"));
		this.m_tabs_2 = <fgui.GGroup>(this.getChild("tabs"));
		this.m_chat = <fgui.GComponent>(this.getChild("chat"));
		this.m_toggleCom = <fgui.GComponent>(this.getChild("toggleCom"));
		this.m_page_title = <fgui.GTextField>(this.getChild("page_title"));
		this.m_back = <fgui.GButton>(this.getChild("back"));
		this.m_logo = <fgui.GLoader>(this.getChild("logo"));
		this.m_Bet = <UI_Bet>(this.getChild("Bet"));
		this.m_gameRecordWindow = <fgui.GComponent>(this.getChild("gameRecordWindow"));
		this.m_betRecordWindow = <fgui.GComponent>(this.getChild("betRecordWindow"));
		this.m_Rank = <UI_Rank>(this.getChild("Rank"));
		this.m_gameDes = <UI_gameDes>(this.getChild("gameDes"));
		this.m_gameRoot = <fgui.GGroup>(this.getChild("gameRoot"));
		this.m_exitFull = <fgui.GButton>(this.getChild("exitFull"));
	}
}