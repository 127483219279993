import { E_ScreenMode } from "../config/Enum";

class UManager {

    /** 检查手机的横竖屏状态 */
    getOr():E_ScreenMode {
        // return E_ScreenMode.horizontal
        if (Laya.Browser.onPC) {
            return E_ScreenMode.horizontal
        }
        else if (Laya.Browser.onMobile) {
            return   E_ScreenMode.vertical
           return Laya.stage.screenMode as E_ScreenMode
        }
        return E_ScreenMode.horizontal
    }

    /** 检测是否为横屏，默认返回横屏 */
    checkHorizontal():boolean{
        // return true

        if (Laya.Browser.onPC) {
            return true
        }
        else if (Laya.Browser.onMobile) {
            return false
            if (Laya.stage.screenMode == "horizontal") {
                return true
            }else{
                return false
            }
        }
        return true
    }

}

export const UIManager: UManager = new UManager();