export const th = {
  translation: {
    "netError": "网络错误",
    "noService": '您当前没有专属客服',
    "agentID": '代理ID',
    "agentNick": '代理昵称：',
    "moneyAgent": '现金代理',
    "creditAgent": '授信代理占成',
    "copySuccess": '复制成功',
    "all": '全部',
    "settledBets": '已结算注单',
    "unsettledBets": '未结算注单',
    "lose": '输',
    "win": '赢',
    "inviteQR": '邀请二维码',
    "unsettled": '未结算',
    "settled": '已结算',
    "canceled": '已取消',
    "num": '第',
    "numName": '名',
    "anonymous": "匿名者",
    "prizeDraw": '待开奖',
    "dragon": "龙",
    "tiger": "虎",
    "even": "双",
    "odd": "单",
    "big": "大",
    "small": "小",
    "closing": '即将封盘',
    "close": '已封盘',
    "opened": '已开奖',
    "hour": '时',
    "min": '分',
    "sec": '秒',
    "tourTip": "当前账号为试玩账号,是否前往注册？",
    "tip": "提示",
    "loginMsg": "登录信息填写不完整",
    "regMsg": "注册信息填写不完整",
    "maxImg": "图片大小不能超过5MB",
    "avatorError": "上传头像失败",
    "noPayTip": "当前账号未设置支付密码,是否前往设置？",
    "noBindTip": "当前提现方式未绑定，请先绑定该提现方式",
    "noUSDTTip": "当前账号未绑定钱包地址,是否前往绑定？",
    "bind": "绑定",
    "order": "存款记录",
    "withdraw": "提现记录",
    "userPassway": "人工充值",
    "usdtPassway": "USDT充值",
    "selectMoney": "请选择一个充值金额",
    "input": "请输入",
    "user": 'User',
    "no": "no",
    "emailCode": 'send email code',
    "reEmailCode": 'resend email code',
    "findPwd": 'Recuperar Senha',
    "backLogin": 'Voltar ao login',
    "AlipayLarge": "AlipayLarge",
  }
}