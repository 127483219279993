import { cnf_event } from "../config/Event_cnf";
import { IPConifg } from "../config/NetConfig";
import PlayerDataManager from "../model/PlayerDataManager";
import { http } from "../net/Http";
import NetManager from "../net/NetManager";
import LocalUtil from "../util/LocalUtil";
import { Router } from "../util/router";
import ConfigManager from "./ConfigManager";
import { notice } from "./NoticeHelper";
export default class HttpHelper {

    private static instance: HttpHelper;
    public static getInstance(): HttpHelper {
        if (!HttpHelper.instance) {
            HttpHelper.instance = new HttpHelper();
        }
        return HttpHelper.instance;
    }

    public isJumpLogin:boolean = false
    //获取主域名 app.jl3001.net   www.lrp6688.com
    getMainHost() {
        if (ConfigManager.getInstance().isDev) { //开发环境
            this.setNetCofing('https://lili.jl3001.net')
        } else {
            let origin = window.location.origin
            this.setNetCofing(origin)
        }
    }
    //设置域名参数
    setNetCofing(mainHost: string) {
        // const ipRegex = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(:\d{2,5})?$/;
        const ipRegex = /(25[0-5]|2[0-4]\d|[0-1]\d{2}|[1-9]?\d)\.(25[0-5]|2[0-4]\d|[0-1]\d{2}|[1-9]?\d)\.(25[0-5]|2[0-4]\d|[0-1]\d{2}|[1-9]?\d) \.(25[0-5]|2[0-4]\d|[0-1]\d{2}|[1-9]?\d)/;
        if (ipRegex.test(mainHost)) {
            mainHost = 'https://lili.jl3001.net'
            // 是IP地址
            IPConifg.httpIp = mainHost + '/api'
            IPConifg.assetIp = mainHost + '/oss'
            IPConifg.agentIp = mainHost + '/ag'
            IPConifg.socketIp = mainHost.replace('http', 'ws')
            // IPConifg.liveIp = mainHost
        } else {
            if (mainHost) {
                // 是域名地址
                IPConifg.httpIp = mainHost + '/api'
                IPConifg.assetIp = mainHost + '/oss'
                IPConifg.agentIp = mainHost + '/ag'
                IPConifg.socketIp = mainHost.replace('http', 'ws')
                // IPConifg.liveIp = 'https://' + mainHost + '/live'
            }
        }
    }

    getUserBalance() {
        http.getBalance((data: any) => {
            if (data.code == 200) {
                console.log("用户余额", data);
                PlayerDataManager.getInstance().setBalance(data.data.balance)
                notice.emit(cnf_event.OnBalanceChanged)
            }
        })
    }


    logOut(){
        LocalUtil.setString('', "userToken")
        NetManager.getInstance().token = ""
        NetManager.getInstance().inited = false
        NetManager.getInstance().closeSocket()
        this.isJumpLogin = true
        Laya.Scene.open(Router.Login, true, null, Laya.Handler.create(this, () => {
        }))
    }

}