import { E_ScreenMode } from "../../config/Enum"
import { IPConifg } from "../../config/NetConfig"
import UI_updateUser from "../../fgui/updateUser/UI_updateUser"
import ConfigManager from "../../helper/ConfigManager"
import I18nHelper from "../../helper/I18nHelper"
import { UIHelper } from "../../helper/UIHelper"
import PlayerDataManager from "../../model/PlayerDataManager"
import { http } from "../../net/Http"
import { Router } from "../../util/router"
import { UIBase } from "../scene/UIBase"
import { CustomerWindow } from "../windows/CustomerWindow"


export class UpdateUser extends UIBase {
    private or: E_ScreenMode
    private uiUpdateUser: UI_updateUser
    xhrList:XMLHttpRequest[] = []
    constructor(or: E_ScreenMode) {
        super(or == E_ScreenMode.horizontal ? UI_updateUser.createInstance() : UI_updateUser.createInstance());
        this.onUILoaded()
    }
    onUILoaded() {
        this.uiUpdateUser = this.UI_Item as UI_updateUser
        fgui.GRoot.inst.addChild(this.uiUpdateUser);


    

        this.uiUpdateUser.m_back.onClick(this, () => {
            Laya.Scene.open(Router.Me, true, null, Laya.Handler.create(this, () => {
            }))
        })

        //客服列表
        this.uiUpdateUser.m_help_btn.onClick(this, this.onCustomerClick);
        (this.uiUpdateUser.m_avatar.getChild("avatar") as fgui.GLoader).url =  PlayerDataManager.getInstance().getPlayerInfo().avatar
        this.createAvator()

        this.uiUpdateUser.m_modifyBtn.onClick(this,this.updateNick)
    }
    //客服列表
    customerWindow: CustomerWindow = null
    onCustomerClick() {
        if (!ConfigManager.getInstance().Customer) {
            UIHelper.showMsg(I18nHelper.t('noService'))
            return
        }
        if (ConfigManager.getInstance().Customer.length > 0) {
            if (!this.customerWindow) {
                this.customerWindow = new CustomerWindow(ConfigManager.getInstance().Customer)
            }
            UIHelper.showWindow(this.customerWindow)
        }
        else {
            UIHelper.showMsg(I18nHelper.t('noService'))
        }
    }

    
    /**
   * 用户提交图片 file 表单 基于原生 dom
   *
   */
    createAvator() {

        console.log("1212");

        //创建隐藏的file并且把它和按钮对齐。达到位置一致，这里我们默认在0点位置
        let file: any = Laya.Browser.document.createElement("input");
        //设置file样式
        file.style = "filter:alpha(opacity=0);opacity:0;width:100%;height:160px;cursor:pointer;top:50px";
        file.type = "file";//设置类型是file类型。
        file.size = "30";
        file.accept = "image/png/jpg";//设置文件的格式为png；
        file.style.position = "absolute";
        file.id = "avatar"
        // file.style.zIndex = 999;
        Laya.Browser.document.body.appendChild(file);//添加到页面；
        let fileReader: any = new Laya.Browser.window.FileReader();

        file.onchange = function (e: any): void {
            if (file.files.length > 0) {

                if (5120 * 1024 < file.files[0].size) {
                    UIHelper.showMsg(I18nHelper.t('maxImg'))
                } else {
                    fileReader.readAsDataURL(file.files[0]); //转换图片格式为字符编码
                }

            }
        };
        let self = this
        fileReader.onload = function (e: { target: { result: any; }; }): void {
            if (Laya.Browser.window.FileReader.DONE == fileReader.readyState) {
                let data = e.target.result;
                let xhr =  http.uploadHead({
                    "img": data
                }, (res: any) => {
                    if (res.code == 200) {
                        PlayerDataManager.getInstance().setAvatar(res.fileName);
                        (self.uiUpdateUser.m_avatar.getChild("avatar") as fgui.GLoader).url = data
                    } else {
                        UIHelper.showMsg(I18nHelper.t('avatorError'))
                    }
                });
                self.xhrList.push(xhr)
            }
        };
    }


    updateNick(){
        let xhr =  http.updateUsername({
            newName:this.uiUpdateUser.m_name_input.text
        },(res:any)=>{
            if (res.code == 200) {
                PlayerDataManager.getInstance().updatePlayerName(this.uiUpdateUser.m_name_input.text)
                Laya.Scene.open(Router.Me, true, null, Laya.Handler.create(this, () => {
                }))
            } 
            UIHelper.showMsg(I18nHelper.t(res.msg))
        })
        this.xhrList.push(xhr)
    }


    destroy() {
        document.getElementById("avatar")?.remove()
        this.xhrList.forEach((xhr)=>{
            xhr.abort()
        })
        this.uiUpdateUser.dispose()
    }
}