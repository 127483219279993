/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_avatar extends fgui.GComponent {

	public m_avatar:fgui.GLoader;
	public m_mask:fgui.GGraph;
	public m_avatarBtn:fgui.GButton;
	public static URL:string = "ui://1exd9k1810j9v1c";

	public static createInstance():UI_avatar {
		return <UI_avatar>(fgui.UIPackage.createObject("common", "avatar"));
	}

	protected onConstruct():void {
		this.m_avatar = <fgui.GLoader>(this.getChild("avatar"));
		this.m_mask = <fgui.GGraph>(this.getChild("mask"));
		this.m_avatarBtn = <fgui.GButton>(this.getChild("avatarBtn"));
	}
}