/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_Rank extends fgui.GComponent {

	public m_mask:fgui.GGraph;
	public m_rankList:fgui.GList;
	public m_day:fgui.GButton;
	public m_week:fgui.GButton;
	public m_mouth:fgui.GButton;
	public static URL:string = "ui://61ic9kfk10j9vz9l";

	public static createInstance():UI_Rank {
		return <UI_Rank>(fgui.UIPackage.createObject("game", "Rank"));
	}

	protected onConstruct():void {
		this.m_mask = <fgui.GGraph>(this.getChild("mask"));
		this.m_rankList = <fgui.GList>(this.getChild("rankList"));
		this.m_day = <fgui.GButton>(this.getChild("day"));
		this.m_week = <fgui.GButton>(this.getChild("week"));
		this.m_mouth = <fgui.GButton>(this.getChild("mouth"));
	}
}