/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_langSetting extends fgui.GComponent {

	public m_back:fgui.GButton;
	public m_help_btn:fgui.GButton;
	public m_country_btn:fgui.GList;
	public static URL:string = "ui://y4nh7vn2mi8l0";

	public static createInstance():UI_langSetting {
		return <UI_langSetting>(fgui.UIPackage.createObject("lang", "langSetting"));
	}

	protected onConstruct():void {
		this.m_back = <fgui.GButton>(this.getChild("back"));
		this.m_help_btn = <fgui.GButton>(this.getChild("help_btn"));
		this.m_country_btn = <fgui.GList>(this.getChild("country_btn"));
	}
}