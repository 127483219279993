/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_row from "./UI_row";
import UI_backwater from "./UI_backwater";
import UI_Footer from "./UI_Footer";

export default class backwaterBinder {
	public static bindAll():void {
		fgui.UIObjectFactory.setExtension(UI_row.URL, UI_row);
		fgui.UIObjectFactory.setExtension(UI_backwater.URL, UI_backwater);
		fgui.UIObjectFactory.setExtension(UI_Footer.URL, UI_Footer);
	}
}