/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_winFrame extends fgui.GLabel {

	public m_contentArea:fgui.GLoader;
	public m_dragArea:fgui.GLoader;
	public m_closeButton:fgui.GButton;
	public static URL:string = "ui://d7esdcvtnreqzal";

	public static createInstance():UI_winFrame {
		return <UI_winFrame>(fgui.UIPackage.createObject("windows", "winFrame"));
	}

	protected onConstruct():void {
		this.m_contentArea = <fgui.GLoader>(this.getChild("contentArea"));
		this.m_dragArea = <fgui.GLoader>(this.getChild("dragArea"));
		this.m_closeButton = <fgui.GButton>(this.getChild("closeButton"));
	}
}