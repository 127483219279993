import { IPay, IUsdt } from "../config/interface";

export default class WalletDataManager {
    //对象
    private static instance: WalletDataManager;
 

    private xhrList:XMLHttpRequest[] = []
    
    private payData: IPay[] = []
    private usdtList:IUsdt[] = []

    private isPayDataUpDate:boolean = true;
    private isUsdListDataUpDate:boolean = true;
    //单利模式
    public static getInstance(): WalletDataManager {
        if (this.instance == null) {
            this.instance = new WalletDataManager();
        }
        return this.instance;
    }
    setPayData(data:IPay[]){
        if(this.isPayDataUpDate){
            this.payData = [];
            data.forEach((e)=>{
                this.payData.push(e);
            })
            this.isPayDataUpDate = false;
        }
    }
    getPayData():IPay[]{
        return this.payData
    }
    setIsPayDataUpDate(bool:boolean){
        this.isPayDataUpDate = bool
    }
    getIsPayDataUpDate():boolean{
        return this.isPayDataUpDate;
    }
    setUsdListData(data:IUsdt[]){
        if(this.isUsdListDataUpDate){
            this.usdtList = [];
            data.forEach((e)=>{
                this.usdtList.push(e);
            })
            this.isUsdListDataUpDate = false;
        }
    }
    getUsdListData():IUsdt[]{
        return this.usdtList
    }
    setIsUsdListDataUpDate(bool:boolean){
        this.isUsdListDataUpDate = bool
    }
    getIsUsdListDataUpDate():boolean{
        return this.isUsdListDataUpDate;
    }
    //构造函数
    constructor() {
    }
    
}