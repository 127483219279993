/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_bank_bottom from "./UI_bank_bottom";

export default class UI_bind_card extends fgui.GComponent {

	public m_back:fgui.GButton;
	public m_title:fgui.GTextField;
	public m_withdrawName:fgui.GTextInput;
	public m_card_name:fgui.GGroup;
	public m_account:fgui.GTextInput;
	public m_card_number:fgui.GGroup;
	public m_out_name:fgui.GTextField;
	public m_card_account:fgui.GGroup;
	public m_outlets:fgui.GTextInput;
	public m_card_branch:fgui.GGroup;
	public m_modifyBtn:fgui.GButton;
	public m_bottom:UI_bank_bottom;
	public m_help_btn:fgui.GButton;
	public m_t0:fgui.Transition;
	public static URL:string = "ui://ohc6kw3ebvur7";

	public static createInstance():UI_bind_card {
		return <UI_bind_card>(fgui.UIPackage.createObject("bind_card", "bind_card"));
	}

	protected onConstruct():void {
		this.m_back = <fgui.GButton>(this.getChild("back"));
		this.m_title = <fgui.GTextField>(this.getChild("title"));
		this.m_withdrawName = <fgui.GTextInput>(this.getChild("withdrawName"));
		this.m_card_name = <fgui.GGroup>(this.getChild("card_name"));
		this.m_account = <fgui.GTextInput>(this.getChild("account"));
		this.m_card_number = <fgui.GGroup>(this.getChild("card_number"));
		this.m_out_name = <fgui.GTextField>(this.getChild("out_name"));
		this.m_card_account = <fgui.GGroup>(this.getChild("card_account"));
		this.m_outlets = <fgui.GTextInput>(this.getChild("outlets"));
		this.m_card_branch = <fgui.GGroup>(this.getChild("card_branch"));
		this.m_modifyBtn = <fgui.GButton>(this.getChild("modifyBtn"));
		this.m_bottom = <UI_bank_bottom>(this.getChild("bottom"));
		this.m_help_btn = <fgui.GButton>(this.getChild("help_btn"));
		this.m_t0 = this.getTransition("t0");
	}
}