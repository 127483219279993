import LocalUtil from "../util/LocalUtil";
import { zh } from "../i18n/zh";
import { en } from "../i18n/en";
import { vi } from "../i18n/vi";
import { zhF } from "../i18n/zhF";
import { ms } from "../i18n/ms";
import { pt } from "../i18n/pt";
import { ko } from "../i18n/ko";
import { km } from "../i18n/km";
import { th } from "../i18n/th";
export default class I18nHelper {
    static init() {
        let lang = LocalUtil.stringForKey("lang", this.getLoaclLang())


        // @ts-ignore
        window.i18next.init({
            lng: lang, // if you're using a language detector, do not define the lng option
            debug: true,
            resources: {
                zh,
                en,
                vi,
                zhF,
                ms,
                pt,
                ko,
                km,
                th,
            }
        });


    }

    static t(key: string) {
        // @ts-ignore
        return window.i18next.t(key)
    }

    static addResourceBundle(langTag: string, data: any) {
        // @ts-ignore
        window.i18next.addResourceBundle(langTag, 'translation', data);
    }



    /**
     * 转换成系统所属的语言
     * @returns 
     */
    static getLoaclLang() {
        // @ts-ignore
        let userLanguage = navigator.language || navigator.userLanguage;
        console.log("用户当前浏览器环境", userLanguage);
        //中文 zh 繁体zhF vi 越南  zh-TW 繁体 ms 马来 en 英语 pt-BR 巴西  ko 韩语 km 柬埔寨 th 泰国

        if (userLanguage.includes("zh")) {
            return "zh"
        } else if (userLanguage.includes("en")) {
            return "en"
        } else if (userLanguage.includes("vi")) {
            return "vi"
        } else if (userLanguage.includes("zh-TW")) {
            return "zhF"
        } else if (userLanguage.includes("ms")) {
            return "ms"
        } else if (userLanguage.includes("pt")) {
            return "pt"
        } else if (userLanguage.includes("ko")) {
            return "ko"
        } else if (userLanguage.includes("km")) {
            return "km"
        } else if (userLanguage.includes("th")) {
            return "th"
        } else {
            return "en"
        }
    }
}