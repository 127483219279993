/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_marqueeText extends fgui.GComponent {

	public m_marquee_text:fgui.GTextField;
	public static URL:string = "ui://bi3ljjpyfbdez7g";

	public static createInstance():UI_marqueeText {
		return <UI_marqueeText>(fgui.UIPackage.createObject("home", "marqueeText"));
	}

	protected onConstruct():void {
		this.m_marquee_text = <fgui.GTextField>(this.getChild("marquee_text"));
	}
}