/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_BetRecordRow extends fgui.GComponent {

	public m_no:fgui.GTextField;
	public m_code:fgui.GTextField;
	public m_betCode:fgui.GTextField;
	public m_amount:fgui.GTextField;
	public m_winLoss:fgui.GTextField;
	public m_gameResult:fgui.GTextField;
	public m_ballList:fgui.GList;
	public static URL:string = "ui://d7esdcvtnvh66";

	public static createInstance():UI_BetRecordRow {
		return <UI_BetRecordRow>(fgui.UIPackage.createObject("windows", "BetRecordRow"));
	}

	protected onConstruct():void {
		this.m_no = <fgui.GTextField>(this.getChild("no"));
		this.m_code = <fgui.GTextField>(this.getChild("code"));
		this.m_betCode = <fgui.GTextField>(this.getChild("betCode"));
		this.m_amount = <fgui.GTextField>(this.getChild("amount"));
		this.m_winLoss = <fgui.GTextField>(this.getChild("winLoss"));
		this.m_gameResult = <fgui.GTextField>(this.getChild("gameResult"));
		this.m_ballList = <fgui.GList>(this.getChild("ballList"));
	}
}