
import UI_marquee from "../../fgui/home/UI_marquee";
import UI_marqueeText from "../../fgui/home/UI_marqueeText";
import ConfigManager from "../../helper/ConfigManager";
import I18nHelper from "../../helper/I18nHelper";
import { http } from "../../net/Http";
export class Marquee {
    private uI_notice: UI_marquee;
    noticeAni: Laya.Tween
    textArr: string[] = []
    constructor(uI_notice: UI_marquee ) {
        this.uI_notice = uI_notice;
        this.onUILoaded()
    }


    splitStringIntoThree(str: string) {
        // 计算每部分应该有的字符数
        const partLength = Math.ceil(str.length / 3);
        // 初始化数组
        const result = [];

        // 循环分割字符串
        for (let i = 0; i < str.length; i += partLength) {
            // 将字符串的一部分添加到数组中
            result.push(str.substring(i, i + partLength));
        }
        return result;
    }

    timer: number = null
    xhrList:XMLHttpRequest[] = []
    onUILoaded() {
        // this.uI_notice.m_marquee.m_marquee_text.singleLine = true;
        // this.uI_notice.m_marquee.m_marquee_text.text = ConfigManager.getInstance().notices

    
        let xhr =  http.getNotice((res:any)=>{
            if (res.code == 200) {
                res.data.forEach((item: { cpairCode: string; noticeContent: any; })=>{
                    ConfigManager.getInstance().notices +=item.cpairCode ? I18nHelper.t(item.cpairCode) :   item.noticeContent
                })

                this.uI_notice.m_marquee_text.text =  ConfigManager.getInstance().notices
                this.textArr = this.splitStringIntoThree(ConfigManager.getInstance().notices)
                if (this.uI_notice) {
                    this.uI_notice.m_marequee_list.itemRenderer = Laya.Handler.create(this, this.renderListItem, null, false);
                    this.uI_notice.m_marequee_list.numItems = this.textArr.length;
                    this.uI_notice.m_marequee_list.setXY(this.uI_notice.width, this.uI_notice.m_marquee_text.y)
                    this.repeatText()
                }
            }
        })
        this.xhrList.push(xhr)


    }
    //重置动画
    private repeatText() {
        let width = 0
        this.uI_notice.m_marequee_list._children.forEach((node) => {
            width += node.width
        })
        console.log(width);
        
        let x = - (this.uI_notice.m_marquee_text.textWidth + 500)
        this.noticeAni = new Laya.Tween()
        this.noticeAni.repeat = 0;
        this.noticeAni.to(this.uI_notice.m_marequee_list, { x: x }, 10 *(this.uI_notice.m_marquee_text.textWidth + 500) , null, Laya.Handler.create(this,()=>{
        }));
    }


    private renderListItem(index: number, obj: fgui.GObject): void {
        var item: UI_marqueeText = <UI_marqueeText>obj;
        item.m_marquee_text.text = this.textArr[index] // ConfigManager.getInstance().notices
    }

    destroy() {
        this.xhrList.forEach((xhr)=>{
            xhr.abort()
        })
        clearTimeout(this.timer)
        this.noticeAni && this.noticeAni.clear()
        this.uI_notice && this.uI_notice.dispose()
    }
}