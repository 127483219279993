export default class ResourceManager {
    static instance: ResourceManager = null
    loadedResources:any = {};

    constructor() {
        this.loadedResources = {};
    }

    static getInstance() {
        if (!this.instance) {
            this.instance = new ResourceManager();
        }
        return this.instance;
    }
    async loadResource(url:string, type:any) {
        // cc.log("图片已经存在直接返回:",url)
        if (this.loadedResources[url]) {
            return this.loadedResources[url];
        }
        return new Promise((resolve, reject) => {

            Laya.loader.load(url, type).then((resource: unknown) => {
                this.loadedResources[url] = resource; // 缓存已加载的资源
                resolve(resource);
            },(reason)=>{
                console.log("图片资源加载错误:", reason)
                reject(null)
            });
        });
    }

    async loadResourcesInFolders(foldersToLoad: string[], progressCallback: (arg0: number) => void) {
        const loadedFolderResources: any[] = [];
        const updateProgress = (progress:number) => {
            if (progressCallback) {
                progressCallback(progress);
            }
        };

        try {
            fgui.UIPackage.loadPackage(foldersToLoad, (pkgs: fairygui.UIPackage[]) => {
                if (pkgs.length  == foldersToLoad.length) {
                    updateProgress(1000)
                }
            }, (progress) => {
                updateProgress(progress)
            });
        } catch (error) {
            console.error(`加载文件夹资源出错: ${error}`);
        } 

        return loadedFolderResources;
    }
}
