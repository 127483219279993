/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_SumRecordTitle extends fgui.GComponent {

	public m_no:fgui.GTextField;
	public m_code:fgui.GTextField;
	public m_ballList:fgui.GList;
	public m_ballList2:fgui.GList;
	public static URL:string = "ui://d7esdcvtmi8lza7";

	public static createInstance():UI_SumRecordTitle {
		return <UI_SumRecordTitle>(fgui.UIPackage.createObject("windows", "SumRecordTitle"));
	}

	protected onConstruct():void {
		this.m_no = <fgui.GTextField>(this.getChild("no"));
		this.m_code = <fgui.GTextField>(this.getChild("code"));
		this.m_ballList = <fgui.GList>(this.getChild("ballList"));
		this.m_ballList2 = <fgui.GList>(this.getChild("ballList2"));
	}
}