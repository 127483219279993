/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_ball extends fgui.GComponent {

	public m_ball_loader:fgui.GLoader;
	public static URL:string = "ui://61ic9kfkhe7mz7p";

	public static createInstance():UI_ball {
		return <UI_ball>(fgui.UIPackage.createObject("game", "ball"));
	}

	protected onConstruct():void {
		this.m_ball_loader = <fgui.GLoader>(this.getChild("ball_loader"));
	}
}