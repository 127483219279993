/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_BigBallBtnTitle2 extends fgui.GButton {

	public m_red:fgui.GGraph;
	public static URL:string = "ui://d7esdcvtc9q3zaf";

	public static createInstance():UI_BigBallBtnTitle2 {
		return <UI_BigBallBtnTitle2>(fgui.UIPackage.createObject("windows", "BigBallBtnTitle2"));
	}

	protected onConstruct():void {
		this.m_red = <fgui.GGraph>(this.getChild("red"));
	}
}