/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_BetRecordWindow extends fgui.GComponent {

	public m_mask:fgui.GGraph;
	public m_closeButton:fgui.GButton;
	public m_list:fgui.GList;
	public m_code:fgui.GTextField;
	public m_betCode:fgui.GTextField;
	public m_amount:fgui.GTextField;
	public m_winLoss:fgui.GTextField;
	public m_gameResult:fgui.GTextField;
	public m_search:fgui.GButton;
	public m_start_time:fgui.GTextInput;
	public m_end_time:fgui.GTextInput;
	public m_allBet:fgui.GTextField;
	public m_allWin:fgui.GTextField;
	public static URL:string = "ui://d7esdcvtnvh65";

	public static createInstance():UI_BetRecordWindow {
		return <UI_BetRecordWindow>(fgui.UIPackage.createObject("windows", "BetRecordWindow"));
	}

	protected onConstruct():void {
		this.m_mask = <fgui.GGraph>(this.getChild("mask"));
		this.m_closeButton = <fgui.GButton>(this.getChild("closeButton"));
		this.m_list = <fgui.GList>(this.getChild("list"));
		this.m_code = <fgui.GTextField>(this.getChild("code"));
		this.m_betCode = <fgui.GTextField>(this.getChild("betCode"));
		this.m_amount = <fgui.GTextField>(this.getChild("amount"));
		this.m_winLoss = <fgui.GTextField>(this.getChild("winLoss"));
		this.m_gameResult = <fgui.GTextField>(this.getChild("gameResult"));
		this.m_search = <fgui.GButton>(this.getChild("search"));
		this.m_start_time = <fgui.GTextInput>(this.getChild("start_time"));
		this.m_end_time = <fgui.GTextInput>(this.getChild("end_time"));
		this.m_allBet = <fgui.GTextField>(this.getChild("allBet"));
		this.m_allWin = <fgui.GTextField>(this.getChild("allWin"));
	}
}