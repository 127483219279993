/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_GlobalModalWaiting from "./UI_GlobalModalWaiting";
import UI_Loading from "./UI_Loading";
import UI_Loading_V from "./UI_Loading_V";

export default class loadingBinder {
	public static bindAll():void {
		fgui.UIObjectFactory.setExtension(UI_GlobalModalWaiting.URL, UI_GlobalModalWaiting);
		fgui.UIObjectFactory.setExtension(UI_Loading.URL, UI_Loading);
		fgui.UIObjectFactory.setExtension(UI_Loading_V.URL, UI_Loading_V);
	}
}