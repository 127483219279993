/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_Detail from "./UI_Detail";
import UI_usdtQR from "./UI_usdtQR";

export default class UI_Wallet extends fgui.GComponent {

	public m_my_wallet:UI_Detail;
	public m_tabList:fgui.GList;
	public m_moneyList:fgui.GList;
	public m_custom_input:fgui.GTextInput;
	public m_sure_btn:fgui.GButton;
	public m_custom:fgui.GGroup;
	public m_money_area:fgui.GGroup;
	public m_customerList:fgui.GList;
	public m_user_area:fgui.GGroup;
	public m_usdtList:fgui.GList;
	public m_usdt_area:fgui.GGroup;
	public m_buttom:fgui.GComponent;
	public m_usdtQR:UI_usdtQR;
	public m_back:fgui.GButton;
	public m_help_btn:fgui.GButton;
	public static URL:string = "ui://eelfbt0zw6t9h";

	public static createInstance():UI_Wallet {
		return <UI_Wallet>(fgui.UIPackage.createObject("wallet", "Wallet"));
	}

	protected onConstruct():void {
		this.m_my_wallet = <UI_Detail>(this.getChild("my_wallet"));
		this.m_tabList = <fgui.GList>(this.getChild("tabList"));
		this.m_moneyList = <fgui.GList>(this.getChild("moneyList"));
		this.m_custom_input = <fgui.GTextInput>(this.getChild("custom_input"));
		this.m_sure_btn = <fgui.GButton>(this.getChild("sure_btn"));
		this.m_custom = <fgui.GGroup>(this.getChild("custom"));
		this.m_money_area = <fgui.GGroup>(this.getChild("money_area"));
		this.m_customerList = <fgui.GList>(this.getChild("customerList"));
		this.m_user_area = <fgui.GGroup>(this.getChild("user_area"));
		this.m_usdtList = <fgui.GList>(this.getChild("usdtList"));
		this.m_usdt_area = <fgui.GGroup>(this.getChild("usdt_area"));
		this.m_buttom = <fgui.GComponent>(this.getChild("buttom"));
		this.m_usdtQR = <UI_usdtQR>(this.getChild("usdtQR"));
		this.m_back = <fgui.GButton>(this.getChild("back"));
		this.m_help_btn = <fgui.GButton>(this.getChild("help_btn"));
	}
}