/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_frame from "./UI_frame";

export default class UI_CustomerWindow extends fgui.GComponent {

	public m_mask:fgui.GGraph;
	public m_frame:UI_frame;
	public m_customerList:fgui.GList;
	public static URL:string = "ui://d7esdcvtt53uz8e";

	public static createInstance():UI_CustomerWindow {
		return <UI_CustomerWindow>(fgui.UIPackage.createObject("windows", "CustomerWindow"));
	}

	protected onConstruct():void {
		this.m_mask = <fgui.GGraph>(this.getChild("mask"));
		this.m_frame = <UI_frame>(this.getChild("frame"));
		this.m_customerList = <fgui.GList>(this.getChild("customerList"));
	}
}