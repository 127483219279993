/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_balance_row from "./UI_balance_row";
import UI_Footer from "./UI_Footer";
import UI_balance_record from "./UI_balance_record";

export default class balance_recordBinder {
	public static bindAll():void {
		fgui.UIObjectFactory.setExtension(UI_balance_row.URL, UI_balance_row);
		fgui.UIObjectFactory.setExtension(UI_Footer.URL, UI_Footer);
		fgui.UIObjectFactory.setExtension(UI_balance_record.URL, UI_balance_record);
	}
}