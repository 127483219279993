/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_refsh_btn from "./UI_refsh_btn";
import UI_Cash from "./UI_Cash";
import UI_DelWindow from "./UI_DelWindow";
import UI_withdraw_btn from "./UI_withdraw_btn";
import UI_add from "./UI_add";

export default class cashBinder {
	public static bindAll():void {
		fgui.UIObjectFactory.setExtension(UI_refsh_btn.URL, UI_refsh_btn);
		fgui.UIObjectFactory.setExtension(UI_Cash.URL, UI_Cash);
		fgui.UIObjectFactory.setExtension(UI_DelWindow.URL, UI_DelWindow);
		fgui.UIObjectFactory.setExtension(UI_withdraw_btn.URL, UI_withdraw_btn);
		fgui.UIObjectFactory.setExtension(UI_add.URL, UI_add);
	}
}