/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_bind_card from "./UI_bind_card";
import UI_bank_bottom from "./UI_bank_bottom";
import UI_bank_item from "./UI_bank_item";

export default class bind_cardBinder {
	public static bindAll():void {
		fgui.UIObjectFactory.setExtension(UI_bind_card.URL, UI_bind_card);
		fgui.UIObjectFactory.setExtension(UI_bank_bottom.URL, UI_bank_bottom);
		fgui.UIObjectFactory.setExtension(UI_bank_item.URL, UI_bank_item);
	}
}