import { E_ScreenMode } from "../../config/Enum";
import UI_modify from "../../fgui/modify/UI_modify";
import ConfigManager from "../../helper/ConfigManager";
import { http } from "../../net/Http";
import { Router } from "../../util/router";
import { UIHelper } from "../../helper/UIHelper";
import { UIBase } from "../scene/UIBase";
import { CustomerWindow } from "../windows/CustomerWindow";
import I18nHelper from "../../helper/I18nHelper";
export class Modify extends UIBase{
    private reloadNums: number = 0
    private or: E_ScreenMode
    private uimodify: UI_modify
    xhrList:XMLHttpRequest[] = []

    constructor(or: E_ScreenMode) {
        super(or == E_ScreenMode.horizontal ? UI_modify.createInstance() : UI_modify.createInstance());
        this.onUILoaded()
    }
    onUILoaded() {
        this.uimodify = this.UI_Item as UI_modify
        fgui.GRoot.inst.addChild(this.uimodify);
        //修改密码
        this.uimodify.m_modifyBtn.onClick(this, () => {
            let xhr =  http.modifyPwd({
                oldPassword: this.uimodify.m_oldPassword.text,
                newPassword: this.uimodify.m_newPassword.text,
                twoPassword: this.uimodify.m_twoPassword.text,
            }, (data: any) => {
                if (data.code == 200) {
                    UIHelper.showMsg(I18nHelper.t(data.msg))
                    Laya.Scene.open(Router.Login, true, null, Laya.Handler.create(this, () => {
                    }))
                }else{
                    UIHelper.showMsg(I18nHelper.t(data.msg))
                }
            })
            this.xhrList.push(xhr)
        })
        this.uimodify.m_back.onClick(this, () => {
            Laya.Scene.open(Router.Me, true, null, Laya.Handler.create(this, () => {
            }))
        })
    //客服列表
   this.uimodify.m_help_btn.onClick(this, this.onCustomerClick)
}
//客服列表
customerWindow: CustomerWindow = null
onCustomerClick() {
    if (!ConfigManager.getInstance().Customer) {
        UIHelper.showMsg(I18nHelper.t('noService'))
        return
    }
    if (ConfigManager.getInstance().Customer.length > 0) {
        if (!this.customerWindow) {
            this.customerWindow = new CustomerWindow(ConfigManager.getInstance().Customer)
        }
        UIHelper.showWindow(this.customerWindow)
    }
    else {
        UIHelper.showMsg(I18nHelper.t('noService'))
    }
}
    destroy() {
        this.xhrList.forEach((xhr)=>{
            xhr.abort()
        })
        this.uimodify.dispose()
    }
}