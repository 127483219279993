/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_channel_btn extends fgui.GButton {

	public m_name:fgui.GTextField;
	public m_logo1:fgui.GLoader;
	public m_logo2:fgui.GLoader;
	public static URL:string = "ui://eelfbt0zywm7s";

	public static createInstance():UI_channel_btn {
		return <UI_channel_btn>(fgui.UIPackage.createObject("wallet", "channel_btn"));
	}

	protected onConstruct():void {
		this.m_name = <fgui.GTextField>(this.getChild("name"));
		this.m_logo1 = <fgui.GLoader>(this.getChild("logo1"));
		this.m_logo2 = <fgui.GLoader>(this.getChild("logo2"));
	}
}