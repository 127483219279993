/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_updateUser extends fgui.GComponent {

	public m_back:fgui.GButton;
	public m_title:fgui.GTextField;
	public m_modifyBtn:fgui.GButton;
	public m_help_btn:fgui.GButton;
	public m_avatar:fgui.GComponent;
	public m_name_input:fgui.GTextInput;
	public m_t0:fgui.Transition;
	public static URL:string = "ui://9d28268olqsm1";

	public static createInstance():UI_updateUser {
		return <UI_updateUser>(fgui.UIPackage.createObject("updateUser", "updateUser"));
	}

	protected onConstruct():void {
		this.m_back = <fgui.GButton>(this.getChild("back"));
		this.m_title = <fgui.GTextField>(this.getChild("title"));
		this.m_modifyBtn = <fgui.GButton>(this.getChild("modifyBtn"));
		this.m_help_btn = <fgui.GButton>(this.getChild("help_btn"));
		this.m_avatar = <fgui.GComponent>(this.getChild("avatar"));
		this.m_name_input = <fgui.GTextInput>(this.getChild("name_input"));
		this.m_t0 = this.getTransition("t0");
	}
}