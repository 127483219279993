/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_Footer extends fgui.GComponent {

	public m_c1:fgui.Controller;
	public m_t0:fgui.Transition;
	public static URL:string = "ui://o6l13e8abvur8";

	public static createInstance():UI_Footer {
		return <UI_Footer>(fgui.UIPackage.createObject("wallet_record", "Footer"));
	}

	protected onConstruct():void {
		this.m_c1 = this.getController("c1");
		this.m_t0 = this.getTransition("t0");
	}
}