/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_chips_list extends fgui.GComponent {

	public m_chips_list:fgui.GList;
	public static URL:string = "ui://61ic9kfkcdhsz7m";

	public static createInstance():UI_chips_list {
		return <UI_chips_list>(fgui.UIPackage.createObject("game", "chips_list"));
	}

	protected onConstruct():void {
		this.m_chips_list = <fgui.GList>(this.getChild("chips_list"));
	}
}