/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_winFrame from "./UI_winFrame";

export default class UI_GameListWindow extends fgui.GComponent {

	public m_mask:fgui.GGraph;
	public m_frame:UI_winFrame;
	public m_tablist:fgui.GList;
	public m_gameList:fgui.GList;
	public static URL:string = "ui://d7esdcvtnreqzam";

	public static createInstance():UI_GameListWindow {
		return <UI_GameListWindow>(fgui.UIPackage.createObject("windows", "GameListWindow"));
	}

	protected onConstruct():void {
		this.m_mask = <fgui.GGraph>(this.getChild("mask"));
		this.m_frame = <UI_winFrame>(this.getChild("frame"));
		this.m_tablist = <fgui.GList>(this.getChild("tablist"));
		this.m_gameList = <fgui.GList>(this.getChild("gameList"));
	}
}