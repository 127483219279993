import { IGameTable } from "../config/interface";

export default class HomeDataManager {
    //对象
    private static instance: HomeDataManager;
 

    private xhrList:XMLHttpRequest[] = []
    
    private swiperData: any[] = []
    private isSwiperDataUpDate:boolean = true;
    private gameList:IGameTable[] = [];
    //单利模式
    public static getInstance(): HomeDataManager {
        if (this.instance == null) {
            this.instance = new HomeDataManager();
        }
        return this.instance;
    }
    setSwiperData(data:any[]){
        if(this.isSwiperDataUpDate){
            this.swiperData = [];
            data.forEach((e)=>{
                this.swiperData.push(e);
            })
            this.isSwiperDataUpDate = false;
        }
    }
    getSwiperData():any[]{
        return this.swiperData
    }
  
    setIsSwiperDataUpDate(bool:boolean){
        this.isSwiperDataUpDate = bool
    }
    getIsSwiperDataUpDate():boolean{
        return this.isSwiperDataUpDate;
    }

    setGameList(data:IGameTable[]){
        this.gameList = data;
    }
    getGameList():IGameTable[]{
        return this.gameList;
    }
  
    //构造函数
    constructor() {
    }
    
}