/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_bank_item extends fgui.GComponent {

	public m_bankName:fgui.GTextField;
	public static URL:string = "ui://ohc6kw3ebvurc";

	public static createInstance():UI_bank_item {
		return <UI_bank_item>(fgui.UIPackage.createObject("bind_card", "bank_item"));
	}

	protected onConstruct():void {
		this.m_bankName = <fgui.GTextField>(this.getChild("bankName"));
	}
}