import { E_ScreenMode } from "../../../config/Enum"
import { cnf_event } from "../../../config/Event_cnf"
import { IPConifg } from "../../../config/NetConfig"
import { IBankCard, IWithdrawType } from "../../../config/interface"
import UI_Cash from "../../../fgui/cash/UI_Cash"
import UI_add from "../../../fgui/cash/UI_add"
import UI_withdraw_btn from "../../../fgui/cash/UI_withdraw_btn"
import ConfigManager from "../../../helper/ConfigManager"
import HttpHelper from "../../../helper/HttpHelper"
import I18nHelper from "../../../helper/I18nHelper"
import { notice } from "../../../helper/NoticeHelper"
import { UIHelper } from "../../../helper/UIHelper"
import PlayerDataManager from "../../../model/PlayerDataManager"
import { http } from "../../../net/Http"
import { Router } from "../../../util/router"
import { BindCardScene } from "../../scene/BindCardScene"
import { BindWalletScene } from "../../scene/BindWalletScene"
import { BindWithdrawScene } from "../../scene/BindWithdrawScene"
import { UIBase } from "../../scene/UIBase"
import { BindEmailWindow } from "../../windows/BindEmailWindow"
import { ConfirmWindow } from "../../windows/ConfirmWindow"
import { CustomerWindow } from "../../windows/CustomerWindow"
import { PayWindow } from "../../windows/PayWindow"

export class Cash extends UIBase {
    private or: E_ScreenMode
    private uiCash: UI_Cash
    private selectedIndex: number = 0
    private bankCardList: IBankCard[] = []

    private withdrawTypeList: IWithdrawType[] = []
    private delCardId: number = null
    constructor(or: E_ScreenMode) {
        super(or == E_ScreenMode.horizontal ? UI_Cash.createInstance() : UI_Cash.createInstance());
        this.onUILoaded()
    }
    onUILoaded() {
        this.uiCash = this.UI_Item as UI_Cash
        fgui.GRoot.inst.addChild(this.uiCash)
        //刷新余额
        this.uiCash.m_refsh_btn.onClick(this, () => {
            this.uiCash.m_refsh_btn.m_t0.play()
            HttpHelper.getInstance().getUserBalance()
            Laya.timer.once(2000, this, async () => {
                this.uiCash.m_refsh_btn.m_t0.stop()
            });
        })

        this.uiCash.m_back.onClick(this, () => {
            Laya.Scene.open(Router.Charge, true, null, Laya.Handler.create(this, () => {
            }))
        })

        this.uiCash.m_list.itemRenderer = Laya.Handler.create(this, this.renderListItem, null, false)
        this.uiCash.m_tabList.itemRenderer = Laya.Handler.create(this, this.renderTableListItem, null, false)



        this.uiCash.m_list.on(fgui.Events.SCROLL_END, this, this.scrollChange)

        //客服列表
        this.uiCash.m_help_btn.onClick(this, this.onCustomerClick)


        this.uiCash.m_comfirm.onClick(this, () => {
            // 

            let findIndex = -1

            this.uiCash.m_list._children.forEach((item, index) => {
                if (this.uiCash.m_list.scrollPane.isChildInView(item)) {
                    findIndex = index
                }
            })

        })

        this.uiCash.m_list.selectedIndex = 0

        this.uiCash.m_delWindow.m_comfirm.onClick(this, this.delCard)
        this.uiCash.m_delWindow.m_cancel.onClick(this, () => {
            this.uiCash.m_delWindow.visible = false
        })
        this.getBankList()
        notice.on(cnf_event.OnBalanceChanged, this.OnBalanceChanged)

        this.uiCash.m_comfirm.onClick(this, this.confirmOrder)

        this.OnBalanceChanged()
    }


    confirmOrder() {
        if (PlayerDataManager.getInstance().getPlayerInfo().isTour == 2) {
            UIHelper.showWindow(new ConfirmWindow(I18nHelper.t('tourTip'), I18nHelper.t('tip'), () => {
                HttpHelper.getInstance().logOut()
            }, () => { }))
            return
        }

        if (PlayerDataManager.getInstance().getPlayerInfo().isPwd == 0) {
            UIHelper.showWindow(new ConfirmWindow(I18nHelper.t('noPayTip'), I18nHelper.t('tip'), () => {
                Laya.Scene.open(Router.PayModify, true, null, Laya.Handler.create(this, () => {
                }))
            }, () => { }))
            return
        }

        let findData = this.bankCardList.find((item) => {
            return item.bankCode == this.withdrawTypeList[this.uiCash.m_tabList.selectedIndex].bankCode
        })

        if (findData) {
            UIHelper.showWindow(new PayWindow(this.uiCash.m_cash_input.text, I18nHelper.t('tip'), (pwd: any) => {

                http.createOrder({
                    "password": pwd,
                    "money": this.uiCash.m_cash_input.text,
                    "bankCardId": findData.id
                }, (res: any) => {
                    console.log("res", res);
                    UIHelper.showMsg(I18nHelper.t(res.msg))
                })
            }, ""))
        } else {
            UIHelper.showMsg(I18nHelper.t('noBindTip'))
        }



    }

    OnBalanceChanged = () => {
        this.uiCash.m_num.text = PlayerDataManager.getInstance().getBalance().toString()
    }

    getBankList() {



        http.getBankList((res: any) => {
            console.log("银行卡列表", res);
            if (res.code == 200) {
                this.withdrawTypeList = res.data
                this.uiCash.m_tabList.numItems = this.withdrawTypeList.length
                this.uiCash.m_tabList.selectedIndex = 0

                http.getMyBankCard((res: any) => {
                    if (res.code == 200) {
                        this.bankCardList = res.data
                        this.uiCash.m_list.numItems = this.withdrawTypeList.length
                    }
                })
            } else {
                UIHelper.showMsg(I18nHelper.t(res.msg))
            }
        })
    }

    scrollChange() {
        console.log("滑动后当前选择的卡片", this.uiCash.m_list.scrollPane.isChildInView(this.uiCash.m_list.getChildAt(0)));

        this.uiCash.m_list._children.forEach((item, index) => {
            if (this.uiCash.m_list.scrollPane.isChildInView(item)) {
                this.uiCash.m_list.selectedIndex = index
                this.uiCash.m_tabList.selectedIndex = index
            }
        })

    }

    //客服列表
    customerWindow: CustomerWindow = null
    onCustomerClick() {
        if (!ConfigManager.getInstance().Customer) {
            UIHelper.showMsg(I18nHelper.t('noService'))
            return
        }
        if (ConfigManager.getInstance().Customer.length > 0) {
            if (!this.customerWindow) {
                this.customerWindow = new CustomerWindow(ConfigManager.getInstance().Customer)
            }
            UIHelper.showWindow(this.customerWindow)
        }
        else {
            UIHelper.showMsg(I18nHelper.t('noService'))
        }
    }

    renderListItem(index: number, obj: fairygui.GObject) {
        let item = obj as UI_add

        let findData = this.bankCardList.find((item) => {
            return item.bankCode == this.withdrawTypeList[index].bankCode
        })


        if (findData) {
            switch (findData.bankCode) {
                case "BANK":
                    item.title = findData.account
                    item.onClick(this, () => {
                        Laya.Scene.open(Router.BindCard, true, {
                            "data": findData, "bindData": {
                                name: `${I18nHelper.t('bind')}${this.withdrawTypeList[index].bankName}`,
                                bankCode: this.withdrawTypeList[index].bankCode
                            }
                        }, Laya.Handler.create(this, (res: Laya.Scene) => {
                            let bindCardScene: BindCardScene = res.getComponent(BindCardScene)
                            bindCardScene.onOpened({
                                "data": findData, "bindData": {
                                    name: `${I18nHelper.t('bind')}${this.withdrawTypeList[index].bankName}`,
                                    bankCode: this.withdrawTypeList[index].bankCode
                                }
                            })
                        }))
                    })

                    break;
                case "USDT":
                    item.title = findData.usdtAddr
                    item.onClick(this, () => {
                        Laya.Scene.open(Router.BindWallet, true, {
                            "data": findData, "bindData": {
                                name: `${I18nHelper.t('bind')}${this.withdrawTypeList[index].bankName}`,
                                bankCode: this.withdrawTypeList[index].bankCode
                            }
                        }, Laya.Handler.create(this, (res: Laya.Scene) => {
                            let bindWalletScene: BindWalletScene = res.getComponent(BindWalletScene)
                            bindWalletScene.onOpened({
                                "data": findData, "bindData": {
                                    name: `${I18nHelper.t('bind')}${this.withdrawTypeList[index].bankName}`,
                                    bankCode: this.withdrawTypeList[index].bankCode
                                }
                            })
                        }))
                    })
                    break;
                case "ALIPAY":
                    item.title = findData.bankName
                    item.m_micon.url = findData.img
                    item.onClick(this, () => {
                        Laya.Scene.open(Router.BindWithdraw, true, {
                            "data": findData, "bindData": {
                                name: `${I18nHelper.t('bind')}${this.withdrawTypeList[index].bankName}`,
                                bankCode: this.withdrawTypeList[index].bankCode
                            }
                        }, Laya.Handler.create(this, (res: Laya.Scene) => {
                            let bindWithdrawScene: BindWithdrawScene = res.getComponent(BindWithdrawScene)
                            bindWithdrawScene.onOpened({
                                "data": findData, "bindData": {
                                    name: `${I18nHelper.t('bind')}${this.withdrawTypeList[index].bankName}`,
                                    bankCode: this.withdrawTypeList[index].bankCode
                                }
                            })
                        }))
                    })
                    break;
                case "WECHAT":
                    item.title = findData.bankName
                    item.m_micon.url = findData.img
                    item.onClick(this, () => {
                        Laya.Scene.open(Router.BindWithdraw, true, {
                            "data": findData, "bindData": {
                                name: `${I18nHelper.t('bind')}${this.withdrawTypeList[index].bankName}`,
                                bankCode: this.withdrawTypeList[index].bankCode
                            }
                        }, Laya.Handler.create(this, (res: Laya.Scene) => {
                            let bindWithdrawScene: BindWithdrawScene = res.getComponent(BindWithdrawScene)
                            bindWithdrawScene.onOpened({
                                "data": findData, "bindData": {
                                    name: `${I18nHelper.t('bind')}${this.withdrawTypeList[index].bankName}`,
                                    bankCode: this.withdrawTypeList[index].bankCode
                                }
                            })
                        }))
                    })
                    break;
                default:
                    break;
            }
        } else {
            item.title = `${I18nHelper.t('bind')}${this.withdrawTypeList[index].bankName}`
            switch (this.withdrawTypeList[index].bankCode) {
                case "BANK":
                    item.onClick(this, () => {
                        Laya.Scene.open(Router.BindCard, true, {
                            "bindData": {
                                name: `${I18nHelper.t('bind')}${this.withdrawTypeList[index].bankName}`,
                                bankCode: this.withdrawTypeList[index].bankCode
                            }
                        }, Laya.Handler.create(this, (res: Laya.Scene) => {
                            let bindCardScene: BindCardScene = res.getComponent(BindCardScene)
                            bindCardScene.onOpened({
                                "bindData": {
                                    name: `${I18nHelper.t('bind')}${this.withdrawTypeList[index].bankName}`,
                                    bankCode: this.withdrawTypeList[index].bankCode
                                }
                            })
                        }))
                    })

                    break;
                case "USDT":
                    item.onClick(this, () => {
                        Laya.Scene.open(Router.BindWallet, true, {
                            "bindData": {
                                name: `${I18nHelper.t('bind')}${this.withdrawTypeList[index].bankName}`,
                                bankCode: this.withdrawTypeList[index].bankCode
                            }
                        }, Laya.Handler.create(this, (res: Laya.Scene) => {
                            let bindWalletScene: BindWalletScene = res.getComponent(BindWalletScene)
                            bindWalletScene.onOpened({
                                "bindData": {
                                    name: `${I18nHelper.t('bind')}${this.withdrawTypeList[index].bankName}`,
                                    bankCode: this.withdrawTypeList[index].bankCode
                                }
                            })
                        }))
                    })
                    break;
                case "ALIPAY":
                    item.onClick(this, () => {
                        Laya.Scene.open(Router.BindWithdraw, true, {
                            "bindData": {
                                name: `${I18nHelper.t('bind')}${this.withdrawTypeList[index].bankName}`,
                                bankCode: this.withdrawTypeList[index].bankCode
                            }
                        }, Laya.Handler.create(this, (res: Laya.Scene) => {
                            let bindWithdrawScene: BindWithdrawScene = res.getComponent(BindWithdrawScene)
                            bindWithdrawScene.onOpened({
                                "bindData": {
                                    name: `${I18nHelper.t('bind')}${this.withdrawTypeList[index].bankName}`,
                                    bankCode: this.withdrawTypeList[index].bankCode
                                }
                            })
                        }))
                    })
                    break;
                case "WECHAT":
                    item.onClick(this, () => {
                        Laya.Scene.open(Router.BindWithdraw, true, {
                            "bindData": {
                                name: `${I18nHelper.t('bind')}${this.withdrawTypeList[index].bankName}`,
                                bankCode: this.withdrawTypeList[index].bankCode
                            }
                        }, Laya.Handler.create(this, (res: Laya.Scene) => {
                            let bindWithdrawScene: BindWithdrawScene = res.getComponent(BindWithdrawScene)
                            bindWithdrawScene.onOpened({
                                "bindData": {
                                    name: `${I18nHelper.t('bind')}${this.withdrawTypeList[index].bankName}`,
                                    bankCode: this.withdrawTypeList[index].bankCode
                                }
                            })
                        }))
                    })
                    break;
                default:
                    break;
            }
        }


        // if (index == 0) {
        //     item.onClick(this, () => {
        //         this.selectedIndex = index
        //         Laya.Scene.open(Router.BindWallet, true, null, Laya.Handler.create(this, () => {
        //         }))
        //     })
        // } else if (index == 1) {
        //     item.onClick(this, () => {
        //         this.selectedIndex = index
        //         Laya.Scene.open(Router.BindCard, true, null, Laya.Handler.create(this, () => {
        //         }))
        //     })
        // } else {

        //     item.m_closebtn.visible = true
        //     item.title = this.bankCardList[index].withdrawType == "bank" ?  this.bankCardList[index].account : this.bankCardList[index].usdtAddr
        //     item.m_closebtn.onClick(this, () => {
        //         this.delCardId = this.bankCardList[index].id
        //         this.uiCash.m_delWindow.visible = true
        //     })
        // }
    }

    renderTableListItem(index: number, obj: UI_withdraw_btn) {
        obj.title = this.withdrawTypeList[index].bankName
        obj.m_logo.url =  this.withdrawTypeList[index].bankLogo
        obj.onClick(this, () => {
            console.log("111", index);
            this.uiCash.m_list.scrollPane.scrollToView(this.uiCash.m_list._children[index])
        })
    }

    delCard() {
        this.uiCash.m_delWindow.visible = false
        http.delMyBankCard({
            id: this.delCardId
        }, (res: any) => {
            if (res.code == 200) {
                this.getBankList()
            } else {
                UIHelper.showMsg(I18nHelper.t(res.msg))
            }
        })

    }


    destroy() {
        notice.off(cnf_event.OnBalanceChanged, this.OnBalanceChanged)
        this.uiCash.dispose()
    }
}