/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_bet_chip extends fgui.GComponent {

	public m_icon:fgui.GLoader;
	public static URL:string = "ui://61ic9kfkomiaz7n";

	public static createInstance():UI_bet_chip {
		return <UI_bet_chip>(fgui.UIPackage.createObject("game", "bet_chip"));
	}

	protected onConstruct():void {
		this.m_icon = <fgui.GLoader>(this.getChild("icon"));
	}
}