/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_GYHBet from "./UI_GYHBet";
import UI_chips_list from "./UI_chips_list";

export default class UI_Bet extends fgui.GComponent {

	public m_type:fgui.Controller;
	public m_tabs:fgui.Controller;
	public m_recordType:fgui.Controller;
	public m_mask:fgui.GGraph;
	public m_gyh_bet:UI_GYHBet;
	public m_tm_bet:fgui.GComponent;
	public m_lh_bet:fgui.GComponent;
	public m_tmdxds_bet:fgui.GComponent;
	public m_gyhTitle:fgui.GButton;
	public m_tmTitle:fgui.GButton;
	public m_lhTitle:fgui.GButton;
	public m_dxdsTitle:fgui.GButton;
	public m_bet:fgui.GGroup;
	public m_customChipsBtn:fgui.GButton;
	public m_chips_list:UI_chips_list;
	public m_chips:fgui.GGroup;
	public m_cancel_btn:fgui.GButton;
	public m_confirm_btn:fgui.GButton;
	public m_repeat_btn:fgui.GButton;
	public m_balance:fgui.GTextField;
	public m_betNum:fgui.GTextField;
	public m_btns:fgui.GGroup;
	public m_playDes:fgui.GButton;
	public m_hideGameBtn:fgui.GButton;
	public static URL:string = "ui://61ic9kfkddvkz7h";

	public static createInstance():UI_Bet {
		return <UI_Bet>(fgui.UIPackage.createObject("game", "Bet"));
	}

	protected onConstruct():void {
		this.m_type = this.getController("type");
		this.m_tabs = this.getController("tabs");
		this.m_recordType = this.getController("recordType");
		this.m_mask = <fgui.GGraph>(this.getChild("mask"));
		this.m_gyh_bet = <UI_GYHBet>(this.getChild("gyh_bet"));
		this.m_tm_bet = <fgui.GComponent>(this.getChild("tm_bet"));
		this.m_lh_bet = <fgui.GComponent>(this.getChild("lh_bet"));
		this.m_tmdxds_bet = <fgui.GComponent>(this.getChild("tmdxds_bet"));
		this.m_gyhTitle = <fgui.GButton>(this.getChild("gyhTitle"));
		this.m_tmTitle = <fgui.GButton>(this.getChild("tmTitle"));
		this.m_lhTitle = <fgui.GButton>(this.getChild("lhTitle"));
		this.m_dxdsTitle = <fgui.GButton>(this.getChild("dxdsTitle"));
		this.m_bet = <fgui.GGroup>(this.getChild("bet"));
		this.m_customChipsBtn = <fgui.GButton>(this.getChild("customChipsBtn"));
		this.m_chips_list = <UI_chips_list>(this.getChild("chips_list"));
		this.m_chips = <fgui.GGroup>(this.getChild("chips"));
		this.m_cancel_btn = <fgui.GButton>(this.getChild("cancel_btn"));
		this.m_confirm_btn = <fgui.GButton>(this.getChild("confirm_btn"));
		this.m_repeat_btn = <fgui.GButton>(this.getChild("repeat_btn"));
		this.m_balance = <fgui.GTextField>(this.getChild("balance"));
		this.m_betNum = <fgui.GTextField>(this.getChild("betNum"));
		this.m_btns = <fgui.GGroup>(this.getChild("btns"));
		this.m_playDes = <fgui.GButton>(this.getChild("playDes"));
		this.m_hideGameBtn = <fgui.GButton>(this.getChild("hideGameBtn"));
	}
}