import { IGameRoom } from "../../config/interface";
import UI_Button from "../../fgui/home/UI_Button";
import UI_row from "../../fgui/home/UI_row";
import UI_GameListWindow from "../../fgui/windows/UI_GameListWindow";
import I18nHelper from "../../helper/I18nHelper";
import HomeDataManager from "../../model/HomeDataManager";
import { Router } from "../../util/router";
import { GameScene } from "../scene/GameScene";
export class GameLIstWindow extends fgui.Window {
    private tableList: fgui.GList
    private gameList: fgui.GList
    private games: IGameRoom[] = []

    public constructor() {
        super();
    }
    protected onInit(): void {
        this.contentPane = UI_GameListWindow.createInstance() as UI_GameListWindow;
        this.tableList = this.contentPane.getChild("customerList")

        this.tableList = this.contentPane.getChild("tablist");
        this.gameList = this.contentPane.getChild("gameList");

        this.tableList.itemRenderer = Laya.Handler.create(this, this.renderTableListItem, null, false);
        this.gameList.itemRenderer = Laya.Handler.create(this, this.renderGameListItem, null, false);
        this.setPivot(0.5, 0.5);
        this.center();

        this.initGameList()

        this.contentPane.getChild('mask').onClick(this, () => {
            this.doHideAnimation()
        })
    }
    protected doShowAnimation(): void {
        this.setScale(0.1, 0.1);
        fgui.GTween.to2(0.1, 0.1, 1, 1, 0.3)
            .setTarget(this, this.setScale)
            .setEase(fgui.EaseType.QuadOut)
            .onComplete(this.onShown, this);
    }
    protected doHideAnimation(): void {
        fgui.GTween.to2(1, 1, 0.1, 0.1, 0.3)
            .setTarget(this, this.setScale)
            .setEase(fgui.EaseType.QuadOut)
            .onComplete(this.hideImmediately, this);
    }





    initGameList() {
        console.log("HomeDataManager.getInstance().getGameList()", HomeDataManager.getInstance().getGameList());
        this.tableList.numItems = HomeDataManager.getInstance().getGameList().length
        this.games = HomeDataManager.getInstance().getGameList()[0].lgGamesRooms
        this.gameList.numItems = this.games.length
    }


    renderTableListItem(index: number, obj: fairygui.GObject) {
        let btn = obj as UI_Button
        if (index == 0) {
            btn.selected = true
            btn.m_icon1.url = fgui.UIPackage.getItemURL("home", "all_icon");
            btn.m_icon2.url = fgui.UIPackage.getItemURL("home", "all_icon2");
        } else {
            btn.selected = false
            let imgUrlArr = HomeDataManager.getInstance().getGameList()[index].gameImg.split(",");
            btn.m_icon1.url = imgUrlArr[0]
            btn.m_icon2.url = imgUrlArr[1]

        }


        btn.text = HomeDataManager.getInstance().getGameList()[index].pairCode ? I18nHelper.t(HomeDataManager.getInstance().getGameList()[index].pairCode) : HomeDataManager.getInstance().getGameList()[index].name;


        btn.onClick(this, () => {
            this.games = HomeDataManager.getInstance().getGameList()[index].lgGamesRooms
            this.gameList.numItems = this.games.length
        })
    }
    renderGameListItem(index: number, obj: fairygui.GObject) {
        let item = obj as UI_row
        item.m_pic.url = this.games[index].image
        console.log(this.games[index].image);
        

        item.m_title.text = this.games[index].pairCode ? I18nHelper.t(this.games[index].pairCode) : this.games[index].name
        item.onClick(this, () => {
            this.doHideAnimation()
            Laya.Scene.open(Router.Game, true, null, Laya.Handler.create(this, (scene: Laya.Scene) => {
                let gameScene: GameScene = scene.getComponent(GameScene)
                gameScene.onOpened({ "roomId": this.games[index].id })
            }))
        })

    }




    destroy() {
        this.dispose()
    }
}
