/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_bet_info from "./UI_bet_info";

export default class UI_b_btn extends fgui.GButton {

	public m_bet_info:UI_bet_info;
	public m_limit:fgui.GTextField;
	public static URL:string = "ui://61ic9kfkmzb7z90";

	public static createInstance():UI_b_btn {
		return <UI_b_btn>(fgui.UIPackage.createObject("game", "b_btn"));
	}

	protected onConstruct():void {
		this.m_bet_info = <UI_bet_info>(this.getChild("bet_info"));
		this.m_limit = <fgui.GTextField>(this.getChild("limit"));
	}
}