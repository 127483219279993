/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_BigBallBtnTitle extends fgui.GButton {

	public m_red:fgui.GGraph;
	public static URL:string = "ui://d7esdcvtmi8lz9w";

	public static createInstance():UI_BigBallBtnTitle {
		return <UI_BigBallBtnTitle>(fgui.UIPackage.createObject("windows", "BigBallBtnTitle"));
	}

	protected onConstruct():void {
		this.m_red = <fgui.GGraph>(this.getChild("red"));
	}
}