/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_withdraw_btn extends fgui.GButton {

	public m_logo:fgui.GLoader;
	public static URL:string = "ui://8xnwynuhmi8lr";

	public static createInstance():UI_withdraw_btn {
		return <UI_withdraw_btn>(fgui.UIPackage.createObject("cash", "withdraw_btn"));
	}

	protected onConstruct():void {
		this.m_logo = <fgui.GLoader>(this.getChild("logo"));
	}
}