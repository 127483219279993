/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_Settled from "./UI_Settled";
import UI_unSettled from "./UI_unSettled";
import UI_betting_order_unsettled from "./UI_betting_order_unsettled";
import UI_Betting_order from "./UI_Betting_order";
import UI_betting_order_settled from "./UI_betting_order_settled";
import UI_Footer from "./UI_Footer";

export default class betting_orderBinder {
	public static bindAll():void {
		fgui.UIObjectFactory.setExtension(UI_Settled.URL, UI_Settled);
		fgui.UIObjectFactory.setExtension(UI_unSettled.URL, UI_unSettled);
		fgui.UIObjectFactory.setExtension(UI_betting_order_unsettled.URL, UI_betting_order_unsettled);
		fgui.UIObjectFactory.setExtension(UI_Betting_order.URL, UI_Betting_order);
		fgui.UIObjectFactory.setExtension(UI_betting_order_settled.URL, UI_betting_order_settled);
		fgui.UIObjectFactory.setExtension(UI_Footer.URL, UI_Footer);
	}
}