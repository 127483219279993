import { E_ScreenMode } from "../../config/Enum";
import UI_Home from "../../fgui/home/UI_Home";
import UI_row from "../../fgui/home/UI_row";
import ConfigManager from "../../helper/ConfigManager";
import { http } from "../../net/Http";
import NetManager from "../../net/NetManager";
import { Router } from "../../util/router";
import { IPConifg } from "../../config/NetConfig";
import { IGameRoom, IGameTable } from "../../config/interface";
import { UIHelper } from "../../helper/UIHelper";
import { UIBase } from "../scene/UIBase";
import PlayerDataManager from "../../model/PlayerDataManager";
import { CustomerWindow } from "../windows/CustomerWindow";
import HttpHelper from "../../helper/HttpHelper";
import { GameScene } from "../scene/GameScene";
import { Marquee } from "./mq";
import { cnf_event } from "../../config/Event_cnf";
import { notice } from "../../helper/NoticeHelper";
import UI_ButtomIcon from "../../fgui/common/UI_ButtomIcon";
import { ConfirmWindow } from "../windows/ConfirmWindow";
import I18nHelper from "../../helper/I18nHelper";
import UI_Button from "../../fgui/home/UI_Button";
import HomeDataManager from "../../model/HomeDataManager";
import GlobalParameterManager from "../../model/GlobalParameterManager";
import { BindEmailWindow } from "../windows/BindEmailWindow";
import AudioHelper from "../../helper/AudioHelper";


export class Home extends UIBase {
    private reloadNums: number = 0
    private or: E_ScreenMode
    private uiHome: UI_Home
    // 游戏列表数据
    data: IGameTable[]
    // 轮播数据
    swiperData: any[]
    // 游戏分类下数据
    games: IGameRoom[]
    //刷新头部
    private _header: fairygui.GComponent;
    //刷新头部控制器
    private _header_c1: fgui.Controller;
    marquee: Marquee

    xhrList: XMLHttpRequest[] = [];
    constructor(or: E_ScreenMode) {
        console.log("home constructor");

        super(or == E_ScreenMode.horizontal ? UI_Home.createInstance() : UI_Home.createInstance());
        this.onUILoaded()
    }
    onUILoaded() {
        console.log("home loaded");

        this.uiHome = this.UI_Item as UI_Home

        fgui.GRoot.inst.addChild(this.uiHome)
        HttpHelper.getInstance().getUserBalance()

        //刷新控制器
        // this._header = this.uiHome.m_homeContent.m_gameList.scrollPane.header
        // this._header_c1 = this._header.getController("c1");
        // this._header.on(fgui.Events.SIZE_CHANGED, this, this.onSizeChanged);
        //刷新控制器

        //余额
        this.uiHome.m_homeContent.m_personal.m_balance.text = PlayerDataManager.getInstance().getPlayerInfo()?.balance.toString()
        //刷新余额
        this.uiHome.m_homeContent.m_personal.m_refsh.onClick(this, () => {
            this.uiHome.m_homeContent.m_personal.m_refsh.m_t0.play()
            HttpHelper.getInstance().getUserBalance()
            Laya.timer.once(2000, this, async () => {
                this.uiHome.m_homeContent.m_personal.m_refsh.m_t0.stop()
            });

        })

        //客服列表
        this.uiHome.m_homeContent.m_personal.m_customer_btn.onClick(this, this.onCustomerClick)
        //获取游戏公告
        this.marquee = new Marquee(this.uiHome.m_homeContent.m_notice)
        //获取游戏轮播
        this.getSwiperList()
        //获取游戏列表
        this.getGameList()


        //充值
        this.uiHome.m_homeContent.m_personal.m_wallet_btn.onClick(this, () => {
            Laya.Scene.open(Router.Wallet, true, null, Laya.Handler.create(this, () => {
            }))
        })
        //提现
        this.uiHome.m_homeContent.m_personal.m_cash_btn.onClick(this, () => {

            if (PlayerDataManager.getInstance().getPlayerInfo().isTour == 2) {
                UIHelper.showWindow(new ConfirmWindow(I18nHelper.t('tourTip'), I18nHelper.t('tip'), () => {
                    HttpHelper.getInstance().logOut()
                }, () => { }))
                return
            }

            Laya.Scene.open(Router.Charge, true, null, Laya.Handler.create(this, () => {
            }))
        })

        this.createButtom()

        this.uiHome.m_homeContent.m_tablist.itemRenderer = Laya.Handler.create(this, this.renderTableListItem, null, false)
        this.uiHome.m_homeContent.m_gameList.itemRenderer = Laya.Handler.create(this, this.renderGameListItem, null, false)

        NetManager.getInstance().init()

        // this.uiHome.m_homeContent.m_gameList.on(fgui.Events.PULL_DOWN_RELEASE, this, this.onPullDownToRefresh);
        notice.on(cnf_event.OnBalanceChanged, this.balanceChange)

        this.uiHome.m_homeContent.m_title.m_logo.url = GlobalParameterManager.getInstance().getLogoUrl();


        let time = 0
        let timer: number = null
        Laya.stage.on(Laya.Event.FOCUS, () => {
            clearInterval(timer)
            if (time > 60 * 3) {
                time = 0
                NetManager.getInstance().reconnect(6)
            }
            console.log("显示了");
        })

        Laya.stage.on(Laya.Event.BLUR, () => {
            time = 0
            timer = setInterval(() => {
                console.log("进入后台了", time);
                time++
            }, 1000)
        })

        // @ts-ignore
        if (window.navigator.connection) {
            // @ts-ignore
            window.navigator.connection.addEventListener('change', this.handleConnectionChange);
        } else {
            console.log('Network information API not supported');
        }



        // 监听页面可见性变化的事件
        document.addEventListener('visibilitychange', function () {
            if (document.hidden) {
                console.log('用户已回到后台');
                AudioHelper.stopMusic()
                // 用户回到后台时的处理逻辑
            } else {
                console.log('用户回到前台');
                // 用户回到前台时的处理逻辑
            }
        });
    }

    handleConnectionChange() {
        // @ts-ignore
        if (window.navigator.connection) {
            // @ts-ignore
            // @ts-ignore
            if (window.navigator.connection.effectiveType == "3g") {
                NetManager.getInstance().reconnect(7)
            }
            // 这里可以根据navigator.connection.type进行相应的处理
        }
    }



    bottomTimer: number = null
    createButtom() {
        let m_buttom = this.uiHome.m_buttom as UI_ButtomIcon

        m_buttom.m_home_icon_active.visible = true
        m_buttom.m_home_icon_bg.visible = false

        m_buttom.m_home_t.color = "#099B36"

        m_buttom.m_home_ani.play()

        // m_buttom.m_home.onClick(this, () => {
        //     Laya.Scene.open(Router.Home, true, null, Laya.Handler.create(this, () => {
        //     }))
        // })

        m_buttom.m_active.onClick(this, () => {
            Laya.Scene.open(Router.Active, true, null, Laya.Handler.create(this, () => {
            }))
        })

        m_buttom.m_wallet.onClick(this, () => {
            Laya.Scene.open(Router.Wallet, true, null, Laya.Handler.create(this, () => {
            }))
        })

        m_buttom.m_my.onClick(this, () => {
            Laya.Scene.open(Router.Me, true, null, Laya.Handler.create(this, () => {
            }))
        })

    }


    getSwiperList() {
        if (HomeDataManager.getInstance().getIsSwiperDataUpDate()) {
            let xhr = http.getSwiper((res: any) => {
                console.log("banner：", res);
                if (res.code == 200) {
                    HomeDataManager.getInstance().setSwiperData(res.data);
                    this.uiHome.m_homeContent.m_swiper.itemRenderer = Laya.Handler.create(this, this.renderswiperItem, null, false);
                    this.uiHome.m_homeContent.m_swiper.setVirtualAndLoop();
                    this.uiHome.m_homeContent.m_swiper.numItems = HomeDataManager.getInstance().getSwiperData().length;
                    Laya.timer.loop(3000, this, () => {
                        this.uiHome.m_homeContent.m_swiper && this.uiHome.m_homeContent.m_swiper.asList.scrollPane.scrollRight(1, true);
                    }, null, true)
                } else {
                    UIHelper.showMsg(I18nHelper.t(res.msg))
                }
            })
            this.xhrList.push(xhr)
        } else {
            Laya.timer.frameOnce(1, this, () => {
                this.uiHome.m_homeContent.m_swiper.itemRenderer = Laya.Handler.create(this, this.renderswiperItem, null, false);
                this.uiHome.m_homeContent.m_swiper.setVirtualAndLoop();
                this.uiHome.m_homeContent.m_swiper.numItems = HomeDataManager.getInstance().getSwiperData().length;
                Laya.timer.loop(3000, this, () => {
                    this.uiHome.m_homeContent.m_swiper && this.uiHome.m_homeContent.m_swiper.asList.scrollPane.scrollRight(1, true);
                }, null, true)
            })
        }

    }


    /** 三方进入游戏 */
    enterGame() {
        // 获取路径上的roomId
        let roomId = 1
        Laya.Scene.open(Router.Game, true, null, Laya.Handler.create(this, (scene: Laya.Scene) => {
            let gameScene: GameScene = scene.getComponent(GameScene)
            gameScene.onOpened({ "roomId": roomId })
        }))
    }

    getGameList() {
        this.data = HomeDataManager.getInstance().getGameList()
        console.log("this.data:", this.data);

        if (HomeDataManager.getInstance().getGameList().length == 0) {
            let xhr = http.getGameList((res: any) => {
                if (res.code == 200) {
                    this.data = res.data
                    let arr: IGameTable = {
                        gameImg: "xxx",
                        code: "xxx",
                        createBy: "xxx",
                        createTime: "xx",
                        id: 0,
                        lgGamesRooms: [],
                        name: I18nHelper.t('all'),
                        remark: "xxx",
                        updateBy: "xxx",
                        updateTime: "xxx",
                        pairCode: null
                    }
                    this.data.forEach((item) => {
                        arr.lgGamesRooms = arr.lgGamesRooms.concat(item.lgGamesRooms)
                    })
                    HomeDataManager.getInstance().setGameList(this.data)
                    this.data.unshift(arr)
                    console.log("this.data", this.data);
                    if (this.uiHome.m_homeContent) {
                        this.uiHome.m_homeContent.m_tablist.numItems = this.data.length
                        if (this.data.length > 0) {
                            this.games = this.data[0].lgGamesRooms
                            this.uiHome.m_homeContent.m_gameList.numItems = this.games.length
                            if (this.games.length > 3) {
                                this.uiHome.m_homeContent.m_gameList.height = Math.floor(this.games.length / 3) * 25 + (314 * Math.floor(this.games.length / 3))
                            }
                        }
                    }
                } else {
                    UIHelper.showMsg(I18nHelper.t(res.msg))
                }
            });
            this.xhrList.push(xhr)
        } else {
            console.log("HomeDataManager.getInstance().getGameList()", HomeDataManager.getInstance().getGameList());
            Laya.timer.frameOnce(5, this, () => {
                this.uiHome.m_homeContent.m_tablist.numItems = HomeDataManager.getInstance().getGameList().length
                if (this.data.length > 0) {
                    this.games = this.data[0].lgGamesRooms
                    this.uiHome.m_homeContent.m_gameList.numItems = this.games.length

                    if (this.games.length > 3) {
                        this.uiHome.m_homeContent.m_gameList.height = Math.floor(this.games.length / 3) * 25 + (314 * Math.floor(this.games.length / 3))
                    }
                }
            })
        }
    }


    renderTableListItem(index: number, obj: fairygui.GObject) {
        let btn = obj as UI_Button
        if (index == 0) {
            btn.selected = true
            btn.m_icon1.url = fgui.UIPackage.getItemURL("home", "all_icon");
            btn.m_icon2.url = fgui.UIPackage.getItemURL("home", "all_icon2");
        } else {
            btn.selected = false
            let imgUrlArr = this.data[index].gameImg.split(",");
            btn.m_icon1.url = imgUrlArr[0]
            btn.m_icon2.url = imgUrlArr[1]

        }


        btn.text = this.data[index].pairCode ? I18nHelper.t(this.data[index].pairCode) : this.data[index].name;


        btn.onClick(this, () => {
            this.games = this.data[index].lgGamesRooms
            this.uiHome.m_homeContent.m_gameList.numItems = this.games.length
        })
    }
    renderGameListItem(index: number, obj: fairygui.GObject) {
        let item = obj as UI_row
        item.m_pic.url = this.games[index].image

        item.m_title.text = this.games[index].pairCode ? I18nHelper.t(this.games[index].pairCode) : this.games[index].name
        item.onClick(this, () => {

            if (PlayerDataManager.getInstance().getPlayerInfo().isTour == 2) {
                UIHelper.showWindow(new ConfirmWindow(I18nHelper.t('tourTip'), I18nHelper.t('tip'), () => {
                    HttpHelper.getInstance().logOut()
                }, () => { }))
                return
            }else{
                Laya.Scene.open(Router.Game, true, null, Laya.Handler.create(this, (scene: Laya.Scene) => {
                    let gameScene: GameScene = scene.getComponent(GameScene)
                    gameScene.onOpened({ "roomId": this.games[index].id })
                }))
            }
        })

    }

    //广告列表
    private renderswiperItem(index: number, obj: fgui.GObject): void {
        var item: fgui.GButton = <fgui.GButton>obj;
        // item.icon =  HomeDataManager.getInstance().getSwiperData()[index].img
        item.icon = HomeDataManager.getInstance().getSwiperData()[index].img
        obj.onClick(this, () => {
            if (HomeDataManager.getInstance().getSwiperData()[index].isOutChain == "1") {
                window.open(HomeDataManager.getInstance().getSwiperData()[index].url)
            } else {
                UIHelper.showIFrame(HomeDataManager.getInstance().getSwiperData()[index].url)
            }
        })
    }



    //下拉加载
    private async onPullDownToRefresh(evt: Laya.Event): Promise<void> {
        console.log('准备');
        // console.log(header);

        if (this._header_c1.selectedIndex == 1) {
            this._header_c1.selectedIndex = 2;
            this.uiHome.m_homeContent.m_gameList.scrollPane.lockHeader(this._header.sourceHeight);
            Laya.timer.once(2000, this, async () => {
                this.getGameList();
                if (this.uiHome.m_homeContent.isDisposed) {
                    return;
                }
                this._header_c1.selectedIndex = 0;
                this.uiHome.m_homeContent.m_gameList.scrollPane.lockHeader(0);
            });
        }

    }

    //监听头部刷新控制器
    onSizeChanged(): void {
        if (this._header_c1.selectedIndex == 2)
            return;
        if (this._header.height > this._header.sourceHeight)
            this._header_c1.selectedIndex = 1;
        else
            this._header_c1.selectedIndex = 0;
    }





    //客服列表
    customerWindow: CustomerWindow = null
    onCustomerClick() {
        if (!ConfigManager.getInstance().Customer) {
            UIHelper.showMsg(I18nHelper.t('noService'))
            return
        }
        if (ConfigManager.getInstance().Customer.length > 0) {
            if (!this.customerWindow) {
                this.customerWindow = new CustomerWindow(ConfigManager.getInstance().Customer)
            }
            UIHelper.showWindow(this.customerWindow)
        }
        else {
            UIHelper.showMsg(I18nHelper.t('noService'))
        }
    }

    balanceChange = () => {
        this.uiHome.m_homeContent.m_personal.m_balance.text = PlayerDataManager.getInstance().getBalance().toString()
    }

    destroy() {
        this.xhrList.forEach((xhr) => {
            console.log("取消home");

            xhr.abort()
        })
        Laya.stage.offAll()

        clearTimeout(this.bottomTimer)
        notice.off(cnf_event.OnBalanceChanged, this.balanceChange)

        // 监听页面可见性变化的事件
        document.removeEventListener('visibilitychange', function () {
            if (document.hidden) {
                console.log('用户已回到后台');
                AudioHelper.stopMusic()
                // 用户回到后台时的处理逻辑
            } else {
                console.log('用户回到前台');
                // 用户回到前台时的处理逻辑
            }
        });
        this.marquee.destroy()
        this.uiHome.dispose()
    }
}