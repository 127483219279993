/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_chips_btn extends fgui.GButton {

	public m_chips_bg:fgui.GMovieClip;
	public static URL:string = "ui://d7esdcvtmzb7z8p";

	public static createInstance():UI_chips_btn {
		return <UI_chips_btn>(fgui.UIPackage.createObject("windows", "chips_btn"));
	}

	protected onConstruct():void {
		this.m_chips_bg = <fgui.GMovieClip>(this.getChild("chips_bg"));
	}
}