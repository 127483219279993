/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_BigBallBtn extends fgui.GButton {

	public m_big:fgui.GTextField;
	public m_double:fgui.GTextField;
	public static URL:string = "ui://d7esdcvtmi8lza0";

	public static createInstance():UI_BigBallBtn {
		return <UI_BigBallBtn>(fgui.UIPackage.createObject("windows", "BigBallBtn"));
	}

	protected onConstruct():void {
		this.m_big = <fgui.GTextField>(this.getChild("big"));
		this.m_double = <fgui.GTextField>(this.getChild("double"));
	}
}