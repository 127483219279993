/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_agent_item extends fgui.GComponent {

	public m_id:fgui.GTextField;
	public m_nickname:fgui.GTextField;
	public m_username:fgui.GTextField;
	public m_per:fgui.GTextField;
	public m_isCredit:fgui.GTextField;
	public m_invitationCode:fgui.GTextField;
	public static URL:string = "ui://ysuys7gbmi8l8";

	public static createInstance():UI_agent_item {
		return <UI_agent_item>(fgui.UIPackage.createObject("agent", "agent_item"));
	}

	protected onConstruct():void {
		this.m_id = <fgui.GTextField>(this.getChild("id"));
		this.m_nickname = <fgui.GTextField>(this.getChild("nickname"));
		this.m_username = <fgui.GTextField>(this.getChild("username"));
		this.m_per = <fgui.GTextField>(this.getChild("per"));
		this.m_isCredit = <fgui.GTextField>(this.getChild("isCredit"));
		this.m_invitationCode = <fgui.GTextField>(this.getChild("invitationCode"));
	}
}