/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_usdt_item from "./UI_usdt_item";
import UI_usdtQR from "./UI_usdtQR";
import UI_customer_item from "./UI_customer_item";
import UI_Detail from "./UI_Detail";
import UI_Wallet from "./UI_Wallet";
import UI_refsh_btn from "./UI_refsh_btn";
import UI_channel_btn from "./UI_channel_btn";

export default class walletBinder {
	public static bindAll():void {
		fgui.UIObjectFactory.setExtension(UI_usdt_item.URL, UI_usdt_item);
		fgui.UIObjectFactory.setExtension(UI_usdtQR.URL, UI_usdtQR);
		fgui.UIObjectFactory.setExtension(UI_customer_item.URL, UI_customer_item);
		fgui.UIObjectFactory.setExtension(UI_Detail.URL, UI_Detail);
		fgui.UIObjectFactory.setExtension(UI_Wallet.URL, UI_Wallet);
		fgui.UIObjectFactory.setExtension(UI_refsh_btn.URL, UI_refsh_btn);
		fgui.UIObjectFactory.setExtension(UI_channel_btn.URL, UI_channel_btn);
	}
}