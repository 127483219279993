import { E_ScreenMode } from "../../config/Enum";
import UI_Active from "../../fgui/active/UI_Active";
import ConfigManager from "../../helper/ConfigManager";
import { Router } from "../../util/router";
import { IPConifg } from "../../config/NetConfig";
import UI_ImageActive from "../../fgui/active/UI_ImageActive";
import { IActive } from "../../config/interface";
import { UIBase } from "../scene/UIBase";
import { UIHelper } from "../../helper/UIHelper";
import UI_ButtomIcon from "../../fgui/common/UI_ButtomIcon";
import { CustomerWindow } from "../windows/CustomerWindow";
import I18nHelper from "../../helper/I18nHelper";
import ActiveDataManager from "../../model/ActiveDataManager";
import { http } from "../../net/Http";
import NetManager from "../../net/NetManager";
import HttpHelper from "../../helper/HttpHelper";
import PlayerDataManager from "../../model/PlayerDataManager";
import { ConfirmWindow } from "../windows/ConfirmWindow";
export class Active extends UIBase {
    private reloadNums: number = 0
    private or: E_ScreenMode
    private uiactive: UI_Active
    data: IActive[] = []
    //刷新头部
    private _header: fairygui.GComponent;
    //刷新头部控制器
    private _header_c1: fgui.Controller;

    xhrList: XMLHttpRequest[] = []
    constructor(or: E_ScreenMode) {
        super(or == E_ScreenMode.horizontal ? UI_Active.createInstance() : UI_Active.createInstance());
        this.onUILoaded()
    }
    onUILoaded() {
        this.uiactive = this.UI_Item as UI_Active
        fgui.GRoot.inst.addChild(this.uiactive);

        //底部跳转
        this.createButtom()
        //刷新控制器
        this._header = this.uiactive.m_pics.scrollPane.header
        this._header_c1 = this._header.getController("c1");
        this._header.on(fgui.Events.SIZE_CHANGED, this, this.onSizeChanged);
        //刷新控制器

        this.uiactive.m_pics.on(fgui.Events.PULL_DOWN_RELEASE, this, this.onPullDownToRefresh);
        this.uiactive.m_pics.on(fgui.Events.PULL_UP_RELEASE, this, this.onPullUpToRefresh);
        //获取活动列表  
        this.getActiveList()
        this.uiactive.m_pics.itemRenderer = Laya.Handler.create(this, this.renderListItem, null, false);
        //客服列表
        this.uiactive.m_help_btn.onClick(this, this.onCustomerClick)
    }
    //客服列表
    customerWindow: CustomerWindow = null
    onCustomerClick() {
        if (!ConfigManager.getInstance().Customer) {
            UIHelper.showMsg(I18nHelper.t('noService'))
            return
        }
        if (ConfigManager.getInstance().Customer.length > 0) {
            if (!this.customerWindow) {
                this.customerWindow = new CustomerWindow(ConfigManager.getInstance().Customer)
            }
            UIHelper.showWindow(this.customerWindow)
        }
        else {
            UIHelper.showMsg(I18nHelper.t('noService'))
        }
    }


    bottomTimer: number = null
    createButtom() {
        let m_buttom = this.uiactive.m_buttom as UI_ButtomIcon

        m_buttom.m_ac_icon_active.visible = true
        m_buttom.m_ac_icon_bg.visible = false
        m_buttom.m_active_t.color = "#099B36"
        m_buttom.m_active_ani.play()


        m_buttom.m_home.onClick(this, () => {
            Laya.Scene.open(Router.Home, true, null, Laya.Handler.create(this, () => {
            }))
        })

        // m_buttom.m_active.onClick(this, () => {
        //     Laya.Scene.open(Router.Active, true, null, Laya.Handler.create(this, () => {
        //     }))
        // })

        m_buttom.m_wallet.onClick(this, () => {
            Laya.Scene.open(Router.Wallet, true, null, Laya.Handler.create(this, () => {
            }))
        })

        m_buttom.m_my.onClick(this, () => {
            Laya.Scene.open(Router.Me, true, null, Laya.Handler.create(this, () => {
            }))
        })

    }

    renderListItem(index: number, obj: UI_ImageActive) {
        if (obj) {
            obj.m_image.url = ActiveDataManager.getInstance().getActiveData()[index].img
            obj.onClick(this, () => {
                if (PlayerDataManager.getInstance().getPlayerInfo().isTour == 2) {
                    UIHelper.showWindow(new ConfirmWindow(I18nHelper.t('tourTip'), I18nHelper.t('tip'), () => {
                        HttpHelper.getInstance().logOut()
                    }, () => { }))
                    return
                }

                // 
                if (ActiveDataManager.getInstance().getActiveData()[index].isOutChain == 2) {
                    window.open(`${ActiveDataManager.getInstance().getActiveData()[index].url}?token=${NetManager.getInstance().token}`)
                } else {
                    UIHelper.showIFrame(`${ActiveDataManager.getInstance().getActiveData()[index].url}?token=${NetManager.getInstance().token}`)
                }
            })
        }
    }

    //获取活动列表
    getActiveList() {
        if (ActiveDataManager.getInstance().getIsActiveDataUpDate()) {

            let xhr = http.getActive((res: any) => {
                if (res.code == 200) {
                    ActiveDataManager.getInstance().setActiveData(res.data);
                    this.uiactive.m_pics && (this.uiactive.m_pics.numItems = ActiveDataManager.getInstance().getActiveData().length)
                } else {
                    UIHelper.showMsg(I18nHelper.t(res.msg))
                }

            });
            this.xhrList.push(xhr)
        } else {
            Laya.timer.frameOnce(1, this, () => {
                this.uiactive.m_pics && (this.uiactive.m_pics.numItems = ActiveDataManager.getInstance().getActiveData().length)

            })
        }

    }


    //下拉加载
    private onPullDownToRefresh(evt: Laya.Event) {
        console.log('准备');
        // console.log(header);

        if (this._header_c1.selectedIndex == 1) {
            this._header_c1.selectedIndex = 2;
            this.uiactive.m_pics.scrollPane.lockHeader(this._header.sourceHeight);
            this.getActiveList();
            if (this.uiactive.isDisposed) {
                return;
            }
            this._header_c1.selectedIndex = 0;
            this.uiactive.m_pics.scrollPane.lockHeader(0);
        }

    }

    //监听头部刷新控制器
    onSizeChanged(): void {
        if (this._header_c1.selectedIndex == 2)
            return;
        if (this._header.height > this._header.sourceHeight)
            this._header_c1.selectedIndex = 1;
        else
            this._header_c1.selectedIndex = 0;
    }

    //上拉加载
    private onPullUpToRefresh(evt: Laya.Event): void {
        var footer: fgui.GComponent = this.uiactive.m_pics.scrollPane.footer.asCom;

        footer.getController("c1").selectedIndex = 1;
        this.uiactive.m_pics.scrollPane.lockFooter(footer.sourceHeight);

        //Simulate a async resquest
        Laya.timer.once(2000, this, async () => {
            this.getActiveList();
            if (this.uiactive.isDisposed)
                return;

            //Refresh completed
            footer.getController("c1").selectedIndex = 0;
            this.uiactive.m_pics.scrollPane.lockFooter(0);
        });
    }



    destroy() {
        clearTimeout(this.bottomTimer)
        this.xhrList.forEach((xhr) => {
            console.log("取消", xhr);

            xhr.abort()
        })
        this.uiactive.dispose()
    }
}