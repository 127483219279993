/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_bet_info extends fgui.GComponent {

	public m_odds:fgui.GTextField;
	public static URL:string = "ui://61ic9kfksamfz5n";

	public static createInstance():UI_bet_info {
		return <UI_bet_info>(fgui.UIPackage.createObject("game", "bet_info"));
	}

	protected onConstruct():void {
		this.m_odds = <fgui.GTextField>(this.getChild("odds"));
	}
}