/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_SumRecordTitle from "./UI_SumRecordTitle";

export default class UI_SumThrend extends fgui.GComponent {

	public m_list:fgui.GList;
	public m_title:UI_SumRecordTitle;
	public static URL:string = "ui://d7esdcvtmi8lza8";

	public static createInstance():UI_SumThrend {
		return <UI_SumThrend>(fgui.UIPackage.createObject("windows", "SumThrend"));
	}

	protected onConstruct():void {
		this.m_list = <fgui.GList>(this.getChild("list"));
		this.m_title = <UI_SumRecordTitle>(this.getChild("title"));
	}
}