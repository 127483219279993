/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_bind_withdraw extends fgui.GComponent {

	public m_back:fgui.GButton;
	public m_title:fgui.GTextField;
	public m_modifyBtn:fgui.GButton;
	public m_help_btn:fgui.GButton;
	public m_micon:fgui.GGroup;
	public m_img:fgui.GLoader;
	public m_t0:fgui.Transition;
	public static URL:string = "ui://ymjq823ami8l3";

	public static createInstance():UI_bind_withdraw {
		return <UI_bind_withdraw>(fgui.UIPackage.createObject("bind_withdraw", "bind_withdraw"));
	}

	protected onConstruct():void {
		this.m_back = <fgui.GButton>(this.getChild("back"));
		this.m_title = <fgui.GTextField>(this.getChild("title"));
		this.m_modifyBtn = <fgui.GButton>(this.getChild("modifyBtn"));
		this.m_help_btn = <fgui.GButton>(this.getChild("help_btn"));
		this.m_micon = <fgui.GGroup>(this.getChild("micon"));
		this.m_img = <fgui.GLoader>(this.getChild("img"));
		this.m_t0 = this.getTransition("t0");
	}
}