import { IPConifg } from "../config/NetConfig";
import LocalUtil from "../util/LocalUtil";
import ResourceManager from "../util/ResourceManager";
import ConfigManager from "./ConfigManager";
export const LayaHelper = {
    /** 深度拷贝 */
    deepCopy(source: any) {
        const targetObj: any = Array.isArray(source) ? [] : {}; // 判断复制的目标是数组还是对象
        for (let keys in source) { // 遍历目标
            if (source.hasOwnProperty(keys)) {
                if (source[keys] && typeof source[keys] === 'object') { // 如果值是对象，就递归一下
                    targetObj[keys] = source[keys].constructor === Array ? [] : {};
                    targetObj[keys] = this.deepCopy(source[keys]);
                } else { // 如果不是，就直接赋值
                    targetObj[keys] = source[keys];
                }
            }
        }
        return targetObj;
    },
    /** 随机打乱数组 */
    shuffle(arr: any) {
        let i = arr.length;
        while (i) {
            let j = Math.floor(Math.random() * i--);
            [arr[j], arr[i]] = [arr[i], arr[j]];
        }
        return arr;
    },
    //获取本地存的数组
    getCaheArray(item: any, def: Array<any> = []) {
        let time = localStorage.getItem(item)
        let pArray: Array<any> = [];
        try {
            pArray = JSON.parse(time);
        } catch {
            pArray = null;
        }
        if (pArray) {
            return pArray;
        }
        return def;
    },
    //获取本地存的数字
    getCaheNumber(item: any, def: number = 0) {
        let time = localStorage.getItem(item)
        if (time && !isNaN(parseInt(time))) {
            return parseInt(time);
        }
        return def;
    },
    /** 数组磊随机一个 */
    arrayRandom(array: string | any[]) {
        return array[Math.ceil(Math.random() * array.length) - 1];
    },
    /** 区间随机 */
    getRandom(min: number, max: number) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    },
    /** 同步加载spine */
    async loadSpineSync(url: string) {
        // return new Promise((resolve, reject) => {
        //     cc.resources.load(url, sp.SkeletonData, (err, assets: sp.SkeletonData) => {
        //         if (err) {
        //             cc.error(`加载asset失败, url:${url}, err: ${err}`);
        //             resolve(null);
        //         } else {
        //             resolve(assets);
        //         }
        //     });
        // });
        let resource = await ResourceManager.getInstance().loadResource(url, Laya.Loader.SPINE)
        return resource
    },
    /** 判断两个数组是否相等 */
    someArray(array1: Array<any>, array2: Array<any>) {
        if (array1.length != array2.length) {
            return false;
        }
        return array1.sort().join('') === array2.sort().join('');
    },
    /** 同步加载资源 */
    loadAssetSync(url: string, type: any) {
        return new Promise((resolve, reject) => {
            Laya.loader.load(url, type).then((res) => {
                if (res) {
                    console.error(`加载asset失败, url:${url}, err: ${res}`);
                    resolve(null);
                } else {
                    reject(res as unknown as typeof type);
                }
            });
        });
    },
    loadSpriteFrame(url: string) {
        return new Promise((resolve, reject) => {
            Laya.loader.load(url, Laya.Loader.IMAGE).then((res: Laya.Texture) => {
                if (res) {
                    console.error(`加载asset失败, url:${url}, err: ${res}`);
                    reject(null);
                } else {
                    resolve(res);
                }
            });
        });
    },
    /**
     * 调整显示文本内容
     * @param str 数字文本内容
    */
    getTextWithNumber(str: string | number) {
        let bChina = true
        console.log("bChina:", bChina)
        let number = Number(str)
        let resultStr = ''
        let showArray = bChina ? [10000, 100000000, '万', '亿'] : [1000, 1000000, 'K', 'M']
        if (number.toString() < showArray[0]) {
            return number.toString()
        }
        if (number.toString() >= showArray[0] && number.toString() < showArray[1]) {
            let str = (number / Number(showArray[0])).toFixed(2)
            resultStr = `${str}${showArray[2]}`
        } else if (number.toString() >= showArray[1]) {
            let str = (number / Number(showArray[1])).toFixed(2)
            resultStr = `${str}${showArray[3]}`
        } else {
            resultStr = String(number)
        }
        return resultStr
    },
    /**
     * 根据开奖结果 ，使用betArea里面按钮的下标
     */
    getBJLBetArea(result: any) {
        let _ret: number[] = []
        switch (result) {
            case "1":
                _ret = [4];
                break;
            case "2":
                _ret = [4, 2];
                break;
            case "3":
                _ret = [4, 0]
                break;
            case "4":
                _ret = [4, 2, 0];
                break;
            case "5":
                _ret = [3];
                break;
            case "6":
                _ret = [3, 2] // "闲,庄对";
                break;
            case "7":
                _ret = [3, 0]//"闲,闲对";
                break;
            case "8":
                _ret = [3, 2, 0]// "闲,庄对,闲对";
                break;
            case "9":
                _ret = [1]// "和";
                break;
            case "10":
                _ret = [1, 2] // "和,庄对";
                break;
            case "11":
                _ret = [1, 0] //"和,闲对";
                break;
            case "12":
                _ret = [1, 2, 0] // "和,庄对,闲对";
                break;
        }
        return _ret
    },
    getLHBetArea(result: any) {
        let _ret: number[] = []
        switch (result) {
            case '1':
                _ret = [0]
                break;
            case '5':
                _ret = [2]
                break;
            case '9':
                _ret = [1]
                break;
            default:
                break;
        }
        return _ret
    },
    getNiuniuBetArea(result: string | any[]) {
        // [[8:-1],[1:0],[10:1],[5:0]]
        if (typeof result == 'string') {
            result = JSON.parse(result)
        }
        let banker = result[0]
        let player1 = result[1]
        let player2 = result[2]
        let player3 = result[3]
        let _ret: number[] = []
        if (player1[1] == 1) {
            _ret = _ret.concat([0, 3, 6])
        }
        if (player2[1] == 1) {
            _ret = _ret.concat([1, 4, 7])
        }
        if (player3[1] == 1) {
            _ret = _ret.concat([2, 5, 8])
        }
        return _ret
    },
    getTongZiBetArea(result: string | any[]) {
        // [[8:-1],[1:0],[10:1],[5:0]]
        if (typeof result == 'string') {
            result = JSON.parse(result)
        }
        let banker = result[0]
        let player1 = result[1]
        let player2 = result[2]
        let player3 = result[3]
        let _ret: number[] = []
        if (player1 > banker) {
            _ret = _ret.concat([0, 3, 6])
        }
        if (player2 > banker) {
            _ret = _ret.concat([1, 4, 7])
        }
        if (player3 > banker) {
            _ret = _ret.concat([2, 5, 8])
        }
        return _ret
    },
    getLPBetArea(result: number) {
        let isBig = result >= 19
        let isDouble = result % 2 == 0
        let isRed = [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36].includes(result)
        let isDa1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].includes(result)
        let isDa2 = [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24].includes(result)
        let isDa3 = [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36].includes(result)
        let isCol1 = [1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34].includes(result)
        let isCol2 = [2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35].includes(result)
        let isCol3 = [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36].includes(result)
        return {
            isBig, isDouble, isRed, isDa1, isDa2, isDa3, isCol1, isCol2, isCol3
        }
    },
    getIsred(result: number) {
        let isRed = [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36].includes(result)
        return isRed
    }
    ,
    getOrientation() {
        let flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        // @ts-ignore
        if (window.device.tablet() || window.device.androidTablet()) {
            flag = true
        }
        // @ts-ignore
        console.log("===========flag:", flag, window.device.tablet(), window.device.ios(), window.device.macos())
        if (flag) {
            return LocalUtil.stringForKey('orientation', 'v')
        } else {
            if (ConfigManager.getInstance().isDev) {
                return LocalUtil.stringForKey('orientation', 'h')
            } else {
                return 'h'
            }
        }
    },
    isMobile() {
        let flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        // @ts-ignore
        if (window.device.tablet() || window.device.androidTablet()) {
            flag = true
        }
        return flag
    }
    ,
    inRange(min: number, max: number, value: number, includeEdge: boolean = true): boolean {
        return includeEdge ? value >= min && value <= max : value > min && value < max;
    },
    //加载远程图片
    loadImage(url: any, callback: (arg0: any) => any) {
        let url_path = ''
        if (url.indexOf("http") >= 0) {
            url_path = url
        } else {
            url_path =  url
        }
        Laya.loader.load(url, Laya.Loader.IMAGE).then((res: Laya.Texture) => {
            callback(res)
        });
    },
    //加载远程背景音乐
    loadMP3(url: any, callback: { (resource: any): void; (arg0: any): void; }) {
        let url_path = ''
        if (url.indexOf("http") >= 0) {
            url_path = url
        } else {
            url_path =  url
        }
        Laya.loader.load(url, Laya.Loader.SOUND).then((res: Laya.Sound) => {
            callback(res)
        });
    },
    //get参数拼接
    strConcatenation(params: { [x: string]: string; }) {
        let str = ''
        let i = 0
        for (let key in params) {
            if (params[key]) {
                if (i == 0) {
                    str = "?" + key + "=" + params[key]
                } else {
                    str += "&" + key + "=" + params[key]
                }
                i++;
            }
        }
        return str
    },
    capCode(code: any, userName: any) {
        let loginKey = "oigoieori4634rf34r4tj66y3";//前端登录校验签名
        // return window.CryptoJS.MD5(code + userName + loginKey).toString()
    },
    //解析html 富文本 为 cocos 的富文本
    parsingHTMLRichText(htmlRichText: string | string[], bgColor: string) {
        if (htmlRichText.length <= 0 || typeof (htmlRichText) != 'string') return
        //解析出来的文本
        let myRichText = ''
        //背景底色
        //背景底色
        bgColor = (bgColor == null || bgColor == '' ?
            '<color=#FFFFFF >'
            : '<color=' + bgColor + '>')
        //是否开始找颜色参数 记录颜色的文本
        var isFindColor = false
        var colorStr = ''
        //开始找 <> 开头和结尾括号 的位置
        var isFindFormat = false
        var startIndex = 0
        var endIndex = 0
        var formatString = ''
        myRichText += bgColor
        for (var i = 0; i < htmlRichText.length; i++) {
            //找特殊标签 开始的标签
            if (htmlRichText[i] == '<') {
                isFindFormat = true
                startIndex = i
            }
            //是否开始找颜色
            if (isFindFormat == true && htmlRichText[i] == '#') {
                isFindColor = true
            }
            //如果开始找颜色了
            if (isFindColor == true) {
                colorStr += htmlRichText[i]
                //结束找颜色
                if (colorStr.length == 7) {
                    isFindColor = false
                }
                continue
            }
            //找到特殊标签 结束的标签  
            if (htmlRichText[i] == '>') {
                isFindFormat = false
                endIndex = i
                //截取这段特殊格式的字符串
                formatString = htmlRichText.slice(startIndex, endIndex)
                //如果是这个字符就添加背景色
                if (formatString == '</span') {
                    myRichText += bgColor
                } else {
                    //否则添加 找到的特殊颜色
                    if (isFindColor == false) {
                        myRichText += '</c><color=' + colorStr + '>'
                        colorStr = ''
                    }
                }
                continue
            }
            if (isFindFormat == false && isFindColor == false) {
                myRichText += htmlRichText[i]
            }
        }
        myRichText += '</c>'
        console.log('myRichText', myRichText);
        return myRichText
    },
    isAndroid() {
        let u = navigator.userAgent;
        return u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
    },
    isIOS() {
        let u = navigator.userAgent;
        return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    },
    /**
     * 给外层发送消息
     */
    sendMsgToNavtive(key: string, value: string) {
        if (this.isAndroid()) {
            document.location = `testkey://${key}=${value}`
        }
        // if (cc.sys.IPHONE == cc.sys.platform || cc.sys.isBrowser) {
        //     return
        // }
    },
    /**
     * 获取音效路径
     */
    getAudioPath() {
        let audioPath = 'audio/' + "zh"
        // if (i18nMgr.getLanguage() != "zh") {
        //     audioPath = 'audio/' + "en"
        // }
        return audioPath
    },
    // 设置页面中输入框层级
    setInputZIndex(zIndex: string) {
        let inputArr = document.getElementsByTagName('input')
        for (let index = 0; index < inputArr.length; index++) {
            const element = inputArr[index];
            element.style.zIndex = zIndex
            element.style.fontSize = "50px"
            element.style.color = "#FCE272"
        }
    },
    splitArray(arr: any[], length: number): any[][] {
        const result = [];
        for (let i = 0; i < arr.length; i += length) {
            result.push(arr.slice(i, i + length));
        }
        return result;
    },
    getCardUrl(card: string) {
        return card.replace("/card/", "").replace(".png", "")
    },


    // 获取最近7天
    getCurrWeekDays() {
        // @ts-ignore
        let startTime = window.dayjs().subtract(7, 'day').format('YYYY-MM-DD')
        // @ts-ignore
        let endTime = window.dayjs().format('YYYY-MM-DD')
        // let startTime = window.moment().startOf('week').format('YYYY-MM-DD')
        // let endTime = window.moment().endOf('week').format('YYYY-MM-DD')
        return { startTime, endTime }
    },

    // 获取最近30天
    getCurrMonthDays() {
        // @ts-ignore
        let startTime = window.dayjs().subtract(30, 'day').format('YYYY-MM-DD')
        // @ts-ignore
        let endTime = window.dayjs().format('YYYY-MM-DD')
        return { startTime, endTime }
    },


    // 获取昨天的开始结束时间
    getLastDays() {
        // @ts-ignore
        let startTime = window.dayjs().subtract(1, 'day').format('YYYY-MM-DD')
        // @ts-ignore
        let endTime = window.dayjs().format('YYYY-MM-DD')
        return { startTime, endTime }
    },

    // 获取今天的开始结束时间
    getDays() {
        // @ts-ignore
        let startTime = window.dayjs().format('YYYY-MM-DD')
        // @ts-ignore
        let endTime = window.dayjs().format('YYYY-MM-DD')
        return { startTime, endTime }
    },

    // 获取年月日
    getYMD(dateStr: string | Date): string[] {
        let date
        if (typeof dateStr == 'string') {
            date = new Date(dateStr);
        } else {
            date = dateStr
        }
        let year = date.getFullYear().toString()
        let month = date.getMonth() + 1 >= 10 ? (date.getMonth() + 1).toString() : ('0' + ((date.getMonth() + 1).toString()))
        let day = date.getDate() >= 10 ? date.getDate().toString() : ('0' + date.getDate().toString())
        return [
            year, month, day
        ]
    }
}
