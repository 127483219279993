import { E_ScreenMode } from "../config/Enum";
import { UIHelper } from "./UIHelper";


export class NodePlayerHelper {

    static instance: NodePlayerHelper;

    public canvasMap: HTMLIFrameElement[] = []

    public static getInstance(): NodePlayerHelper {
        if (this.instance == null) {
            this.instance = new NodePlayerHelper();
        }
        return this.instance;
    }



    private createIFrame(videoId: string, url: string) {
        let iframe = document.createElement('iframe') as HTMLIFrameElement
        iframe.style.position = "absolute"
        iframe.id = videoId
        document.body.insertAdjacentElement("afterbegin", iframe)
        let key =  window.btoa(url) 
        iframe.src = `video1.html?key=${key}`   
        iframe.setAttribute("frameBorder","no")
        iframe.setAttribute("border","0")
        iframe.setAttribute("marginwidth","0")
        iframe.setAttribute("marginheight","0")
        iframe.setAttribute("scrolling","no")
        iframe.setAttribute("allowtransparency","yes")
        return iframe
    }




    private setCnavasStyle(canvasId: string, width: number, height: number, topNum = 115) {
        this.setPos(canvasId, width, height, topNum)
        this.getCanvsById(canvasId).style.opacity = "1"

    }


    private getCanvsById(canvasId: string): HTMLCanvasElement {
        return document.getElementById(canvasId) as HTMLCanvasElement
    }


    private setPos(canvasId: string, width: number, height: number, topNum = 115) {
        let canvas = this.getCanvsById(canvasId)


        let layaCanvas = document.getElementById("layaCanvas")

        let transform = layaCanvas.style.transform
        let matrix = Number(transform.substring(7, transform.length - 2).split(",")[0])

        let layaCanvasWidth = layaCanvas.clientWidth * matrix

        // 计算laya的场景相对于屏幕缩放比例
        let scaleRot = layaCanvasWidth / 1080
        // 视频在画面中的大小
        let realWidth = width * scaleRot
        let realHeight  = realWidth/(1920/1080)

        if (Laya.stage.screenMode == E_ScreenMode.vertical) {
            canvas.style.left = ``
            canvas.style.top = `50px` 
            canvas.style.width = `${realWidth}px`
            canvas.style.height = `${realHeight}px`
        }

        if (navigator.userAgent.indexOf("Android") <= -1 && navigator.userAgent.indexOf("iPhone") <= -1) {
            canvas.style.transform = layaCanvas.style.transform
            canvas.style.transformOrigin = layaCanvas.style.transformOrigin
            canvas.style.left = ``
            canvas.style.top = `${50}px`
            canvas.style.width = `${layaCanvas.clientWidth}px`
            canvas.style.height = `${realHeight}px`
            
        }
    }

 



    /**
     * 播放直播流
     * @param url 
     */
    public playByUrl(videoId: string, url: string, width = 1280, height = 720, top = 115) {
        // url = 'xxx.xxx.xxx';
        if (!url) {
            UIHelper.showMsg("video url is null")
            return
        }
        let canvas = this.createIFrame(videoId, url)
        this.canvasMap.push(canvas)
        this.setCnavasStyle(videoId, width, height, top)
        let self = this
        let func = () => {
            self.setPos(videoId, width, height, top)
        }
        window.removeEventListener("resize", func)
        window.addEventListener("resize", func)
    }

      /**
     * 播放直播流
     * @param url 
     */
      public playByUrlFull(videoId: string, url: string, width = 1280, height = 720, top = 115) {
        // url = 'xxx.xxx.xxx';
        if (!url) {
            UIHelper.showMsg("video url is null")
            return
        }
        let canvas = document.createElement('iframe') as HTMLIFrameElement
        canvas.style.position = "absolute"
        canvas.id = videoId
        document.body.insertAdjacentElement("afterbegin", canvas)


        let key =  window.btoa(url)
        canvas.src = `video2.html?key=${key}`   
        canvas.setAttribute("frameBorder","no")
        canvas.setAttribute("border","0")
        canvas.setAttribute("marginwidth","0")
        canvas.setAttribute("marginheight","0")
        canvas.setAttribute("scrolling","no")
        canvas.setAttribute("allowtransparency","yes")
        canvas.style.opacity = "0"
        canvas.style.width = "100%"
        canvas.style.height = "100%"
        canvas.style.top = "0"
        this.canvasMap.push(canvas)
    }




    public showVideo(videoId: string) {
      
        let canvas = this.getCanvsById(videoId)

        if (canvas) {
            canvas.style.opacity = "1"
        }
    }


    // 隐藏视频并暂停
    public hideVideo(videoId: string) {
      
        let canvas = this.getCanvsById(videoId)
        if (canvas) {
            canvas.style.opacity = "0"
        }
    }




    public stopAll() {
        this.canvasMap.forEach((canvas, k) => {
            canvas.remove()
        })
    }
}