/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_frame from "./UI_frame";

export default class UI_PayWindow extends fgui.GComponent {

	public m_mask:fgui.GGraph;
	public m_frame:UI_frame;
	public m_title:fgui.GTextField;
	public m_content:fgui.GTextField;
	public m_cancel_btn:fgui.GButton;
	public m_sure_btn:fgui.GButton;
	public m_pay_pwd:fgui.GTextInput;
	public static URL:string = "ui://d7esdcvtmi8lz99";

	public static createInstance():UI_PayWindow {
		return <UI_PayWindow>(fgui.UIPackage.createObject("windows", "PayWindow"));
	}

	protected onConstruct():void {
		this.m_mask = <fgui.GGraph>(this.getChild("mask"));
		this.m_frame = <UI_frame>(this.getChild("frame"));
		this.m_title = <fgui.GTextField>(this.getChild("title"));
		this.m_content = <fgui.GTextField>(this.getChild("content"));
		this.m_cancel_btn = <fgui.GButton>(this.getChild("cancel_btn"));
		this.m_sure_btn = <fgui.GButton>(this.getChild("sure_btn"));
		this.m_pay_pwd = <fgui.GTextInput>(this.getChild("pay_pwd"));
	}
}