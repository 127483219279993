/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_Detail from "./UI_Detail";
import UI_refsh_btn from "./UI_refsh_btn";
import UI_Charge from "./UI_Charge";

export default class chargeBinder {
	public static bindAll():void {
		fgui.UIObjectFactory.setExtension(UI_Detail.URL, UI_Detail);
		fgui.UIObjectFactory.setExtension(UI_refsh_btn.URL, UI_refsh_btn);
		fgui.UIObjectFactory.setExtension(UI_Charge.URL, UI_Charge);
	}
}