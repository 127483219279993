import { E_ScreenMode } from "../../config/Enum";
import { cnf_event } from "../../config/Event_cnf";
import { IConfigVos, IGameConfig, IGameRoom, IRecoverChip } from "../../config/interface";
import UI_Bet from "../../fgui/game/UI_Bet";
import UI_EightNum from "../../fgui/game/UI_EightNum";
import UI_FiveNum from "../../fgui/game/UI_FiveNum";
import UI_FourNum from "../../fgui/game/UI_FourNum";
import UI_LHArea from "../../fgui/game/UI_LHArea";
import UI_TM10Area from "../../fgui/game/UI_TM10Area";
import UI_TM8Area from "../../fgui/game/UI_TM8Area";
import UI_TMDXDSArea from "../../fgui/game/UI_TMDXDSArea";
import UI_TenNum from "../../fgui/game/UI_TenNum";
import UI_bet_btn from "../../fgui/game/UI_bet_btn";
import UI_bet_chip from "../../fgui/game/UI_bet_chip";
import AudioHelper from "../../helper/AudioHelper";
import HttpHelper from "../../helper/HttpHelper";
import I18nHelper from "../../helper/I18nHelper";
import { LayaHelper } from "../../helper/LayaHelper";
import { notice } from "../../helper/NoticeHelper";
import { UIHelper } from "../../helper/UIHelper";
import BallDataManager from "../../model/BallDataManager";
import { http } from "../../net/Http";
export class BetArea {
    private or: E_ScreenMode
    private betArea: UI_Bet
    public tempChips: Map<string, {
        pos: Laya.Point,
        node: UI_bet_chip,
        bg: fgui.GImage
    }> = new Map()
    // 桌子上所有已经成功投注的筹码
    public tableChips: Map<string, {
        pos: Laya.Point,
        node: UI_bet_chip,
        bg: fgui.GImage
    }> = new Map()

    // 所有点位对应的按钮
    public allChips: Map<string, UI_bet_btn> = new Map()


    private is8Ball: boolean = true
    xhrList:XMLHttpRequest[] = []

    roomData: IGameRoom
    public canBet:boolean = true

    constructor(or: E_ScreenMode, betArea: UI_Bet, data: IGameRoom) {
        this.or = or
        this.betArea = betArea
        this.roomData = data
        this.is8Ball = this.roomData.ball == 8
        this.onUILoaded()
    }

    onUILoaded() {

       let xhr =  http.getGameBetItems({
            gameCode: this.roomData.gameCode,
            roomId: this.roomData.id
        }, (data: any) => {
            console.log("获取到的配置信息", data);
            if (data.code == 200) {
                BallDataManager.getInstance().clearData(this.roomData.id)
                BallDataManager.getInstance().gameConfig = data.data
                this.setBetPoint(BallDataManager.getInstance().gameConfig)
                this.recoverUI()
            }
        })
        this.xhrList.push(xhr)

        this.betArea.m_confirm_btn.onClick(this, this.confirmBetting)
        this.betArea.m_cancel_btn.onClick(this, this.cancelBetting)
        notice.on(cnf_event.changeBetItem, this.updateBetInfo)
        notice.on(cnf_event.userBetCodeEvent, this.recoverChips)
    }

    updateBetInfo = (data: IGameConfig[]) => {
        if (data.length > 0) {
            if (data[0].disable == 1) {
                this.betArea.m_gyh_bet.visible = false
            }else{
                this.betArea.m_gyh_bet.visible = true
                this.setGYHPoint(data[0],true)
            }
        }
        if (data.length > 1) {
            if (data[1].disable == 1) {
                this.betArea.m_tm_bet.visible = false
            }else{
                this.betArea.m_tm_bet.visible = true
                this.setTMPoint(data[1],true)
            }
        }
        if (data.length > 2) {
            if (data[2].disable == 1) {
                this.betArea.m_lh_bet.visible = false
            }else{
                this.betArea.m_lh_bet.visible = true
                this.setLHPoint(data[2],true)
            }
        }
        if (data.length > 3) {
            if (data[3].disable == 1) {
                this.betArea.m_tmdxds_bet.visible = false
            }else{
                this.betArea.m_tmdxds_bet.visible = true
                this.setTMPDXDSoint(data[3],true)
            }
        }
    }



    setBetPoint(data: IGameConfig[]) {
        if (data.length > 0) {
            if (data[0].disable == 1) {
                this.betArea.m_gyh_bet.visible = false
            }else{
                this.betArea.m_gyh_bet.visible = true
                this.setGYHPoint(data[0])
            }
        }
        if (data.length > 1) {
            if (data[1].disable == 1) {
                this.betArea.m_tm_bet.visible = false
            }else{
                this.betArea.m_tm_bet.visible = true
                this.setTMPoint(data[1])
            }
        }
        if (data.length > 2) {
            if (data[2].disable == 1) {
                this.betArea.m_lh_bet.visible = false
            }else{
                this.betArea.m_lh_bet.visible = true
                this.setLHPoint(data[2])
            }
        }
        if (data.length > 3) {
            if (data[3].disable == 1) {
                this.betArea.m_tmdxds_bet.visible = false
            }else{
                this.betArea.m_tmdxds_bet.visible = true
                this.setTMPDXDSoint(data[3])
            }
        }
    }

  
    setGYHPoint(data: IGameConfig, update: boolean = false) {
        this.betArea.m_gyhTitle.title = data.pairCode ? I18nHelper.t(data.pairCode) :data.groupName

        this.betArea.m_gyh_bet._children.forEach((item, index) => {
            let node = item as UI_bet_btn
            node.visible = false
        })

        data.configList.forEach((item, index) => {
            let node = this.betArea.m_gyh_bet.getChildAt(index) as UI_bet_btn
            if (node) {
                node.title = item.pairCoded ? I18nHelper.t(item.pairCoded) :item.betName
                node.m_bet_info.m_odds.text = `1:${item.betOdds}`
                node.m_limit.text = `${item.minAmount} - ${item.maxAmount}`
                if (update) {
                    node.offClick(this, this.onClickBetArea)
                }
                node.onClick(this, this.onClickBetArea, [{
                    btn: node,
                    data: item
                }])
                node.visible = item.disabled == 0
                this.allChips.set(item.betCode, node)
            }
        })
    }

    tmBetAreaList: any []  = []
    setTMPoint(data: IGameConfig, update: boolean = false) {
        this.betArea.m_tmTitle.title = data.pairCode ? I18nHelper.t(data.pairCode) :data.groupName

        let arr: IConfigVos[][] = []

        for (let index = 0; index < data.configList.length; index += this.roomData.ball) {
            arr.push(data.configList.slice(index, index + this.roomData.ball))
        }


        if (update) {
            for (let i = 0; i < this.roomData.ball; i++) {
                let tmBetNode = this.tmBetAreaList[i]

                tmBetNode._children.forEach((node: any, j: number) => {
                    node.title = arr[i][j].pairCoded ? I18nHelper.t(arr[i][j].pairCoded) :arr[i][j].betName 

                    node.m_bet_info.m_odds.text = `1:${arr[i][j].betOdds}`
                    // node.m_bet_info.m_odds.text = `${arr[i][j].betCode}`
                    node.m_limit.text = `${arr[i][j].minAmount} - ${arr[i][j].maxAmount}`
                    node.visible = arr[i][j].disabled == 0

                    node.offClick(this, this.onClickBetArea)
                    node.onClick(this, this.onClickBetArea, [
                        {
                            btn: node,
                            data: arr[i][j]
                        }
                    ])
                    this.allChips.set(arr[i][j].betCode, node)
                })
            }
            return
        }


        for (let i = 0; i < this.roomData.ball; i++) {
            let tmBetNode:UI_TM8Area | UI_TM10Area = null
            if (this.is8Ball) {
                tmBetNode = UI_TM8Area.createInstance()
            } else {
                tmBetNode = UI_TM10Area.createInstance()
            }

            tmBetNode._children.forEach((node: any, j) => {
                node.title = arr[i][j].pairCoded ? I18nHelper.t(arr[i][j].pairCoded) :arr[i][j].betName 
                node.m_bet_info.m_odds.text = `1:${arr[i][j].betOdds}`
                // node.m_bet_info.m_odds.text = `${arr[i][j].betCode}`
                node.m_limit.text = `${arr[i][j].minAmount} - ${arr[i][j].maxAmount}`
                node.visible = arr[i][j].disabled == 0

                node.onClick(this, this.onClickBetArea, [
                    {
                        btn: node,
                        data: arr[i][j]
                    }
                ])
                this.allChips.set(arr[i][j].betCode, node)

            })

            tmBetNode.setXY(0, 270)
            this.betArea.m_tm_bet.addChild(tmBetNode)
            this.tmBetAreaList.push(tmBetNode)
        }


     
        let betNum = this.is8Ball ? UI_EightNum.createInstance() : UI_TenNum.createInstance()

        this.betArea.m_tm_bet.addChild(betNum)
        let rankBtnList:fgui.GButton[] = betNum._children as fgui.GButton[]
        this.tmBetAreaList.forEach((item, i) => {
            item.visible = i == 0
        })
        rankBtnList[0].titleColor = "#FFFFFF"
        rankBtnList[0].selected = true
        rankBtnList.forEach((item, index) => {
            item.title = `${I18nHelper.t('num')}${index + 1}${I18nHelper.t('numName')}`

            item.onClick(this, () => {
                rankBtnList.forEach((item, j) => {
                    item.titleColor = j == index ? "#FFFFFF" : '#8B8C91'
                    item.selected = j == index
                })
                this.tmBetAreaList.forEach((item, i) => {
                    item.visible = i == index
                })
            })
        })
    }

    tmDXBetAreaList: UI_TMDXDSArea[] = []
    setTMPDXDSoint(data: IGameConfig, update: boolean = false) {
        this.betArea.m_dxdsTitle.title = data.pairCode ? I18nHelper.t(data.pairCode) :data.groupName

        let arr: IConfigVos[][] = []

        for (let index = 0; index < data.configList.length; index += 4) {
            arr.push(data.configList.slice(index, index + 4))
        }

        if (update) {
            for (let i = 0; i < this.roomData.ball; i++) {
                let tmBetNode = this.tmDXBetAreaList[i]

                tmBetNode.m_b.title =   I18nHelper.t(arr[i][0].pairCoded) 
                tmBetNode.m_x.title = I18nHelper.t(arr[i][1].pairCoded) 
                tmBetNode.m_d.title = I18nHelper.t(arr[i][2].pairCoded) 
                tmBetNode.m_s.title = I18nHelper.t(arr[i][3].pairCoded) 

                tmBetNode.m_b.m_bet_info.m_odds.text = `1:${arr[i][0].betOdds}`
                tmBetNode.m_x.m_bet_info.m_odds.text = `1:${arr[i][1].betOdds}`
                tmBetNode.m_d.m_bet_info.m_odds.text = `1:${arr[i][2].betOdds}`
                tmBetNode.m_s.m_bet_info.m_odds.text = `1:${arr[i][3].betOdds}`


                tmBetNode.m_b.m_limit.text = `${arr[i][0].minAmount} - ${arr[i][0].maxAmount}`
                tmBetNode.m_x.m_limit.text = `${arr[i][1].minAmount} - ${arr[i][1].maxAmount}`
                tmBetNode.m_d.m_limit.text = `${arr[i][2].minAmount} - ${arr[i][2].maxAmount}`
                tmBetNode.m_s.m_limit.text = `${arr[i][3].minAmount} - ${arr[i][3].maxAmount}`

                tmBetNode.m_b.offClick(this, this.onClickBetArea)
                tmBetNode.m_x.offClick(this, this.onClickBetArea)
                tmBetNode.m_d.offClick(this, this.onClickBetArea)
                tmBetNode.m_s.offClick(this, this.onClickBetArea)


                tmBetNode.m_b.visible = arr[i][0].disabled == 0
                tmBetNode.m_x.visible = arr[i][1].disabled == 0
                tmBetNode.m_d.visible = arr[i][2].disabled == 0
                tmBetNode.m_s.visible = arr[i][3].disabled == 0

                tmBetNode.m_b.onClick(this, this.onClickBetArea, [{
                    btn: tmBetNode.m_b,
                    data: arr[i][0]
                }])
                tmBetNode.m_x.onClick(this, this.onClickBetArea, [{
                    btn: tmBetNode.m_x,
                    data: arr[i][1]
                }])
                tmBetNode.m_d.onClick(this, this.onClickBetArea, [{
                    btn: tmBetNode.m_d,
                    data: arr[i][2]
                }])
                tmBetNode.m_s.onClick(this, this.onClickBetArea, [{
                    btn: tmBetNode.m_s,
                    data: arr[i][3]
                }])

                this.allChips.set(arr[i][0].betCode, tmBetNode.m_b as unknown as UI_bet_btn)

                this.allChips.set(arr[i][1].betCode, tmBetNode.m_x as unknown as UI_bet_btn)

                this.allChips.set(arr[i][2].betCode, tmBetNode.m_d as unknown as UI_bet_btn)

                this.allChips.set(arr[i][3].betCode, tmBetNode.m_s as unknown as UI_bet_btn)


            }
            return
        }


        for (let i = 0; i < this.roomData.ball; i++) {
            let tmBetNode = UI_TMDXDSArea.createInstance()

            tmBetNode.m_b.title = I18nHelper.t(arr[i][0].pairCoded) 
            tmBetNode.m_x.title =I18nHelper.t(arr[i][1].pairCoded) 
            tmBetNode.m_d.title = I18nHelper.t(arr[i][2].pairCoded) 
            tmBetNode.m_s.title = I18nHelper.t(arr[i][3].pairCoded) 

            tmBetNode.m_b.m_bet_info.m_odds.text = `1:${arr[i][0].betOdds}`
            tmBetNode.m_x.m_bet_info.m_odds.text = `1:${arr[i][1].betOdds}`
            tmBetNode.m_d.m_bet_info.m_odds.text = `1:${arr[i][2].betOdds}`
            tmBetNode.m_s.m_bet_info.m_odds.text = `1:${arr[i][3].betOdds}`


            tmBetNode.m_b.m_limit.text = `${arr[i][0].minAmount} - ${arr[i][0].maxAmount}`
            tmBetNode.m_x.m_limit.text = `${arr[i][1].minAmount} - ${arr[i][1].maxAmount}`
            tmBetNode.m_d.m_limit.text = `${arr[i][2].minAmount} - ${arr[i][2].maxAmount}`
            tmBetNode.m_s.m_limit.text = `${arr[i][3].minAmount} - ${arr[i][3].maxAmount}`

            tmBetNode.m_b.visible = arr[i][0].disabled == 0
            tmBetNode.m_x.visible = arr[i][1].disabled == 0
            tmBetNode.m_d.visible = arr[i][2].disabled == 0
            tmBetNode.m_s.visible = arr[i][3].disabled == 0

            tmBetNode.m_b.onClick(this, this.onClickBetArea, [{
                btn: tmBetNode.m_b,
                data: arr[i][0]
            }])
            tmBetNode.m_x.onClick(this, this.onClickBetArea, [{
                btn: tmBetNode.m_x,
                data: arr[i][1]
            }])
            tmBetNode.m_d.onClick(this, this.onClickBetArea, [{
                btn: tmBetNode.m_d,
                data: arr[i][2]
            }])
            tmBetNode.m_s.onClick(this, this.onClickBetArea, [{
                btn: tmBetNode.m_s,
                data: arr[i][3]
            }])

            tmBetNode.setXY(0, 300)
            this.betArea.m_tmdxds_bet.addChild(tmBetNode)
            this.tmDXBetAreaList.push(tmBetNode)
        }




        let betNum = this.is8Ball ? UI_EightNum.createInstance() : UI_TenNum.createInstance()
        this.betArea.m_tmdxds_bet.addChild(betNum)
        let rankBtnList:fgui.GButton[] = betNum._children as fgui.GButton[]
        this.tmDXBetAreaList.forEach((item, i) => {
            item.visible = i == 0
        })
        rankBtnList[0].titleColor = "#FFFFFF"
        rankBtnList[0].selected = true
        rankBtnList.forEach((item, index) => {
            item.title = `${I18nHelper.t('num')}${index + 1}${I18nHelper.t('numName')}`

            item.onClick(this, () => {
                rankBtnList.forEach((item, j) => {
                    item.titleColor = j == index ? "#FFFFFF" : '#8B8C91'
                    item.selected = j == index
                })
                this.tmDXBetAreaList.forEach((item, i) => {
                    item.visible = i == index
                })
            })
        })
    }

    tmLHBetAreaList: UI_LHArea[] = []
    setLHPoint(data: IGameConfig, update: boolean = false) {
        this.betArea.m_lhTitle.title = data.pairCode ? I18nHelper.t(data.pairCode) :data.groupName

        let arr: IConfigVos[][] = []

        for (let index = 0; index < data.configList.length; index += 2) {
            arr.push(data.configList.slice(index, index + 2))
        }

        if (update) {
            for (let i = 0; i < (this.roomData.ball/2); i++) {
                let lhBetNode =  this.tmLHBetAreaList[i] //UI_LHArea.createInstance()
                lhBetNode.m_l.m_bet_info.m_odds.text = `1:${arr[i][0].betOdds}`
                lhBetNode.m_h.m_bet_info.m_odds.text = `1:${arr[i][1].betOdds}`
                lhBetNode.m_l.m_limit.text = `${arr[i][0].minAmount} - ${arr[i][0].maxAmount}`
                lhBetNode.m_h.m_limit.text = `${arr[i][1].minAmount} - ${arr[i][1].maxAmount}`

                lhBetNode.m_l.title =I18nHelper.t(arr[i][0].pairCoded) 
                lhBetNode.m_h.title =I18nHelper.t(arr[i][1].pairCoded) 



                lhBetNode.m_l.offClick(this, this.onClickBetArea)
                lhBetNode.m_h.offClick(this, this.onClickBetArea)

                lhBetNode.m_l.visible = arr[i][0].disabled == 0
                lhBetNode.m_h.visible = arr[i][1].disabled == 0

                lhBetNode.m_l.onClick(this, this.onClickBetArea, [{
                    btn: lhBetNode.m_l,
                    data: arr[i][0]
                }])
                lhBetNode.m_h.onClick(this, this.onClickBetArea, [{
                    btn: lhBetNode.m_h,
                    data: arr[i][1]
                }])

                this.allChips.set(arr[i][0].betCode, lhBetNode.m_l as unknown as UI_bet_btn)
                this.allChips.set(arr[i][1].betCode, lhBetNode.m_h as unknown as UI_bet_btn)

            }
            return
        }


        for (let i = 0; i <  (this.roomData.ball/2); i++) {
            let lhBetNode = UI_LHArea.createInstance()
            lhBetNode.m_l.m_bet_info.m_odds.text = `1:${arr[i][0].betOdds}`
            lhBetNode.m_h.m_bet_info.m_odds.text = `1:${arr[i][1].betOdds}`
            lhBetNode.m_l.m_limit.text = `${arr[i][0].minAmount} - ${arr[i][0].maxAmount}`
            lhBetNode.m_h.m_limit.text = `${arr[i][1].minAmount} - ${arr[i][1].maxAmount}`

            lhBetNode.m_l.title = I18nHelper.t(arr[i][0].pairCoded) 
            lhBetNode.m_h.title = I18nHelper.t(arr[i][1].pairCoded) 
            lhBetNode.m_l.visible = arr[i][0].disabled == 0
            lhBetNode.m_h.visible = arr[i][1].disabled == 0
            lhBetNode.m_l.onClick(this, this.onClickBetArea, [{
                btn: lhBetNode.m_l,
                data: arr[i][0]
            }])
            lhBetNode.m_h.onClick(this, this.onClickBetArea, [{
                btn: lhBetNode.m_h,
                data: arr[i][1]
            }])
            lhBetNode.setXY(0, 120)
            this.betArea.m_lh_bet.addChild(lhBetNode)
            this.tmLHBetAreaList.push(lhBetNode)
        }

        let betNum = this.is8Ball ? UI_FourNum.createInstance() : UI_FiveNum.createInstance()
        this.betArea.m_lh_bet.addChild(betNum)
        let rankBtnList:fgui.GButton[] = betNum._children as fgui.GButton[]
        this.tmLHBetAreaList.forEach((item, i) => {
            item.visible = i == 0
        })
        rankBtnList[0].titleColor = "#FFFFFF"
        rankBtnList[0].selected = true
        rankBtnList.forEach((item, index) => {
            item.title = `${I18nHelper.t('num')}${index + 1}${I18nHelper.t('numName')}`

            item.onClick(this, () => {
                rankBtnList.forEach((item, j) => {
                    item.titleColor = j == index ? "#FFFFFF" : '#8B8C91'
                    item.selected = j == index
                })
                this.tmLHBetAreaList.forEach((item, i) => {
                    item.visible = i == index
                })
            })
        })
    }



    onClickBetArea(betPointData: {
        btn: UI_bet_btn,
        data: IConfigVos
    }) {
        let chips = UI_bet_chip.createInstance()
        chips.setSize(60, 60)
        chips.m_icon.url = fgui.UIPackage.getItemURL("game", "chip_" + BallDataManager.getInstance().getSelectedChipsInfo().chipsMoney);
        chips.setXY(LayaHelper.getRandom(-20, 80), LayaHelper.getRandom(-80, -40))
        betPointData.btn.m_bet_info.addChild(chips)





        if (this.betArea.getController('type').selectedPage == "gyh") {
            betPointData.btn.m_bg.alpha = 1
            this.tempChips.set(chips._id, {
                pos: new Laya.Point(),
                node: chips,
                bg: betPointData.btn.m_bg
            })
        } else {
            this.tempChips.set(chips._id, {
                pos: new Laya.Point(),
                node: chips,
                bg: null
            })
        }
        BallDataManager.getInstance().myTempBeting(this.roomData.id, betPointData.data.betCode)
    }


    async confirmBetting() {
        if (this.canBet) {
            let confResult = await BallDataManager.getInstance().confirmBet(this.roomData)
            console.log("投注结果：", confResult);
            if (confResult) {
                AudioHelper.playBet()
                this.tableChips = new Map([... this.tableChips, ...this.tempChips]);
                this.tempChips.clear()
    
            } else {
                console.log("111");
    
                this.cancelBetting()
            }
            HttpHelper.getInstance().getUserBalance()
        }else{
            UIHelper.showMsg(I18nHelper.t('close'))
            this.cancelBetting()
            console.log("不能投注");
        }
       
    }

    cancelBetting() {
        try {
            this.tempChips.forEach((item) => {

                item.bg && ( item.bg.alpha = 0)

                item.node.dispose()
            })
        } catch (error) {
            console.error(error);
        }
        this.tempChips.clear()
        BallDataManager.getInstance().cancelBet(this.roomData.id)
    }

    canRecover = true
    recoverData: IRecoverChip[] = []
    recoverChips = (data: IRecoverChip[]) => {
        if (this.canRecover) {
            console.log("需要恢复的筹码", data);
            this.recoverData = data
            this.recoverUI()
            this.canRecover = false
        }
      
    }

    recoverUI() {
        this.recoverData.forEach((item) => {
            let btn = this.allChips.get(item.betCode)
            if (btn) {
                let chips = UI_bet_chip.createInstance()
                chips.setSize(60, 60)
                chips.m_icon.url = fgui.UIPackage.getItemURL("game", "chip_" + BallDataManager.getInstance().getSelectedChipsInfo().chipsMoney);
                chips.setXY(LayaHelper.getRandom(-20, 80), LayaHelper.getRandom(-80, -40))
                btn.m_bet_info.addChild(chips)


                if (this.betArea.getController('type').selectedPage == "gyh") {
                    this.tableChips.set(chips._id, {
                        pos: new Laya.Point(),
                        node: chips,
                        bg: btn.m_bg
                    })
                    btn.m_bg.alpha = 1
                }else {
                    this.tableChips.set(chips._id, {
                        pos: new Laya.Point(),
                        node: chips,
                        bg: null
                    })
                }
            } 

        })
    }



    clearChips() {
        try {
            this.tempChips.forEach((item) => {
                item.bg && ( item.bg.alpha = 0)

                item.node.dispose()
            })
        } catch (error) {
            console.error(error);
        }
        try {
            this.tableChips.forEach((item) => {
                item.bg && ( item.bg.alpha = 0)
                item.node.dispose()
            })
        } catch (error) {
            console.error(error);
        }
        this.tempChips.clear()
        this.tableChips.clear()
    }

    destroy() {
        console.log("LoginView销毁了")
        this.xhrList.forEach((xhr)=>{
            xhr.abort()
        })
        notice.off(cnf_event.changeBetItem, this.updateBetInfo)
        notice.off(cnf_event.userBetCodeEvent, this.recoverChips)
        this.clearChips()
        this.betArea.dispose();
    }
}