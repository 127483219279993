/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_Button extends fgui.GButton {

	public m_icon1:fgui.GLoader;
	public m_icon2:fgui.GLoader;
	public static URL:string = "ui://d7esdcvtnreqzan";

	public static createInstance():UI_Button {
		return <UI_Button>(fgui.UIPackage.createObject("windows", "Button"));
	}

	protected onConstruct():void {
		this.m_icon1 = <fgui.GLoader>(this.getChild("icon1"));
		this.m_icon2 = <fgui.GLoader>(this.getChild("icon2"));
	}
}