/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_Detail from "./UI_Detail";

export default class UI_Charge extends fgui.GComponent {

	public m_back:fgui.GButton;
	public m_help_btn:fgui.GButton;
	public m_my_wallet:UI_Detail;
	public m_deprec:fgui.GButton;
	public m_withdrawal:fgui.GButton;
	public static URL:string = "ui://e59xd1qaywm7o";

	public static createInstance():UI_Charge {
		return <UI_Charge>(fgui.UIPackage.createObject("charge", "Charge"));
	}

	protected onConstruct():void {
		this.m_back = <fgui.GButton>(this.getChild("back"));
		this.m_help_btn = <fgui.GButton>(this.getChild("help_btn"));
		this.m_my_wallet = <UI_Detail>(this.getChild("my_wallet"));
		this.m_deprec = <fgui.GButton>(this.getChild("deprec"));
		this.m_withdrawal = <fgui.GButton>(this.getChild("withdrawal"));
	}
}