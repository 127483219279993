/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_refsh from "./UI_refsh";

export default class UI_Personal extends fgui.GComponent {

	public m_balance:fgui.GTextField;
	public m_refsh:UI_refsh;
	public m_customer_btn:fgui.GButton;
	public m_cash_btn:fgui.GButton;
	public m_wallet_btn:fgui.GButton;
	public static URL:string = "ui://bi3ljjpyjq2pz5e";

	public static createInstance():UI_Personal {
		return <UI_Personal>(fgui.UIPackage.createObject("home", "Personal"));
	}

	protected onConstruct():void {
		this.m_balance = <fgui.GTextField>(this.getChild("balance"));
		this.m_refsh = <UI_refsh>(this.getChild("refsh"));
		this.m_customer_btn = <fgui.GButton>(this.getChild("customer_btn"));
		this.m_cash_btn = <fgui.GButton>(this.getChild("cash_btn"));
		this.m_wallet_btn = <fgui.GButton>(this.getChild("wallet_btn"));
	}
}