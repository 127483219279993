import { IPConifg } from "../../config/NetConfig";
import { IRank } from "../../config/interface";
import UI_avatar from "../../fgui/common/UI_avatar";
import UI_Rank from "../../fgui/game/UI_Rank";
import UI_rank_btn from "../../fgui/game/UI_rank_btn";
import { LayaHelper } from "../../helper/LayaHelper";
import { http } from "../../net/Http";

export class Rank {

    ui: UI_Rank
    data: IRank[] = []
    //刷新头部
    private _header: fairygui.GComponent;
    //刷新头部控制器
    private _header_c1: fgui.Controller;
    private _total: number = 0;
    private roomId: number
    xhrList: XMLHttpRequest[] = [];
    private selectType = 1 // 0 今天  1 本周  2 本月
    constructor(ui: UI_Rank, roomId: number) {
        console.log("111111");
        this.roomId = roomId
        this.ui = ui
        this.onUILoaded()
    }


    onUILoaded() {
        this._header = this.ui.m_rankList.scrollPane.header
        this._header_c1 = this._header.getController("c1");
        this._header.on(fgui.Events.SIZE_CHANGED, this, this.onSizeChanged);
        this.ui.m_rankList.on(fgui.Events.PULL_DOWN_RELEASE, this, this.onPullDownToRefresh);
        this.ui.m_rankList.on(fgui.Events.PULL_UP_RELEASE, this, this.onPullUpToRefresh);
        // this.ui.m_rankList.setVirtual()

        this.ui.m_rankList.itemRenderer = Laya.Handler.create(this, this.renderListItem, null, false);
        // this.ui.m_closeButton.onClick(this,()=>{
        //     this.ui.visible = false
        // })

        this.ui.m_day.onClick(this, () => {

            this.ui.m_day.selected = false
            this.ui.m_week.selected = false
            this.ui.m_mouth.selected = false
            this.selectType = 0
            this.ui.m_day.selected = true
            this.getList()
        })

        this.ui.m_week.onClick(this, () => {

            this.ui.m_day.selected = false
            this.ui.m_week.selected = false
            this.ui.m_mouth.selected = false
            this.selectType = 1
            this.ui.m_week.selected = true
            this.getList()

        })

        this.ui.m_mouth.onClick(this, () => {

            this.ui.m_day.selected = false
            this.ui.m_week.selected = false
            this.ui.m_mouth.selected = false
            this.selectType = 2
            this.ui.m_mouth.selected = true
            this.getList()

        })

        setTimeout(()=>{
            this.getList()
        },1000)

    }

    showWindow() {
        this.getList()
    }


    private renderListItem(index: number, item: UI_rank_btn): void {
        let iconUrl = ""
        if (index == 0) {
            iconUrl = "ui://61ic9kfk10j9vz9p"
        }
        if (index == 1) {
            iconUrl = "ui://61ic9kfk10j9vz9o"
        }
        if (index == 2) {
            iconUrl = "ui://61ic9kfk10j9vz9n"
        }
        item.icon = iconUrl
        item.title = this.data[index].name
        item.m_money.text = this.data[index].amount.toString();
        (item.m_avtor as UI_avatar).m_avatar.icon =   this.data[index].head
    }


    getList() {
        let date = LayaHelper.getDays()


        switch (this.selectType) {
            case 0:
                date = LayaHelper.getDays()
                break;
            case 1:
                date = LayaHelper.getCurrWeekDays()
                break;
            case 2:
                date = LayaHelper.getCurrMonthDays()
                break;
            default:
                break;
        }

        let xhr =  http.getRankList({
            // @ts-ignore
            date: window.dayjs().format('YYYY-MM-DD'),
            roomId: this.roomId
        }, (data: any) => {
            if (data.code == 200) {
                this.data = data.data
                this.ui.m_rankList.numItems = this.data.length
            }
        })

        this.xhrList.push(xhr)

    }

    //下拉加载
    private async onPullDownToRefresh(evt: Laya.Event): Promise<void> {

        if (this._header_c1.selectedIndex == 1) {
            this._header_c1.selectedIndex = 2;
            this.ui.m_rankList.scrollPane.lockHeader(this._header.sourceHeight);
            await this.getList();
            if (this.ui.isDisposed) {
                return;
            }
            this._header_c1.selectedIndex = 0;
            this.ui.m_rankList.scrollPane.lockHeader(0);
        }
    }

    //监听头部刷新控制器
    onSizeChanged(): void {
        if (this._header_c1.selectedIndex == 2)
            return;
        if (this._header.height > this._header.sourceHeight)
            this._header_c1.selectedIndex = 1;
        else
            this._header_c1.selectedIndex = 0;
    }

    //上拉加载
    private async onPullUpToRefresh(evt: Laya.Event): Promise<void> {
        var footer: fgui.GComponent = this.ui.m_rankList.scrollPane.footer.asCom;
        footer.getController("c1").selectedIndex = 1;
        this.ui.m_rankList.scrollPane.lockFooter(footer.sourceHeight);

        if (this.data.length < this._total) {
            var result = await this.getList();
            if (this.ui.isDisposed)
                return;

            //Refresh completed
            footer.getController("c1").selectedIndex = 0;
            this.ui.m_rankList.scrollPane.lockFooter(0);
        } else {
            this.ui.m_rankList.scrollPane.lockFooter(0);
            return;
        }

    }


    destroy() {
        this.xhrList.forEach((xhr)=>{
            xhr.abort()
        })
    }
}