/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_chatRight extends fgui.GButton {

	public m_msg:fgui.GRichTextField;
	public m_sendName:fgui.GTextField;
	public m_time:fgui.GTextField;
	public m_sendIcon1:fgui.GLoader;
	public m_sendIcon:fgui.GComponent;
	public static URL:string = "ui://q1kq7fv1reg61c";

	public static createInstance():UI_chatRight {
		return <UI_chatRight>(fgui.UIPackage.createObject("chat", "chatRight"));
	}

	protected onConstruct():void {
		this.m_msg = <fgui.GRichTextField>(this.getChild("msg"));
		this.m_sendName = <fgui.GTextField>(this.getChild("sendName"));
		this.m_time = <fgui.GTextField>(this.getChild("time"));
		this.m_sendIcon1 = <fgui.GLoader>(this.getChild("sendIcon1"));
		this.m_sendIcon = <fgui.GComponent>(this.getChild("sendIcon"));
	}
}