import { GameEvent } from "../config/GameEvent";
import { IBetInfo, IGameConfig, IGameRoom, IRoomData } from "../config/interface";
import I18nHelper from "../helper/I18nHelper";
import { LayaHelper } from "../helper/LayaHelper";
import { notice } from "../helper/NoticeHelper";
import { UIHelper } from "../helper/UIHelper";
import { http } from "../net/Http";
import LocalUtil from "../util/LocalUtil";
export default class BallDataManager {
    //对象
    private static instance: BallDataManager;
    //下注区域筹码
    public chipsData = [1, 5, 10, 20, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 20000]
    //所有筹码
    public allChipsData = [1, 5, 10, 20, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 20000, 50000, 100000, 200000, 500000, 1000000]
    //玩家自己选择的筹码
    public playerChips: any = [];
    // 当前选择小费
    public rewardMoney: number = 0

    public nperCode: string = ""
    //玩家选中的下注筹码πR
    private selectedChipsInfo: {
        chipsPos: Laya.Point,
        chipsMoney: number
    } = null


    private gameMyBetInfoRecord: IBetInfo = null
    //自己临时下注记录
    private tempMyBetInfoRecord: IBetInfo = null




    gameConfig: IGameConfig[] = []
    //单利模式
    public static getInstance(): BallDataManager {
        if (this.instance == null) {
            this.instance = new BallDataManager();
        }
        return this.instance;
    }
    //构造函数
    constructor() {
    }
    //设置选择的筹码
    public setSelectedChipsInfo(data: { chipsMoney: any; chipsPos: Laya.Point; }) {
        LocalUtil.setString(JSON.stringify(data), 'selectedChipsInfo')
        this.selectedChipsInfo = data
    }
    //获取选择的筹码
    public getSelectedChipsInfo() {
        return this.selectedChipsInfo
    }
    //清除记录
    public initMyGameBetInfoRecord(roomId: number, data?: IBetInfo) {
        if (data) {
            this.gameMyBetInfoRecord = data
            notice.emit(GameEvent.UserTempBet + roomId, this.gameMyBetInfoRecord)
            return
        }
        this.gameMyBetInfoRecord = {
            betAmounts: [
            ],
            betCodes: [
            ],
            roomId: null,
            gameCode: null,
            nperCode: null,
            totalAmount: null
        }
        notice.emit(GameEvent.UserTempBet + +  roomId, this.gameMyBetInfoRecord)

    }
    //下注下注的临时记录
    public initTempGameBetInfoRecord(roomId: number) {
        this.tempMyBetInfoRecord = {
            betAmounts: [
            ],
            betCodes: [
            ],
            roomId: roomId,
            gameCode: null,
            nperCode: null,
            totalAmount: null
        }
    }

    clearData(roomId: number) {
        this.initTempGameBetInfoRecord(roomId)
        this.initMyGameBetInfoRecord(roomId)
    }
    // 用户自己临时投注
    public myTempBeting(roomId: number, betCode: string) {

        let data: IBetInfo = {
            betAmounts: [
                this.selectedChipsInfo.chipsMoney
            ],
            betCodes: [
                betCode
            ],
            roomId: roomId,
            gameCode: "",
            nperCode: "",
            totalAmount: this.selectedChipsInfo.chipsMoney
        }

        this.addTempMyBetInfoRecord(roomId, data)
        console.log('投注后临时的下注记录', this.tempMyBetInfoRecord);
    }
    // 确认投注后将临时数据清空，并提交自己的确认好的加入自己的记录中
    public confirmBet(roomConfig: IGameRoom) {
        return new Promise((resolve, reject) => {
            if (this.tempMyBetInfoRecord && this.tempMyBetInfoRecord.betCodes.length > 0) {
                // 将当前临时选中的筹码，进行投注
                this.setMyGameBetRecord(roomConfig, this.tempMyBetInfoRecord)
                let allBet = this.tempMyBetInfoRecord
                console.log("总投注金额：", allBet)
                http.userBetInfo(allBet, (res: any) => {
                    if (res.code == 200) {
                        UIHelper.showMsg(I18nHelper.t(res.msg))
                        this.gameMyBetInfoRecord = this.addBetInfoRecord(LayaHelper.deepCopy(this.tempMyBetInfoRecord), LayaHelper.deepCopy(this.gameMyBetInfoRecord))
                        this.initTempGameBetInfoRecord(roomConfig.id)
                        resolve(true)
                    } else {
                        console.log("下注失败:", res.msg)
                        UIHelper.showMsg(I18nHelper.t(res.msg))
                        this.cancelBet(roomConfig.id)
                        notice.emit(GameEvent.CancelBetting + roomConfig.id)
                        resolve(false)
                    }
                })
            } else {

                UIHelper.showMsg(I18nHelper.t("plzBet"))
                // this.initTempGameBetInfoRecord(roomConfig.id)
                resolve(false)
            }
        })

    }
    // 取消投注，并清空当前的临时筹码
    public cancelBet(roomId: number) {
        this.initTempGameBetInfoRecord(roomId)
        notice.emit(GameEvent.UserTempBet + roomId, this.tempMyBetInfoRecord)
    }
    //获取用户当前的投注，并补充相关的投注信息
    public setMyGameBetRecord(roomConfig: IGameRoom, data: IBetInfo) {
        data.nperCode = this.nperCode
        data.gameCode = roomConfig.gameCode
        data.roomId = roomConfig.id
        return data
    }
    public addTempMyBetInfoRecord(roomId: number, data: IBetInfo) {
        this.tempMyBetInfoRecord = this.addBetInfoRecord(LayaHelper.deepCopy(data), LayaHelper.deepCopy(this.tempMyBetInfoRecord))
        notice.emit(GameEvent.UserTempBet + roomId, this.tempMyBetInfoRecord)
    }
    // 传递一个target数据，将两组数据加起来,调用此方法，的对象需要使用深拷贝
    addBetInfoRecord(data: IBetInfo, target: IBetInfo): IBetInfo {
        target.betAmounts = target.betAmounts.concat(data.betAmounts)
        target.betCodes = target.betCodes.concat(data.betCodes)
        target.totalAmount += data.totalAmount
        return target
    }
    public getGameMyBetInfoRecord() {
        return this.gameMyBetInfoRecord
    }
    checkCanRepeat(obj: any) {
        for (let key in obj) {
            if (typeof obj[key] === 'object') {
                if (!this.checkCanRepeat(obj[key])) {
                    return false;
                }
            } else {
                if (obj[key] !== 0) {
                    return false;
                }
            }
        }
        return true;
    }

    findChips(targetNum: number): number[] {
        const allChipsData = [1, 5, 10, 20, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 20000, 50000, 100000, 200000, 500000, 1000000];
        const resultArr: number[] = [];
        // Sort the chip values in ascending order
        allChipsData.sort((a, b) => a - b);
        let remainingNum = targetNum;
        let currentIndex = allChipsData.length - 1;
        while (remainingNum > 0 && currentIndex >= 0) {
            const currentChipValue = allChipsData[currentIndex];
            if (remainingNum >= currentChipValue) {
                resultArr.push(currentChipValue);
                remainingNum -= currentChipValue;
            } else {
                currentIndex--;
            }
        }
        // If the remaining number is not zero, it cannot be made up using the available chips
        if (remainingNum !== 0) {
            return [];
        }
        return resultArr;
    }
}