/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

export default class UI_ButtomIcon extends fgui.GComponent {

	public m_home_icon_bg:fgui.GImage;
	public m_ac_icon_bg:fgui.GImage;
	public m_wallet_icon_bg:fgui.GImage;
	public m_me_icon_bg:fgui.GImage;
	public m_home_icon_active:fgui.GImage;
	public m_wallet_icon_active:fgui.GImage;
	public m_ac_icon_active:fgui.GImage;
	public m_me_icon_active:fgui.GImage;
	public m_home:fgui.GGraph;
	public m_active:fgui.GGraph;
	public m_wallet:fgui.GGraph;
	public m_my:fgui.GGraph;
	public m_home_t:fgui.GTextField;
	public m_active_t:fgui.GTextField;
	public m_wallet_t:fgui.GTextField;
	public m_me_t:fgui.GTextField;
	public m_home_ani:fgui.Transition;
	public m_active_ani:fgui.Transition;
	public m_wallet_ani:fgui.Transition;
	public m_me_ani:fgui.Transition;
	public static URL:string = "ui://1exd9k18w6t9n";

	public static createInstance():UI_ButtomIcon {
		return <UI_ButtomIcon>(fgui.UIPackage.createObject("common", "ButtomIcon"));
	}

	protected onConstruct():void {
		this.m_home_icon_bg = <fgui.GImage>(this.getChild("home_icon_bg"));
		this.m_ac_icon_bg = <fgui.GImage>(this.getChild("ac_icon_bg"));
		this.m_wallet_icon_bg = <fgui.GImage>(this.getChild("wallet_icon_bg"));
		this.m_me_icon_bg = <fgui.GImage>(this.getChild("me_icon_bg"));
		this.m_home_icon_active = <fgui.GImage>(this.getChild("home_icon_active"));
		this.m_wallet_icon_active = <fgui.GImage>(this.getChild("wallet_icon_active"));
		this.m_ac_icon_active = <fgui.GImage>(this.getChild("ac_icon_active"));
		this.m_me_icon_active = <fgui.GImage>(this.getChild("me_icon_active"));
		this.m_home = <fgui.GGraph>(this.getChild("home"));
		this.m_active = <fgui.GGraph>(this.getChild("active"));
		this.m_wallet = <fgui.GGraph>(this.getChild("wallet"));
		this.m_my = <fgui.GGraph>(this.getChild("my"));
		this.m_home_t = <fgui.GTextField>(this.getChild("home_t"));
		this.m_active_t = <fgui.GTextField>(this.getChild("active_t"));
		this.m_wallet_t = <fgui.GTextField>(this.getChild("wallet_t"));
		this.m_me_t = <fgui.GTextField>(this.getChild("me_t"));
		this.m_home_ani = this.getTransition("home_ani");
		this.m_active_ani = this.getTransition("active_ani");
		this.m_wallet_ani = this.getTransition("wallet_ani");
		this.m_me_ani = this.getTransition("me_ani");
	}
}