/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_menuList from "./UI_menuList";

export default class UI_Me extends fgui.GComponent {

	public m_menuList:UI_menuList;
	public m_avatar:fgui.GComponent;
	public m_name:fgui.GTextField;
	public m_account:fgui.GTextField;
	public m_bottom:fgui.GComponent;
	public m_copyId:fgui.GButton;
	public m_updateUserInfo:fgui.GButton;
	public static URL:string = "ui://61q84xa5w6t9f";

	public static createInstance():UI_Me {
		return <UI_Me>(fgui.UIPackage.createObject("me", "Me"));
	}

	protected onConstruct():void {
		this.m_menuList = <UI_menuList>(this.getChild("menuList"));
		this.m_avatar = <fgui.GComponent>(this.getChild("avatar"));
		this.m_name = <fgui.GTextField>(this.getChild("name"));
		this.m_account = <fgui.GTextField>(this.getChild("account"));
		this.m_bottom = <fgui.GComponent>(this.getChild("bottom"));
		this.m_copyId = <fgui.GButton>(this.getChild("copyId"));
		this.m_updateUserInfo = <fgui.GButton>(this.getChild("updateUserInfo"));
	}
}